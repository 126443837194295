(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('collaterateInfoService', collaterateInfoService);

    collaterateInfoService.$inject = ['$http', '$q'];

    function collaterateInfoService ($http, $q) {
        var service = {
            getInfo: getInfo
        };

        return service;

        function getInfo (messagesUri) {
            var requestConfig = {
                url: messagesUri,
                method: 'GET'
            };

            return $http(requestConfig)
                .then(returnData)
                .catch(handleGetInfoError);

            function returnData (response) {
                return response.data;
            }

            function handleGetInfoError () {
                return $q.reject('There was an error getting info from server.');
            }
        }
    }
}());
