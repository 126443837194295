(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('classicEvents', classicEvents);

    classicEvents.$inject = ['$window', '$timeout', '$log'];

    function classicEvents ($window, $timeout, $log) {
        if (!$window.tbg || !$window.tbg.eventHub) {
            $log.error('Classic window.tbg.eventHub not loaded. Required by classicEvents. The classicEvents not initialized.');
            return;
        }

        var $classicEventHub = $window.tbg.eventHub;

        var service = {
            trigger: trigger,
            on: on,
            off: off,
            events: $classicEventHub.events
        };

        return service;

        function trigger (eventName, additionalArguments) {
            $timeout(function () {
                $classicEventHub.trigger(eventName, additionalArguments);
            });
        }

        // Returns handler function that must be passed to "off" method to unbind
        function on (eventName, handler) {
            var boundHandler;

            if (!eventName || !eventName.length || !$classicEventHub.events.hasOwnProperty(eventName.split('.')[0])) {
                $log.error('Event "' + eventName + '" not found in window.tbg.eventHub.');
                return;
            }

            if (!handler) {
                return;
            }

            boundHandler = onEventName.bind(null, handler);

            $classicEventHub.on(eventName, boundHandler);

            return boundHandler;

            function onEventName (originalHandler) {
                var args = [];
                Array.prototype.push.apply(args, arguments);
                args.shift();

                $timeout(function () {
                    originalHandler.apply(null, args);
                });
            }
        }

        function off (eventName, handler) {
            if (!eventName || !eventName.length || !$classicEventHub.events.hasOwnProperty(eventName.split('.')[0])) {
                $log.error('Event "' + eventName + '" not found in window.tbg.eventHub.');
                return;
            }

            if (!handler) {
                return;
            }

            $classicEventHub.off(eventName, handler);
        }
    }
}());
