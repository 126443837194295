(function (window, $, USER_TYPE) {
    'use strict';

    /*
        Service that logs errors to the server. Exposes window.tbgErrorLogger
        to the global scope.

        Used outside of Angular (in a global try...catch) so is not initially
        written as an Angular service (though there is a wrapper in our Angular app
        of this service - error-logger.service.js).

        Uses jQuery to contact the server - in the event the Angular app
        is borked, it will still report errors to the server.
    */

    var ENDPOINTS = {
        COLLATERATE_ADMIN: '/rest/internal/v1/collaterateAdmin',
        SUPPLIER_ADMIN: '/rest/internal/v1/supplierAdmin',
        SITE_ADMIN: '/rest/internal/v1/siteAdmin',
        STOREFRONT_USER: '/rest/internal/v1/storefront'
    };

    if (!$) {
        console.error('TBGErrorLogger requires jQuery to be loaded first.');
        return;
    }

    if (!USER_TYPE) {
        console.error('TBGErrorLogger requires USER_TYPE to be set before loading.');
        return;
    }

    if (!ENDPOINTS.hasOwnProperty(USER_TYPE)) {
        console.error('TBGErrorLogger: the USER_TYPE "%s" was not found in ENDPOINTS.', USER_TYPE);
        return;
    }

    window.tbgErrorLogger = {
        log: log
    };

    return;

    function log (err, successCb, errorCb, completeCb) {
        // Silence Zoho error reporting...
        if (err.extras && err.extras.message) {
            if (err.extras.message.indexOf('client id not not event') > -1 || err.extras.message.indexOf('client id not registered with worker') > -1) {
                return;
            }
        }

        // Obfuscate password fields
        if (err.config && err.config.data) {
            Object.keys(err.config.data).forEach(function (key) {
                if (key.toLowerCase().includes('password') || key.toLowerCase().includes('pwd')) {
                    err.config.data[key] = '*****';
                }
            });
        }

        $.ajax({
            url: ENDPOINTS[USER_TYPE] + '/clientErrorLog',
            method: 'PUT',
            contentType: 'application/json',
            success: successCb || noop,
            error: errorCb || noop,
            complete: completeCb || noop,
            data: JSON.stringify({
                json: err
            })
        });
    }

    function noop () {}
}(window, window.jQuery, window.USER_TYPE));
