(function () {
    'use strict';

    /*
        Note: using native Promises in this service, as $q was causing
        circular dependency due to this service being required by http-error-interceptor.js.

        The StackTrace.js library we're using to generate source-mapped stack traces
        for error reporting includes a Promise polyfill library, so we should be safe
        with older browsers as well.
    */

    angular
        .module('tbg.errorReporting')
        .factory('tbgErrorService', tbgErrorService);

    tbgErrorService.$inject = ['stackTrace', '$window', 'errorTypes'];

    function tbgErrorService (stackTrace, $window, errorTypes) {
        var service = {
            newHttpError: newHttpError,
            newError: newError,
            newWindowError: newWindowError
        };

        return service;

        function newHttpError (rejection) {
            var error = new Error(errorTypes.HTTP);

            return new Promise(httpErrorPromise);

            function httpErrorPromise (resolve, reject) {
                return stackTrace
                    .fromError(error)
                    .then(prepareError);

                function prepareError (stack) {
                    error.request = {
                        config: rejection.config,
                        status: rejection.status,
                        statusText: rejection.statusText || ''
                    };

                    if (rejection.data && rejection.data.errors && rejection.data.errors.length) {
                        error.errors = rejection.data.errors;
                    }

                    _decorateError(error, stack, errorTypes.HTTP);
                    resolve(error);
                }
            }
        }

        function newError (error, cause) {
            return stackTrace
                .fromError(error)
                .then(prepareError);

            function prepareError (stack) {
                _decorateError(error, stack, errorTypes.STANDARD, cause);
                return error;
            }
        }

        function newWindowError (message, file, line, col, error) {
            return new Promise(windowErrorPromise);

            function windowErrorPromise (resolve, reject) {
                if (!error) {
                    error = new Error(errorTypes.WINDOW);
                    prepareError();
                    returnError(error);
                } else {
                    return stackTrace
                        .fromError(error)
                        .then(prepareError)
                        .then(returnError);
                }

                function prepareError (stack) {
                    error.extras = {
                        message: message,
                        file: file || '',
                        line: line || '',
                        col: col || ''
                    };
                    _decorateError(error, stack, errorTypes.WINDOW);
                    return error;
                }

                function returnError (e) {
                    resolve(error);
                }
            }
        }

        function _decorateError (error, stack, type, cause) {
            error.mappedStack = stack || [];
            error.ua = $window.navigator.userAgent;
            error.href = $window.location.href;
            error.type = type;
            error.cause = cause || '';
        }
    }
}());
