(function () {
    'use strict';

    angular
        .module('storefrontApp')
        .factory('userNotificationsService', userNotificationsService);

    userNotificationsService.$inject = ['$window', '$rootScope', '$http', '$q', 'restRootUrl', 'authService'];

    function userNotificationsService ($window, $rootScope, $http, $q, restRootUrl, authService) {
        var DEBOUNCE_INTERVAL = 5 * (1000 * 60); // 5 minute debounce time

        var pendingReviewCount = 0;
        var needsPrintFilesCount = 0;
        var requiresActionCount = 0;

        var pendingReviewPromise = null;
        var pendingNeedsPrintFilesPromise = null;
        var pendingRequiresActionPromise = null;

        var localStorage = $window.localStorage;
        var storageKeys = {
            PENDING_REVIEW: 'userNotifications_pendingReview',
            NEEDS_FILES: 'userNotifications_needsPrintFiles',
            REQUIRES_ACTION: 'userNotifications_requiresAction'
        };

        var service = {
            pendingReviewItems: {
                get: getPendingReviewItems,
                subscribe: subscribeToPendingReviewItems
            },
            needsPrintFilesItems: {
                get: getNeedsPrintFilesItems,
                subscribe: subscribeToNeedsPrintFilesItems
            },
            requiresActionItems: {
                get: getRequiresActionItems,
                subscribe: subscribeToRequiresActionItems,
                resolve: resolveRequiresActionItem
            },
            totalNotificationCount: {
                get: getTotalNotificationCount,
                subscribe: subscribeToTotalNotificationCount
            },
            classicEvents: {
                subscribe: subscribeToClassicEvents
            }
        };

        bindToClassicEvents();

        return service;

        function bindToClassicEvents () {
            $window.events.listen($window.events.ORDER_LIST_UPDATED, function () {
                $rootScope.$emit('userNotifications:classicEventsTriggered');
            });
        }

        function subscribeToClassicEvents (scope, callback) {
            scope.$on('$destroy', $rootScope.$on('userNotifications:classicEventsTriggered', callback));
        }

        function getPendingReviewItems (userId) {
            var requestConfig = {
                method: 'GET',
                url: restRootUrl + '/jobs/pendingProofReview',
                params: {
                    userId: userId,
                    size: 100
                }
            };

            var cachedPendingReview;

            if (pendingReviewPromise) {
                return pendingReviewPromise;
            }

            if (localStorage) {
                cachedPendingReview = localStorage.getItem(storageKeys.PENDING_REVIEW);
                if (cachedPendingReview) {
                    cachedPendingReview = JSON.parse(cachedPendingReview);
                    if (new Date().getTime() - cachedPendingReview.timestamp < DEBOUNCE_INTERVAL) {
                        notifySystem(cachedPendingReview.data);
                        return $q.resolve(cachedPendingReview.data);
                    }
                }
            }

            return pendingReviewPromise = $http(requestConfig).then(processResponse);

            function processResponse (response) {
                var data = response.data.content;
                notifySystem(data);
                saveToLocalStorage(data, storageKeys.PENDING_REVIEW);
                pendingReviewPromise = null;
                return data;
            }

            function notifySystem (data) {
                $rootScope.$emit('userNotifications:pendingReviewItemsUpdated', data);
                $window.events.trigger($window.events.PENDING_REVIEW_LIST_UPDATED); // for theme devs
                pendingReviewCount = data.length;
                getTotalNotificationCount();
            }
        }

        function subscribeToPendingReviewItems (scope, callback) {
            scope.$on('$destroy', $rootScope.$on('userNotifications:pendingReviewItemsUpdated', callback));
        }

        function getNeedsPrintFilesItems (userId) {
            var requestConfig = {
                method: 'GET',
                url: restRootUrl + '/jobs/needPrintFiles',
                params: {
                    userId: userId,
                    size: 100
                }
            };

            var cachedNeedsPrintFiles;

            if (pendingNeedsPrintFilesPromise) {
                return pendingNeedsPrintFilesPromise;
            }

            if (localStorage) {
                cachedNeedsPrintFiles = localStorage.getItem(storageKeys.NEEDS_FILES);
                if (cachedNeedsPrintFiles) {
                    cachedNeedsPrintFiles = JSON.parse(cachedNeedsPrintFiles);
                    if (new Date().getTime() - cachedNeedsPrintFiles.timestamp < DEBOUNCE_INTERVAL) {
                        notifySystem(cachedNeedsPrintFiles.data);
                        return $q.resolve(cachedNeedsPrintFiles.data);
                    }
                }
            }

            return pendingNeedsPrintFilesPromise = $http(requestConfig).then(processResponse);

            function processResponse (response) {
                var data = response.data.content;
                notifySystem(data);
                saveToLocalStorage(data, storageKeys.NEEDS_FILES);
                pendingNeedsPrintFilesPromise = null;
                return data;
            }

            function notifySystem (data) {
                $rootScope.$emit('userNotifications:needsPrintFilesItemsUpdated', data);
                $window.events.trigger($window.events.NEEDS_PRINT_FILES_LIST_UPDATED); // for theme devs
                needsPrintFilesCount = data.length;
                getTotalNotificationCount();
            }
        }

        function subscribeToNeedsPrintFilesItems (scope, callback) {
            scope.$on('$destroy', $rootScope.$on('userNotifications:needsPrintFilesItemsUpdated', callback));
        }

        function getRequiresActionItems (userId, forceRefresh) {
            var requestConfig = {
                method: 'GET',
                url: restRootUrl + '/orderLogEntries/needAction',
                params: {
                    userId: userId,
                    size: 100
                }
            };

            var cachedRequiresAction;

            if (pendingRequiresActionPromise) {
                return pendingRequiresActionPromise;
            }

            if (!forceRefresh) {
                if (localStorage) {
                    cachedRequiresAction = localStorage.getItem(storageKeys.REQUIRES_ACTION);
                    if (cachedRequiresAction) {
                        cachedRequiresAction = JSON.parse(cachedRequiresAction);
                        if (new Date().getTime() - cachedRequiresAction.timestamp < DEBOUNCE_INTERVAL) {
                            notifySystem(cachedRequiresAction.data);
                            return $q.resolve(cachedRequiresAction.data);
                        }
                    }
                }
            }

            return pendingRequiresActionPromise = $http(requestConfig).then(processResponse);

            function processResponse (response) {
                var data = response.data.content;
                notifySystem(data);
                saveToLocalStorage(data, storageKeys.REQUIRES_ACTION);
                pendingRequiresActionPromise = null;
                return data;
            }

            function notifySystem (data) {
                $rootScope.$emit('userNotifications:requiresActionItemsUpdated', data);
                $window.events.trigger($window.events.REQUIRES_ACTION_LIST_UPDATED); // for theme devs
                requiresActionCount = data.length;
                getTotalNotificationCount();
            }
        }

        function resolveRequiresActionItem (userId, itemId) {
            var requestConfig = {
                method: 'PATCH',
                url: restRootUrl + '/user/' + userId + '/orderLogEntries/' + itemId,
                data: {
                    id: itemId,
                    resolved: true
                }
            };

            return $http(requestConfig).then(processResponse);

            function processResponse (response) {
                if (response.data.resolved) {
                    return getRequiresActionItems(userId, true).then(function () {
                        return response;
                    });
                }
                return $q.reject('Could not mark notification as complete. Please refresh and try again.');
            }
        }

        function subscribeToRequiresActionItems (scope, callback) {
            scope.$on('$destroy', $rootScope.$on('userNotifications:requiresActionItemsUpdated', callback));
        }

        function getTotalNotificationCount () {
            var totalNotificationCount = pendingReviewCount + needsPrintFilesCount + requiresActionCount;
            $rootScope.$emit('userNotifications:totalNotificationCountUpdated', totalNotificationCount);
            $window.events.trigger($window.events.NOTIFICATION_COUNT_UPDATED); // for theme devs
            return totalNotificationCount;
        }

        function subscribeToTotalNotificationCount (scope, callback) {
            scope.$on('$destroy', $rootScope.$on('userNotifications:totalNotificationCountUpdated', callback));
        }

        function saveToLocalStorage (data, key) {
            var storageObject = {
                timestamp: new Date().getTime(),
                data: data
            };

            localStorage.setItem(key, JSON.stringify(storageObject));
        }
    }
}());
