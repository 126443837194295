angular.module('storefrontApp').directive('tbgPagination', [
    function () {
        return {
            restrict: 'EA',
            templateUrl: '/templates/pagination.template.html',
            link: function (scope, element, attrs) {
                var pagination = null;
                if (!scope.pagination) {
                    scope.pagination = scope.ctrl.pagination;
                }
                scope.showDropDown = true;

                // // num of records on current page: whatever is less, page size or total count
                scope.numRecords = Math.min(scope.pagination.totalCount, scope.pagination.pageSize);
                scope.numPages = Math.ceil(scope.pagination.totalCount / scope.pagination.pageSize);

                scope.currentRecord = scope.pagination.pageSize * (scope.pagination.currentPage - 1) + 1;
                scope.lastVisibleRecord = scope.currentRecord + scope.numRecords - 1;

                scope.$watch('pagination.currentPage + pagination.pageSize + pagination.totalCount', function (n, o) {
                    // // num of records on current page: whatever is less, page size or total count
                    scope.numRecords = Math.min(scope.pagination.totalCount, scope.pagination.pageSize);
                    scope.numPages = Math.ceil(scope.pagination.totalCount / scope.pagination.pageSize);

                    scope.pages = [];
                    if (scope.pagination.currentPage > 0 && scope.pagination.currentPage <= scope.numPages) {
                    // valid page number entered
                        scope.currentRecord = scope.pagination.pageSize * (scope.pagination.currentPage - 1) + 1;
                        scope.lastVisibleRecord = (scope.currentRecord + scope.numRecords - 1) > scope.pagination.totalCount ? scope.pagination.totalCount : scope.currentRecord + scope.numRecords - 1;

                        // Default page limits
                        var startPage = 1; var
                            endPage = scope.numPages;

                        // show input instead of dropwown if number of pages exceeds 500
                        if (scope.numPages > 500) {
                            scope.showDropDown = false;
                        } else {
                        // Add page number links
                            for (var number = startPage; number <= endPage; number++) {
                                scope.pages.push(number);
                            }
                            if (isIELessThan(11)) {
                                var select = element.find('select');
                                for (var x = 0; x < select.length; x++) {
                                    select[x].parentNode.insertBefore(select[x], select[x]);
                                }
                            }
                        }
                    } else if (scope.currentPage > scope.numPages) {
                    // number greater than total pages, default to last page
                        scope.selectPage(scope.numPages);
                    } else if (parseInt(scope.currentPage) && scope.currentPage < 1) {
                    // number less than 1 default to 1
                        scope.selectPage(1);
                    }
                });
                scope.noPrevious = function () {
                    return scope.pagination.currentPage === 1;
                };
                scope.nextPage = function () {
                    scope.pagination.currentPage++;
                };
                scope.prevPage = function () {
                    scope.pagination.currentPage--;
                };
                scope.noNext = function () {
                    return scope.pagination.currentPage === scope.numPages;
                };
                scope.isActive = function (page) {
                    return scope.pagination.currentPage === page;
                };

                scope.selectPage = function (page) {
                    if (page > 0 && page <= scope.numPages) {
                        scope.pagination.currentPage = page;
                    }
                };
            }
        };
    }
]);
