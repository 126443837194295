function CometBusinessObjectBehaviour (className, behaviourFunctions) {
    this.className = className;
    this.behaviourFunctions = behaviourFunctions;
}

var cometBusinessObjectBehaviour = {
    classes: new Array(),
    add: function (className, behaviourFunctions) {
        var aClass = new CometBusinessObjectBehaviour(className, behaviourFunctions);
        this.classes[this.classes.length] = aClass;
    },
    getWithClassName: function (className) {
        var classes = this.classes;
        for (var i = 0; i < classes.length; i++) {
            var each = classes[i];
            if (each.className == className) {
                return each;
            }
        }
        return null;
    },
    addBehavior: function (cometBO, behaviourFunctions) {
        for (var i in behaviourFunctions) {
            cometBO[i] = behaviourFunctions[i];
        }
        return cometBO;
    }
};

/*
* Comet Business Object.  these objects represent a real java business object on the server.  field names will match field names on the real object
*  as defined in the comet xml property.
*/
function CometBusinessObject (objectNode) {
    // instance vars common for all bo's.
    this.javaClassName;
    this._properties = new Array();

    // functions
    this.setPropertyNode = CometBusinessObject_setPropertyNode;
    this.setPropertyInt = CometBusinessObject_setPropertyInt;
    this.setPropertyDouble = CometBusinessObject_setPropertyDouble;
    this.setPropertyBoolean = CometBusinessObject_setPropertyBoolean;
    this.setPropertyDate = CometBusinessObject_setPropertyDate;
    this.setPropertyMoney = CometBusinessObject_setPropertyMoney;
    this.setPropertyString = CometBusinessObject_setPropertyString;
    this.setPropertyBO = CometBusinessObject_setPropertyBO;
    this.setPropertyMap = CometBusinessObject_setPropertyMap;
    this.setPropertyArrayList = CometBusinessObject_setPropertyArrayList;
    this.setPropertyJSON = CometBusinessObject_setPropertyJSON;
    this.setPropertyEnumeration = CometBusinessObject_setPropertyEnumeration;
    this.getProperty = CometBusinessObject_getProperty;
    this.setProperty = CometBusinessObject_setProperty;
    this.get = CometBusinessObject_get;
    this.put = CometBusinessObject_put;
    this.getHtml = CometBusinessObject_getHtml;

    // private
    this.private_generateDebugInfo = CometBusinessObject_private_generateDebugInfo;

    // initialize from XML if this object came from server
    if (objectNode != null) {
        this.javaClassName = objectNode.getAttribute('class');
        var objectNodePropertyNodes = objectNode.childNodes;
        for (var i = 0; i < objectNodePropertyNodes.length; i++) {
            var objectNodePropertyNode = objectNodePropertyNodes[i];
            this.setPropertyNode(objectNodePropertyNode);
        }
    }
}
function CometBusinessObject_setPropertyNode (propertyNode) {
    var propertyName = propertyNode.getAttribute('name');

    // if single value node
    if (propertyNode.childNodes.length == 1) {
        var propertyValueNode = propertyNode.firstChild;
        if (propertyValueNode.nodeName == 'value') {
            var valueType = propertyValueNode.getAttribute('type');
            if (valueType == 'Integer') {
                this.setPropertyInt(propertyName, valueType, propertyValueNode.firstChild.nodeValue);
            } else if (valueType == 'Double') {
                this.setPropertyDouble(propertyName, valueType, propertyValueNode.firstChild.nodeValue);
            } else if (valueType == 'Boolean') {
                this.setPropertyBoolean(propertyName, valueType, propertyValueNode.firstChild.nodeValue);
            } else if (valueType == 'Date') {
                this.setPropertyDate(propertyName, valueType, propertyValueNode.firstChild.nodeValue);
            } else if (valueType == 'Money') {
                this.setPropertyMoney(propertyName, valueType, propertyValueNode.firstChild.nodeValue);
            } else if (valueType == 'MoneyString') {
                this.setPropertyString(propertyName, valueType, propertyValueNode.firstChild.nodeValue);
            } else if (valueType == 'String') {
                if (propertyValueNode.firstChild == null) {
                    this.setPropertyString(propertyName, valueType, '');
                } else {
                    var value = '';
                    if (typeof propertyValueNode.textContent != 'undefined') {
                        value = propertyValueNode.textContent;
                    } else {
                        value = propertyValueNode.firstChild.nodeValue;
                    }
                    this.setPropertyString(propertyName, valueType, value);
                }
            } else if (valueType == 'SerializedObject') {
                // this is JDCometSerializableObjectProperty.SERIALIZEDOBJECT
                this.setPropertyBO(propertyName, valueType, propertyValueNode.firstChild);
            } else if (valueType == 'Map') {
                this.setPropertyMap(propertyName, valueType, propertyValueNode.firstChild);
            } else if (valueType == 'ArrayList') {
                this.setPropertyArrayList(propertyName, valueType, propertyValueNode);
            } else if (valueType == 'JSONOBJECT') {
                this.setPropertyJSON(propertyName, valueType, propertyValueNode);
            } else if (valueType == 'Enumeration') {
                this.setPropertyEnumeration(propertyName, valueType, propertyValueNode.firstChild);
            }
        }
    }
}
function CometBusinessObject_setPropertyInt (propertyName, valueType, stringValue) {
    if (stringValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        this.setProperty(propertyName, valueType, toInt(stringValue));
    }
}
function CometBusinessObject_setPropertyDouble (propertyName, valueType, stringValue) {
    if (stringValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        this.setProperty(propertyName, valueType, toFloat(stringValue));
    }
}
function CometBusinessObject_setPropertyBoolean (propertyName, valueType, stringValue) {
    if (stringValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        this.setProperty(propertyName, valueType, stringValue == 'true');
    }
}
function CometBusinessObject_setPropertyDate (propertyName, valueType, stringValue) {
    if (stringValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        var date = new Date();
        date.setTime(Date.parse(stringValue));

        // alertPanel.alert(stringValue + " " +date.getDate());
        this.setProperty(propertyName, valueType, date);
    }
}
function CometBusinessObject_setPropertyMoney (propertyName, valueType, stringValue) {
    if (stringValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        this.setProperty(propertyName, valueType, toFloat(stringValue));
    }
}
function CometBusinessObject_setPropertyString (propertyName, valueType, stringValue) {
    if (stringValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        this.setProperty(propertyName, valueType, strUnweb(stringValue));
    }
}
function CometBusinessObject_setPropertyBO (propertyName, valueType, objectNode) {
    if (objectNode.nodeName == '#text' && objectNode.nodeValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        var bo = new CometBusinessObject(objectNode);
        var behaviorClass = cometBusinessObjectBehaviour.getWithClassName(bo.javaClassName);
        if (behaviorClass != null) {
            bo = cometBusinessObjectBehaviour.addBehavior(bo, behaviorClass.behaviourFunctions);
        }
        this.setProperty(propertyName, valueType, bo);
    }
}
function CometBusinessObject_setPropertyEnumeration (propertyName, valueType, objectNode) {
    if (objectNode.nodeName == '#text' && objectNode.nodeValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        var bo = new CometBusinessObject(objectNode);
        this.setProperty(propertyName, valueType, bo);
    }
}
function CometBusinessObject_setPropertyMap (propertyName, valueType, objectNode) {
    if (objectNode.nodeName == '#text' && objectNode.nodeValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        var bo = new CometBusinessObject(objectNode);
        this.setProperty(propertyName, valueType, bo);
    }
}
function CometBusinessObject_setPropertyArrayList (propertyName, valueType, valueNode) {
    if (valueNode.firstChild == null || (valueNode.firstChild.nodeName == '#text' && valueNode.firstChild.nodeValue == 'null')) {
        this.setProperty(propertyName, valueType, null);
    } else {
        var objectNodes = valueNode.childNodes;
        var objects = new Array();
        for (var i = 0; i < objectNodes.length; i++) {
            var objectNode = objectNodes[i];
            if (objectNode.nodeName == '#text' && objectNode.nodeValue == 'null') {
                this.setProperty(propertyName, valueType, null);
            } else {
                var bo = new CometBusinessObject(objectNode);
                var behaviorClass = cometBusinessObjectBehaviour.getWithClassName(bo.javaClassName);
                if (behaviorClass != null) {
                    bo = cometBusinessObjectBehaviour.addBehavior(bo, behaviorClass.behaviourFunctions);
                }
                objects[objects.length] = bo;
            }
        }
        this.setProperty(propertyName, valueType, objects);
    }
}
function CometBusinessObject_setPropertyJSON (propertyName, valueType, objectNode) {
    if (objectNode.nodeName == '#text' && objectNode.nodeValue == 'null') {
        this.setProperty(propertyName, valueType, null);
    } else {
        var value = '';
        if (typeof objectNode.textContent != 'undefined') {
            value = objectNode.textContent;
        } else {
            value = objectNode.firstChild.nodeValue;
            if (value == null) {
                var childNode = objectNode.firstChild;
                if (childNode.nodeType == 1) {
                    childNode = childNode.firstChild;
                }
                if (childNode != null && childNode.nodeType == 3) {
                    value = childNode.nodeValue;
                }
            }
        }
        var jsonObject = {};
        if (value != '') {
            jsonObject = $.parseJSON(value);
        }
        this.setProperty(propertyName, valueType, jsonObject);
    }
}
function CometBusinessObject_setProperty (propertyName, valueType, anyValue) {
    var property = this.getProperty(propertyName);
    if (property == null) {
        property = new CometBusinessObjectProperty(propertyName, valueType, anyValue);
        this._properties[this._properties.length] = property;
    } else {
        property.value = anyValue;
    }
    this[propertyName] = anyValue;
}

function CometBusinessObject_getProperty (propertyName) {
    var _properties = this._properties;
    for (var i = 0; i < _properties.length; i++) {
        var property = _properties[i];
        if (property.name == propertyName) {
            return property;
        }
    }
    return null;
}
function CometBusinessObject_get (propertyName) {
    // in preperation for "_properties" to go away or be less important.  use local values.
    return this[propertyName];
}

// depricated - set the value directly to the comet object instead.
function CometBusinessObject_put (propertyName, aValue) {
    var property = this.getProperty(propertyName);
    if (property == null) {
        // error?
    } else {
        property.setValue(aValue);
    }

    // new way
    this[propertyName] = aValue;
}
function CometBusinessObject_getHtml (propertyName) {
    var value = this.get(propertyName);
    return strUnweb(value);
}
function CometBusinessObject_private_generateDebugInfo () {
    var _properties = this._properties;
    var htmlContent = '';
    htmlContent += '\n<table border="0" cellspacing="0" class="debugObject">';
    htmlContent += '<tr><td colspan="2" class="javatypename">';
    htmlContent += strWeb(this.javaClassName);
    htmlContent += '</td></tr>\n';
    for (var i = 0; i < _properties.length; i++) {
        var property = _properties[i];
        htmlContent += property.private_generateDebugInfo();
    }
    htmlContent += '</table>\n';
    return htmlContent;
}

/*
* Comet Business Object Property.
*
*
*/
function CometBusinessObjectProperty (aName, aType, aValue) {
    this.name = aName;
    this.value = aValue;
    this.type = aType;

    // private
    this.private_generateDebugInfo = CometBusinessObjectProperty_private_generateDebugInfo;
    this.setValue = CometBusinessObjectProperty_setValue;
}
function CometBusinessObjectProperty_setValue (aValue) {
    this.value = aValue;
}
function CometBusinessObjectProperty_private_generateDebugInfo () {
    var htmlContent = '';
    htmlContent += '<tr><td>';
    htmlContent += strWeb(this.name);
    htmlContent += '</td><td>';
    if (this.value != null && (this.type == 'SerializedObject' || this.type == 'Map' || this.type == 'Enumeration')) {
        htmlContent += this.value.private_generateDebugInfo();
    } else if (this.value != null && this.type == 'ArrayList') {
        htmlContent += '\n<table border="0" cellspacing="0" class="debugObject">';
        htmlContent += '<tr>';
        for (var i = 0; i < this.value.length; i++) {
            htmlContent += '<td>' + i + '</td>';
        }
        htmlContent += '</tr>\n<tr>';
        for (var i = 0; i < this.value.length; i++) {
            var valueItem = this.value[i];
            htmlContent += '<td>';
            htmlContent += valueItem.private_generateDebugInfo();
            htmlContent += '</td>';
        }
        htmlContent += '</tr>\n</table>\n';
    } else {
        htmlContent += strWB(this.value);
    }
    htmlContent += '</td></tr>\n';
    return htmlContent;
}
