cometBusinessObjectBehaviour.add('TBGBOOrderShipment', {
    getShipFromName: function () {
        return this.shipFromFname + ' ' + this.shipFromLname;
    },
    getShipToName: function () {
        return this.shipToFname + ' ' + this.shipToLname;
    },
    isShipped: function () {
        return this.shipmentCreated;
    },
    getItemAllocationCount: function () {
        var itemAllocations = this.itemAllocations;
        if (itemAllocations == null) {
            return 0;
        }
        return itemAllocations.length;
    },
    getItemAllocationForItemId: function (id) {
        var itemAllocs = this.itemAllocations;
        for (var i = 0, ni = itemAllocs == null ? 0 : itemAllocs.length; i < ni; i++) {
            var citemalloc = itemAllocs[i];
            if (citemalloc.orderItem.id == id) {
                return citemalloc;
            }
        }
        return null;
    },
    isUpsIntegrated: function () {
        var cvendor = this.shippingVendor;
        if (cvendor != null && cvendor.name == 'UPS') {
            return true;
        }
        return false;
    },
    isUspsIntegrated: function () {
        var cvendor = this.shippingVendor;
        if (cvendor != null && cvendor.name == 'USPS') {
            return true;
        }
        return false;
    },
    isFedexIntegrated: function () {
        var cvendor = this.shippingVendor;
        if (cvendor != null && cvendor.name == 'FEDEX') {
            return true;
        }
        return false;
    },
    isIntegrated: function () {
        var isIntegrated = this.isUpsIntegrated() || this.isUspsIntegrated() || this.isFedexIntegrated();
        return isIntegrated;
    },
    getTrackingNumber: function () {
        if (this.isShipped() && this.isIntegrated()) {
            return this.shipmentIdNumber;
        }
        return this.manualShipTrackingNumber;
    },
    getShippingService: function () {
        var isIntegrated = this.isIntegrated();
        if (isIntegrated && this.shippingService != null) {
            return this.shippingService.shippingServiceName;
        }
        var isCustom = !isIntegrated && hasValue(this.shippingServiceNotes);
        if (isCustom) {
            return this.shippingServiceNotes;
        }
        return null;
    },
    isReadyToBeShipped: function () {
        // check to see that all items are ready to be shipped.
        var itemAllocations = this.itemAllocations;
        if (itemAllocations == null) {
            return false;
        }
        for (var i = 0, ni = itemAllocations.length; i < ni; i++) {
            var itemAllocation = itemAllocations[i];
            var orderItem = itemAllocation.orderItem;
            var offeringServiceType = orderItem.offeringServiceType;
            if (offeringServiceType.name == 'POD') {
                if (orderItem.cancelled == false) {
                    var productionStatus = orderItem.productionStatus;
                    if (productionStatus == null) {
                        return false;
                    }
                    if (productionStatus.name != 'Finished') {
                        return false;
                    }
                    var parentOrder = orderItem.parentOrder;
                    if (parentOrder.closed == true) {
                        return false;
                    }
                    var parentOrderStatus = parentOrder.status;
                    if (parentOrderStatus.name == 'Shipped' || parentOrderStatus.name == 'Cancelled') {
                        return false;
                    }
                }
            } else if (offeringServiceType.name == 'STOCK') {
                // always shippable state
            }
        }
        return true;
    }
});
