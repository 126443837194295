(function (window, GrowlMessages) {
    'use strict';

    window.growlMessages = new GrowlMessages(
        'userMessages',
        'userMessageContent',
        'growlMessages',
        { defaultCssClasses: 'userMessages hideIt' }
    );
}(window, window.GrowlMessages));
