(function () {
    var app = angular.module('storefrontApp', [
        'ngAnimate',
        'ngSanitize',
        'ui.bootstrap',
        'growlNotifications',
        'collaterate.common',
        'collaterate.common.directives.currency',
        'collaterate.common.directives.autocomplete',
        'collaterate.common.plupload',
        'ioHelpers',
        'templates',
        'tbg.errorReporting',
        'userNotifications.v1',
        'collaterate.interceptors'
    ]);

    // declare templates module, to be primed via gulp
    angular.module('templates', []);

    // declare versioned widget modules
    angular.module('userNotifications.v1', []);

    app.constant('restRootUrl', '/rest/internal/v1/storefront');

    // Set header on all get rest requests to turn off resource links
    app.run([
        '$http', 'ioHelpers', function ($http, ioHelpers) {
            $http.defaults.headers.get = {
                'collaterate-hide-resource-links': 'true'
            };
            $http.ioHelpers = ioHelpers;
        }
    ]);

    app.config([
        '$uibModalProvider', function ($uibModalProvider) {
            var storefrontModalDefaultOptions = {
                windowTemplateUrl: '/templates/modal-panel.template.html',
                openedClass: 'panel-open',
                backdropClass: 'screenOverlay panelOverlay'
            };

            angular.merge($uibModalProvider.options, storefrontModalDefaultOptions);
        }
    ]);
}());
