function PageGlobals () {
    this.debugDivId = 'pageGlobalsDebugDiv';
    this.thisName = getRandomNumber();

    // working
    this.keys = new Array();

    // data
    // the keys and their values are actually stored right on this instance.  makes for easier retrieval.
    // the list of keys allows for the debug info to be displayed quicker.

    // functions
    this.has = PageGlobals_has;
    this.set = PageGlobals_set;
    this.get = PageGlobals_get;
    this.generateDebugInfo = PageGlobals_generateDebugInfo;
}
function PageGlobals_has (key) {
    for (var i = 0; i < this.keys.length; i++) {
        var akey = this.keys[i];
        if (akey == key) {
            return true;
        }
    }
    return false;
}
function PageGlobals_set (key, cometObject) {
    this[key] = cometObject;
    if (!this.has(key)) {
        this.keys[this.keys.length] = key;
    }
    if (DEBUG) {
        this.generateDebugInfo();
    }
}
function PageGlobals_get (key) {
    return this[key];
}
function PageGlobals_generateDebugInfo () {
    var containerDivId = this.debugDivId;
    var containerDiv = $('#' + containerDivId);
    if (containerDiv != null) {
        var divId = containerDivId + this.thisName;
        var div = $('#' + divId)[0];
        if (div == null) {
            containerDiv.append('<div id="' + divId + '"></div>');
        }
        var content = '<br /><br />';
        for (var i = 0; i < this.keys.length; i++) {
            var key = this.keys[i];
            var object = this[key];
            content += '<table border="0" cellspacing="0" class="debugObject">\n';
            content += '<tr><td>';
            content += strWeb(key);
            content += '</td><td>';
            if (object == null) {
                content += 'NULL';
            } else if (object.private_generateDebugInfo) {
                content += object.private_generateDebugInfo();
            } else {
                content += JSON.stringify(object);
            }
            content += '</td></tr>\n';
            content += '</table><br />\n\n';
        }
        dynamic.setDivIdContent(divId, content);
    }
}
