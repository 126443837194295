cometBusinessObjectBehaviour.add('TBGBOSystemOffering', {
    hasProof: function (aProof) {
        var offeringProofs = this.proofs;
        if (offeringProofs == null) {
            return false;
        }
        for (var i = 0; i < offeringProofs.length; i++) {
            var offeringProof = offeringProofs[i];
            var proof = offeringProof.proof;
            if (proof.id == aProof.id) {
                return true;
            }
        }
        return false;
    },
    isServiceTypeStock: function () {
        var offeringServiceType = this.offeringServiceType;
        return offeringServiceType.name === 'STOCK';
    },
    isServiceTypePOD: function () {
        var offeringServiceType = this.offeringServiceType;
        return offeringServiceType.name === 'POD';
    },
    isPodArtworkFromStorage: function () {
        if (this.podArtworkSource != null) {
            if (this.podArtworkSource.id === 'STORED') {
                return true;
            }
        }
        return false;
    },
    isPodArtworkFromTemplate: function () {
        if (this.podArtworkSource != null) {
            if (this.podArtworkSource.id === 'TEMPLATE') {
                return true;
            }
        }
        return false;
    },
    isPodArtworkFromUser: function () {
        if (this.podArtworkSource != null) {
            if (this.podArtworkSource.id === 'USER_UPLOAD') {
                return true;
            }
        }
        return false;
    },
    getStockAttributeTitleCount: function () {
        var i = 0;
        if (hasValue(this.stockAttributeTitle1)) {
            i += 1;
        }
        if (hasValue(this.stockAttributeTitle2)) {
            i += 1;
        }
        if (hasValue(this.stockAttributeTitle3)) {
            i += 1;
        }
        return i;
    },
    getVariant: function (id) {
        var variants = this.variants;
        for (var i = 0, ni = variants == null ? 0 : variants.length; i < ni; i++) {
            var variant = variants[i];
            if (variant.id == id) {
                return variant;
            }
        }
        return null;
    },
    getMarkupTypeName: function () {
        var markupType = this.markupType;
        if (!hasValue(markupType)) {
            return ' ';
        }
        if (markupType === 'QUANTITY') {
            return 'Quantity';
        }
        if (markupType === 'THROUGHPUT') {
            return 'Device Throughput';
        }
        if (markupType === 'SQUARE FEET') {
            return 'Square Feet';
        }
        return ' ';
    }
});
