cometBusinessObjectBehaviour.add('TBGBOUser', {
    getNumberOfAddresses: function () {
        var addresses = this.addresses;
        if (addresses == null) {
            return 0;
        }
        return addresses.length;
    },
    getAddressOfType: function (addressType) {
        var addresses = this.addresses;
        if (addresses == null) {
            return null;
        }
        for (var i = 0; i < addresses.length; i++) {
            var address = addresses[i];
            var addressAddressType = address.addressType;
            if (addressAddressType != null && addressAddressType.indexOf(addressType) != -1) {
                address._INDEX = i;
                return address;
            }
        }
        return null;
    },
    getBlindFromAddress: function () {
        return this.getAddressOfType(TBGBOUserAddress.BLIND_FROM);
    },
    getBillToAddress: function () {
        return this.getAddressOfType(TBGBOUserAddress.BILL_TO);
    },
    getShipToAddress: function () {
        return this.getAddressOfType(TBGBOUserAddress.SHIP_TO);
    },
    isSystemAdmin: function () {
        return false;
    }
});
