(function () {
    angular
        .module('ioHelpers', [])
        .factory('ioHelpers', ioHelpers);

    function ioHelpers () {
        return {
            isNotString: isNotString,
            isBlank: isBlank,
            isString: isString,
            flattenObject: flattenObject,
            toQueryString: toQueryString
        };

        function isNotString (str) {
            return !isString(o);
        }

        function isBlank (str) {
            return !str || /^\s*$/.test(str);
        }

        function isString (o) {
            return o && (typeof o === 'string' || (typeof o === 'object' && o.constructor === String));
        }

        function flattenObject (ob) {
            // Takes a json object and flattens it so that { 'aProp': 'string', 'aObj' : { 'aObjProp' : 'string'}}
            // becomes { 'aProp': 'string', 'aObj.aObjProp' :  'string' }
            // Works well for 'GET' rest calls that have a complicated object pojo for input
            var toReturn = {};

            for (var i in ob) {
                if (!ob.hasOwnProperty(i)) {
                    continue;
                }

                if (typeof ob[i] === 'object') {
                    var flatObject = flattenObject(ob[i]);

                    for (var x in flatObject) {
                        if (!flatObject.hasOwnProperty(x)) {
                            continue;
                        }

                        toReturn[i + '.' + x] = flatObject[x];
                    }
                } else {
                    toReturn[i] = ob[i];
                }
            }

            return toReturn;
        }

        function toQueryString (obj, delimeterValue) {
            outer = this;

            // Takes a json object and flattens it for use as a parameter value
            // common usage would be to perform a search with parameter q="value1=somestring:value2=3" from a json object {"value1":"somestring" ,"value2":3}

            var delimeter = delimeterValue || ':'; // default to colon if non provided.
            var query = {
                q: ''
            };

            angular.forEach(obj, function (value, key) {
                // from angular doco this. is the query object context
                if (value !== null && typeof value !== 'undefined' && (!outer.isString(value) || (outer.isString(value) && !outer.isBlank(value)))) {
                    if (this.q !== '') {
                        this.q += delimeter;
                    }

                    this.q += key + '=' + value;
                }
            }, query);

            return query.q;
        }
    }
}());
