/**
 * @ngdoc object
 * @name window.tbg.eventHub
 * @description
 * `window.tbg.eventHub`
 *
 * Classic notification service, enables event communication between the classic and angular worlds
 *
 * #### Events
 * - `STOREFRONT_BULK_ADD_LOADED` (see Properties section below for event information)
 * - `STOREFRONT_BULK_ADD_MOVED` (see Properties section below for event information)
 * - `STOREFRONT_REQUEST_REQUOTE_START` (see Properties section below for event information)
 * - `STOREFRONT_REQUEST_REQUOTE_END` (see Properties section below for event information)
 * - `STOREFRONT_DECLINE_QUOTE_START` (see Properties section below for event information)
 * - `STOREFRONT_DECLINE_QUOTE_END` (see Properties section below for event information)
 * - `STOREFRONT_SALES_ESTIMATE_LOADED` (see Properties section below for event information)
 * - `CHILI_DOCUMENT_LOADED` (see Properties section below for event information)
 * - `CHILI_ADD_TO_CART` (see Properties section below for event information)
 * - `CHECKOUT_DIVISIONS_LOADED` (see Properties section below for event information)
 * - `THANKYOU_DIGITAL_DOWNLOAD_LINK` (see Properties section below for event information)
 */

/**
 * @ngdoc object
 * @name window.tbg.eventHub.events
 * @propertyOf window.tbg.eventHub
 * @description
 * Object containing event keys
 * @returns {object} Returns object of event keys to be used with eventHub
 * @example
 <pre>
    window.tbg.eventHub.events;

    returns {
        STOREFRONT_BULK_ADD_LOADED: 'STOREFRONT_BULK_ADD_LOADED',             // The eventName of the event raised when bulk-add products have loaded for the first time
        STOREFRONT_BULK_ADD_MOVED: 'STOREFRONT_BULK_ADD_MOVED',               // The eventName of the event raised when a bulk-add product has been staged or un-staged
        STOREFRONT_REQUEST_REQUOTE_START: 'STOREFRONT_REQUEST_REQUOTE_START', // The eventName of the event raised when a storefront user has clicked the "request requote" button on a custom project quote
        STOREFRONT_REQUEST_REQUOTE_END: 'STOREFRONT_REQUEST_REQUOTE_END',     // The eventName of the event raised when a storefront user has clicked the "cancel request requote" button on a custom project quote, or the page data is refreshed and no longer in that state
        STOREFRONT_DECLINE_QUOTE_START: 'STOREFRONT_DECLINE_QUOTE_START',     // The eventName of the event raised when a storefront user has clicked the "decline quote" button on a custom project quote
        STOREFRONT_DECLINE_QUOTE_END: 'STOREFRONT_DECLINE_QUOTE_END',         // The eventName of the event raised when a storefront user has clicked the "cancel decline quote" button on a custom project quote, or the page data is refreshed and no longer in that state
        STOREFRONT_SALES_ESTIMATE_LOADED: 'STOREFRONT_SALES_ESTIMATE_LOADED', // The eventName of the event raised when a storefront Sales Estimate has loaded / rendered
        CHILI_DOCUMENT_LOADED: 'CHILI_DOCUMENT_LOADED',                       // The eventName of the event raised when a Chili document has loaded and is ready to work with
        CHILI_ADD_TO_CART: 'CHILI_ADD_TO_CART',                               // The eventName of the event raised when a storefront user wants to add a Chili document to their cart
        CHECKOUT_DIVISIONS_LOADED: 'CHECKOUT_DIVISIONS_LOADED',               // The eventName of the event raised when a storefront user moves to the first page of checkout and the division list is loaded. The event payload uses "dd_name"  to provide the name attribute of the division dropdown field.
        THANKYOU_DIGITAL_DOWNLOAD_LINK: 'THANKYOU_DIGITAL_DOWNLOAD_LINK',     // The eventName of the event raised when a storefront user moves to the thankyou page and if there is any digital item in the Order. The event generates the Downloadable link for digital item.
    }
 </pre>
 */

/**
 * @ngdoc method
 * @name window.tbg.eventHub#on
 * @methodOf window.tbg.eventHub
 * @description Binds handlers to Collaterate eventHub events.
 *
 * @param {string} eventName The event to bind to.
 * @param { function (event, data) } handler Function to handle the event. Will be called with two parameters, the event object and an event data object.
 * @example
 <pre>
    // Bind to an event
    window.tbg.eventHub.on(window.tbg.eventHub.events.STOREFRONT_REQUEST_REQUOTE_START, function (event, data) {
        // Do something
    });
 </pre>
 */

/**
 * @ngdoc method
 * @name window.tbg.eventHub#off
 * @methodOf window.tbg.eventHub
 * @description Removes event bindings for an event.
 *
 * @param {string} eventName The event to stop listening to.
 * @param {function=} handler Reference to handler function that we want to unbind. If no handler function is passed, all handler functions for eventName will be unbound.
 * @example
 <pre>
    // Stop listening to event
    window.tbg.eventHub.off(window.tbg.eventHub.events.STOREFRONT_REQUEST_REQUOTE_START, myBoundHandler);
 </pre>
 */

/**
 * @ngdoc method
 * @name window.tbg.eventHub#trigger
 * @methodOf window.tbg.eventHub
 * @description Triggers specified event.
 *
 * @param {string} eventName The event to trigger.
 * @param {object} data The event data listeners will be expecting when handling the event
 * @example
 <pre>
    // Trigger an event
    window.tbg.eventHub.trigger(window.tbg.eventHub.events.STOREFRONT_REQUEST_REQUOTE_START);
 </pre>
 */

(function (window, $) {
    'use strict';

    /*
        Event hub for classic javascript world. Exposes window.tbg.eventHub to the global scope.

        Has corresponding "classicEventService" in Angular app.

        Uses jQuery on/off/trigger methods. Useage examples:
        - window.tbg.eventHub.on(window.tbg.eventHub.events.EVENT_NAME, handlerFunction);
        - window.tbg.eventHub.off(window.tbg.eventHub.events.EVENT_NAME, handlerFunction);
        - window.tbg.eventHub.trigger(window.tbg.eventHub.events.EVENT_NAME [, extraParameters ]);

        First argument passed to `handlerFunction` is always the event object. The "extraParameters"
        argument on the `trigger` method is an array or an object. If an array, each item in the
        array is passed as a separate argument to `handlerFunction`. If an object, the object is
        passed whole as the second argument to `handlerFunction`.

        Enumerate available event names below, and document any handler signatures here.
    */

    var events = {
        // Called when the session object is modified outside of Comet. Useful for
        // refreshing working object when this happens to avoid ye olde out of sync error.
        SESSION_OBJECT_MODIFIED: 'SESSION_OBJECT_MODIFIED',

        // Called when comet response sets the global production plan
        PRODUCTION_PLAN_SET: 'PRODUCTION_PLAN_SET',

        // Called when comet response sets the global cquote
        QUOTE_SET: 'QUOTE_SET',

        // Called when bulk add products have finished loading on storefront
        STOREFRONT_BULK_ADD_LOADED: 'STOREFRONT_BULK_ADD_LOADED',

        // Called when a bulk add product is staged/unstaged on storefront
        STOREFRONT_BULK_ADD_MOVED: 'STOREFRONT_BULK_ADD_MOVED',

        // Called when storefront user is requesting a requote
        STOREFRONT_REQUEST_REQUOTE_START: 'STOREFRONT_REQUEST_REQUOTE_START',

        // Called when storefront user is no longer requesting a requote
        STOREFRONT_REQUEST_REQUOTE_END: 'STOREFRONT_REQUEST_REQUOTE_END',

        // Called when storefront user is declining a quote
        STOREFRONT_DECLINE_QUOTE_START: 'STOREFRONT_DECLINE_QUOTE_START',

        // Called when a storefront user is no longer declining a quote
        STOREFRONT_DECLINE_QUOTE_END: 'STOREFRONT_DECLINE_QUOTE_END',

        // Called when storefront Sales Estimate has loaded / rendered
        STOREFRONT_SALES_ESTIMATE_LOADED: 'STOREFRONT_SALES_ESTIMATE_LOADED',

        // Called when a Chili document has loaded and is ready to work with
        CHILI_DOCUMENT_LOADED: 'CHILI_DOCUMENT_LOADED',

        // Trigger in order to add Chili product to cart
        CHILI_ADD_TO_CART: 'CHILI_ADD_TO_CART',

        // Trigger when division list is loaded for checkout. Used in theme code by some storefronts to enable `chosen` plugin.
        CHECKOUT_DIVISIONS_LOADED: 'CHECKOUT_DIVISIONS_LOADED',

        // Trigger when there is a Digital Download file in Thank you page
        THANKYOU_DIGITAL_DOWNLOAD_LINK: 'THANKYOU_DIGITAL_DOWNLOAD_LINK'
    };

    if (!$) {
        console.error('TBGEventHub requires jQuery to be loaded first.');
        return;
    }

    init();

    return;
    function init () {
        var $eventHub = $({});
        $eventHub.events = {};

        for (var event in events) {
            if (events.hasOwnProperty(event)) {
                $eventHub.events[event] = events[event];
            }
        }

        window.tbg = window.tbg || {};
        window.tbg.eventHub = $eventHub;
    }
}(window, jQuery));
