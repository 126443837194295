var cookie = Cookie.load(cookieAndLoginGlobals.SPCOOKIEPATH, cookieAndLoginGlobals.SPCOOKIENAME, cookieAndLoginGlobals.SPCOOKIEEXPIREDAYS);
if (cookie == null) {
    cookie = new Cookie(cookieAndLoginGlobals.SPCOOKIEPATH, cookieAndLoginGlobals.SPCOOKIENAME, cookieAndLoginGlobals.SPCOOKIEEXPIREDAYS);
} else {
    // extend the expiration date if there is a user key present
    if (hasValue(cookie.get(cookieAndLoginGlobals.SPCOOKIEKEY))) {
        cookie.save();
    }
}

var login = {
    key: function (event) {
        if (isEnterEvent(event)) {
            event.stopPropagation();
            login.loginClicked();
        }
    },
    loginInProcess: false,
    loginClicked: function () {
        if (!this.loginInProcess) {
            this.loginInProcess = true;
            var validator = new Validator();
            var signinDivContext = $('#signin');
            var validateItemOptions = {
                customParent: true,
                parent: signinDivContext
            };
            validator.addItem(newValidateItem('unameEF', 'Username', validateItemOptions));
            validator.addItem(newValidateItem('pwdEF', 'Password', validateItemOptions));
            if (validator.isValid()) {
                var remember = isChecked('rememberCB', signinDivContext);
                var cometRequest = comet.newRequest(cookieAndLoginGlobals.loginHandlerURL, 'LOGINACTION', function (cometRequest) {
                    login.loginInProcess = false;
                    if (cometRequest.hasErrorMessages()) {
                        growlMessages.showAll(cometRequest);
                        focusOn('unameEF', signinDivContext);
                    } else {
                        globals.setCUser(cometRequest.getObject('USER'));
                        events.trigger(events.USER_JUST_LOGGED_IN);
                    }
                });

                // cometRequest.statusDivId = 'loginMessages';
                cometRequest.addParameter('LOGINUSERNAMEEF', getFormItemValue('unameEF', signinDivContext));
                cometRequest.addParameter('LOGINPASSWORDEF', getFormItemValue('pwdEF', signinDivContext));
                if (remember) {
                    cometRequest.addParameter('LOGINREMEMBERCB', 'true');
                }
                cometRequest.send();
            } else {
                login.loginInProcess = false;
            }
        }
    }
};

var passwordPanel = {
    efName: 'PASS_USER_NAME_EF',
    open: function () {
        var content = '';
        content += '<h2>Password Retrieval</h2>';
        content += tags.div('', {
            id: 'passwordMessages',
            cssClass: 'cometMessages'
        });
        content += '<div id="passwordContent">';
        content += '<p>Please enter your username or email address and click continue. An email will be sent with instructions for updating your password.</p>';
        var table = new JDTable({
            cssClass: 'formFields'
        });
        table.addRow([
            {
                text: 'Username / Email',
                tag: 'th'
            },
            forms.ef(this.efName, '', {
                cssClass: 'field ',
                onkeypress: 'passwordPanel.keyUp(event)'
            })
        ], {
            cssClass: 'required'
        });
        content += table.generate();
        content += forms.button('Continue', {
            onclick: 'passwordPanel.continueClicked()'
        });
        content += tags.div('', {
            id: 'passwordStatus'
        });
        content += '</div>';
        savePanel.open(content, {
            showSave: false,
            showCancel: false,
            afterShow: function () {
                focusOn(this.efName, $('#savePanelContent'));
            }
        });
    },
    close: function () {
        savePanel.close();
    },
    keyUp: function (event) {
        if (isEnterEvent(event)) {
            // blurFormItem(this.efName, $('#savePanelContent'));
            this.continueClicked();
        }
    },
    continueClicked: function () {
        var validator = new Validator();
        var signinDivContext = document;
        var validateItemOptions = {
            customParent: true,
            parent: signinDivContext
        };
        validator.addItem(newValidateItem(this.efName, 'Username', validateItemOptions));
        if (validator.isValid()) {
            var tempthis = this;
            var cometRequest = comet.newRequest(cookieAndLoginGlobals.passwordHandlerURL, 'REQUEST_PASSWORD_RESET_ACTION', function (cometRequest) {
                growlMessages.showAll(cometRequest);
                if (cometRequest.hasErrorMessages()) {
                    focusOn(tempthis.efName, $('#savePanelContent'));
                } else {
                    passwordPanel.close();
                }
            });
            cometRequest.statusDivId = 'passwordStatus';
            cometRequest.addFormField(this.efName, true, signinDivContext);
            cometRequest.send();
        }
    }
};

var general = {
    initialize: function () {
        this.display();
        this.hideShowIfSignedInElements();
    },
    display: function () {
        var cuser = globalpageglobals.cuser;
        var content = '';
        if (cuser == null) {
            content += tags.div(forms.ef('unameEF', '', {
                placeholder: 'Username',
                onkeyup: 'login.key(event)',
                cssClass: 'textField'
            }), {
                cssClass: 'signInField un'
            });
            content += tags.div(forms.ef('pwdEF', '', {
                type: 'password',
                placeholder: 'Password',
                onkeyup: 'login.key(event)',
                cssClass: 'textField'
            }), {
                cssClass: 'signInField pw'
            });
            content += tags.div(tags.a('Sign In', 'javascript:login.loginClicked()', {
                cssClass: 'button secondary signIn'
            }), {
                cssClass: 'signInField submit'
            });
            content += tags.div(forms.cb('rememberCB', 'Remember Me', false, {
                cssClass: 'check'
            }), {
                cssClass: 'rememberMe'
            });
            dynamic.setDivIdContent('accountMenu', '');
            dynamic.setDivIdContent('signin', content);
        } else {
            var ul2 = new JDUL({
                cssClass: 'accountLinks'
            });
            ul2.add(tags.a('Orders', cookieAndLoginGlobals.orderListURL, {
                onclick: linkTracker.getOnClickAttribute()
            }), {
                cssClass: 'accountLink orders'
            });
            ul2.add(tags.a('Account', cookieAndLoginGlobals.accountURL, {
                onclick: linkTracker.getOnClickAttribute()
            }), {
                cssClass: 'accountLink account'
            });
            if (cuser.siteAdmin == true) {
                ul2.add(tags.a('Site Administration', cookieAndLoginGlobals.siteAdminURL), {
                    cssClass: 'accountLink admin'
                });
            }
            ul2.add(tags.a('Sign Out', 'javascript:general.logoutClicked()'), {
                cssClass: 'accountLink signout'
            });
            var ul = new JDUL({
                cssClass: 'userAccountMenu'
            });
            ul.add(tags.span(strWeb(cuser.get('fname')) + ' ' + strWeb(cuser.get('lname')), {
                cssClass: 'userFullName'
            }) + ul2.generate());
            content += ul.generate();

            dynamic.setDivIdContent('signin', '');
            dynamic.setDivIdContent('accountMenu', content);
        }
    },
    hideShowIfSignedInElements: function () {
        var signedIn = globalpageglobals.cuser != null;
        var nodes = $('.ifSignedIn');
        if (signedIn) {
            nodes.show();
        } else {
            nodes.hide();
        }
        nodes = $('.ifNotSignedIn');
        if (signedIn) {
            nodes.hide();
        } else {
            nodes.show();
        }
    },
    sessionError: function () {
        var content = '';
        content += '<div id="userMessageError">This site requires browser cookies to sign in.</div>';
        dynamic.setDivIdContent('accountMenu', content);
    },
    logoutClicked: function () {
        gotoURL(cookieAndLoginGlobals.logoutHandlerURL);
    }
};

// similar to the events object, but:
// - allows parameters to be passed to listener functions
// - blocks calls to the "event", so that all listeners finish before execution flow returns to caller
/*
//sample theme code - this would go into a theme file
if (!window.console) console = {log:function(){}};
var sampleThemeEventsContainerObjectWithFancyNameLikeAccountEventsListener = {
    onUserRegistered: function(cuser) {
        console.log('user registered', cuser);
    },
    onAccountUpdated: function(cuserBefore, cuserAfter) {
        console.log('loaded', cuserBefore, cuserAfter);
    }
};
$(function(){
    accountEvents.registerOnPageLoadingListener(sampleThemeEventsContainerObjectWithFancyNameLikeAccountEventsListener);
    accountEvents.registerOnPageLoadedListener(sampleThemeEventsContainerObjectWithFancyNameLikeAccountEventsListener);
});
*/
var accountEvents = {
    // UserRegistered
    onUserRegisteredListeners: new Array(),
    registerOnUserRegisteredListener: function (listener) {
        if (listener != null && typeof listener == 'object' && typeof listener.onUserRegistered == 'function') {
            this.onUserRegisteredListeners[this.onUserRegisteredListeners.length] = listener;
        } else {
            throw new TypeError('expected an object having onUserRegistered function');
        }
    },

    // root function that calls registered listeners of UserRegistered event.
    // listeners should have provide a function with signature "onUserRegistered(cuser)"
    onUserRegistered: function (cuser) {
        for (var i = 0, ni = this.onUserRegisteredListeners == null ? 0 : this.onUserRegisteredListeners.length; i < ni; i++) {
            this.onUserRegisteredListeners[i].onUserRegistered(cuser);
        }
    },

    // AccountUpdated
    onAccountUpdatedListeners: new Array(),
    registerOnAccountUpdatedListener: function (listener) {
        if (listener != null && typeof listener == 'object' && typeof listener.onAccountUpdated == 'function') {
            this.onAccountUpdatedListeners[this.onAccountUpdatedListeners.length] = listener;
        } else {
            throw new TypeError('expected an object having onAccountUpdated function');
        }
    },

    // root function that calls registered listeners of AccountUpdated event.
    // listeners should have provide a function with signature "onAccountUpdated(cuserBefore, cuserAfter)"
    onAccountUpdated: function (cuserBefore, cuserAfter) {
        for (var i = 0, ni = this.onAccountUpdatedListeners == null ? 0 : this.onAccountUpdatedListeners.length; i < ni; i++) {
            this.onAccountUpdatedListeners[i].onAccountUpdated(cuserBefore, cuserAfter);
        }
    }
};

events.listen(events.SESSION_ERROR, general, 'initialize');
events.listen(events.USER_LOADED, general, 'initialize');
events.listen(events.USER_JUST_LOGGED_IN, general, 'initialize');
events.listen(events.USER_JUST_LOGGED_IN, general, 'hideShowIfSignedInElements');
