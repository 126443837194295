cometBusinessObjectBehaviour.add('TBGBOProject', {
    hasItems: function () {
        var items = this.items;
        return items != null && items.length > 0;
    },
    getItemWithId: function (id) {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                if (citem.id == id) {
                    return citem;
                }
            }
        }
        return null;
    },
    getFirstShipment: function () {
        var shipments = this.shipments;
        if (shipments == null || shipments.length == 0) {
            return null;
        }
        return shipments[0];
    },
    getFirstShipmentServiceName: function () {
        var shipment = this.getFirstShipment();
        if (shipment == null) {
            return null;
        }
        return shipment.shippingServiceName;
    },
    hasPODItem: function () {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                if (citem.isServiceTypePOD()) {
                    return true;
                }
            }
        }
        return false;
    },
    hasStockItem: function () {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                if (citem.isServiceTypeStock()) {
                    return true;
                }
            }
        }
        return false;
    },
    getFirstUserDiscountName: function () {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                var discountName = citem.userOfferingPriceDiscountName;
                if (hasValue(discountName)) {
                    return discountName;
                }
            }
        }
        return '';
    }
});
