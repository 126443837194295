cometBusinessObjectBehaviour.add('JDCometArrayList', {
    /**
	 * Search the list and return the first element having an object.id equal to the id parameter.
	 */
    getBO: function (id) {
        var list = this.list;
        var found = null;
        if (list) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    found = list[i];
                }
            }
        }
        return found;
    }
});
