(function () {
    'use strict';

    angular
        .module('storefrontApp')
        .factory('authService', authService);

    authService.$inject = ['$rootScope', '$window'];

    function authService ($rootScope, $window) {
        var isLoggedIn = false;
        var user = null;

        var service = {
            isLoggedIn: isLoggedInFn,
            subscribe: subscribe,
            getUser: getUser
        };

        init();

        return service;

        function init () {
            bindEvents();
            updateState();
        }

        function bindEvents () {
            $window.events.listen($window.events.USER_LOADED, updateState);
            $rootScope.$on('$destroy', unbindEvents);
        }

        function unbindEvents () {
            $window.events.unlisten($window.events.USER_LOADED, updateState);
        }

        function updateState () {
            var newUser = $window.globalpageglobals.cuser;

            if (!newUser) {
                isLoggedIn = false;
                user = null;
                notifySubscribers();
                return;
            }

            isLoggedIn = true;
            user = newUser;
            notifySubscribers();
        }

        function isLoggedInFn () {
            return isLoggedIn;
        }

        function getUser () {
            return user;
        }

        function subscribe (scope, callback) {
            scope.$on('$destroy', $rootScope.$on('authentication:change', callback));
        }

        function notifySubscribers () {
            $rootScope.$emit('authentication:change', user);
        }
    }
}());
