cometBusinessObjectBehaviour.add('TBGBOOrder', {
    hasItems: function () {
        var items = this.items;
        return items != null && items.length > 0;
    },

    // find [item with id=id]. do not consider children of items
    getDirectChildItemWithId: function (id) {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                if (citem.id == id) {
                    return citem;
                }
            }
        }
        return null;
    },
    getAnyChildItemWithId: function (id) {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                if (citem.id == id) {
                    return citem;
                }

                // check child
                var foundChild = citem.getChildItemWithId(id);
                if (foundChild) {
                    return foundChild;
                }
            }
        }
        return null;
    },

    // call this with either (parentId, childId) or (itemId)...
    // will return the direct child order item if only itemId or childId is
    // provided.
    // will return the production run if both parenId and childId are provided.
    getItemWithId: function (parentItemId, itemId) {
        if (parentItemId) {
            var cparentitem = this.getDirectChildItemWithId(parentItemId);
            if (cparentitem) {
                if (itemId) {
                    return cparentitem.getChildItemWithId(itemId);
                }
                return cparentitem;
            }
        } else {
            var foundItem = this.getDirectChildItemWithId(itemId);
            if (foundItem) {
                return foundItem;
            }

            // stop gap solution for hibernate conversion
            return this.getAnyChildItemWithId(itemId);
        }
        return null;
    },
    getFirstShipment: function () {
        var shipments = this.shipments;
        if (shipments == null || shipments.length == 0) {
            return null;
        }
        return shipments[0];
    },
    getFirstShipmentServiceName: function () {
        var shipment = this.getFirstShipment();
        if (shipment == null || shipment.shippingService == null) {
            return null;
        }
        return shipment.shippingService.shippingServiceName;
    },
    isShipped: function () {
        if (this.status != null && this.status.name == 'Shipped') {
            return true;
        }
        return false;
    },
    isCancelled: function () {
        if (this.status != null && this.status.name == 'Cancelled') {
            return true;
        }
        return false;
    },
    isAllPlannedShipmentsShipped: function () {
        var shipments = this.shipments;
        for (var i = 0, ni = shipments.length; i < ni; i++) {
            var cshipment = shipments[i];
            if (!cshipment.isShipped()) {
                return false;
            }
        }
        return true;
    },
    getItemQuantityShipped: function (citem) {
        var shippedItemQuantity = 0;
        var shipments = this.shipments;
        for (var i = 0, ni = shipments.length; i < ni; i++) {
            var cshipment = shipments[i];
            if (cshipment.isShipped()) {
                var callocation = cshipment.getItemAllocationForItemId(citem.id);
                if (callocation != null) {
                    shippedItemQuantity += callocation.quantity;
                }
            }
        }
        return shippedItemQuantity;
    },
    isAllItemQuantityShipped: function (citem) {
        return this.getItemQuantityShipped(citem) >= citem.totalAdjustedQuantity;
    },
    hasPODItem: function () {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                if (citem.isServiceTypePOD()) {
                    return true;
                }
            }
        }
        return false;
    },
    hasStockItem: function () {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                if (citem.isServiceTypeStock()) {
                    return true;
                }
            }
        }
        return false;
    },
    getFirstUserDiscountName: function () {
        if (this.hasItems()) {
            var items = this.items;
            for (var i = 0, ni = items.length; i < ni; i++) {
                var citem = items[i];
                var discountName = citem.userOfferingPriceDiscountName;
                if (hasValue(discountName)) {
                    return discountName;
                }
            }
        }
        return '';
    }
});
