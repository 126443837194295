(function () {
    'use strict';

    angular
        .module('tbg.errorReporting')
        .constant('errorTypes', {
            HTTP: 'Collaterate HTTP Error',
            WINDOW: 'Collaterate Window Error',
            STANDARD: 'Collaterate Standard Error'
        });
}());
