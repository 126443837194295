cometBusinessObjectBehaviour.add('TBGBOSystemOfferingSiteShare', {
    getListItemValue: function () {
        return 'SLS_' + this.id;
    },
    getListItemName: function () {
        return this.systemOffering.name + ' (Shared Product)';
    },
    getPublishedDescriptionHtml: function () {
        if (this.overrideDisplay) {
            return this.getHtml('description');
        }
        return this.systemOffering.getHtml('description');
    },
    getPublishedPricingPrintJobClassification: function () {
        if (this.overridePricing) {
            return this.pricingPrintJobClassification;
        }
        return this.systemOffering.pricingPrintJobClassification;
    },
    getPublishedPricingLargeFormatPrintJobClassification: function () {
        if (this.overridePricing) {
            return this.pricingLargeFormatPrintJobClassification;
        }
        return this.systemOffering.pricingLargeFormatPrintJobClassification;
    },
    getPublishedShowProof: function () {
        if (this.overrideProofs) {
            return this.showProof;
        }
        return this.systemOffering.showProof;
    },
    getPublishedEditProof: function () {
        if (this.overrideProofs) {
            return this.editProof;
        }
        return this.systemOffering.editProof;
    },
    getPublishedProofs: function () {
        if (this.overrideProofs) {
            return this.proofs;
        }
        return this.systemOffering.proofs;
    },
    getPublishedShowTurnaroundTime: function () {
        if (this.overrideTurnaroundTimes) {
            return this.showTurnaroundTime;
        }
        return this.systemOffering.showTurnaroundTime;
    },
    getPublishedEditTurnaroundTime: function () {
        if (this.overrideTurnaroundTimes) {
            return this.editTurnaroundTime;
        }
        return this.systemOffering.editTurnaroundTime;
    },
    getPublishedTurnaroundTimes: function () {
        if (this.overrideTurnaroundTimes) {
            return this.turnaroundTimes;
        }
        return this.systemOffering.turnaroundTimes;
    },
    isPodArtworkFromStorage: function () {
        return this.podArtworkSource != null && this.podArtworkSource.id === 'STORED';
    },
    isPodArtworkFromTemplate: function () {
        return this.podArtworkSource != null && this.podArtworkSource.id === 'TEMPLATE';
    },
    isPodArtworkFromUser: function () {
        return this.podArtworkSource != null && this.podArtworkSource.id == 'USER_UPLOAD';
    },
    isPublishedPodArtworkFromStorage: function () {
        if (this.overrideArtwork) {
            return this.isPodArtworkFromStorage();
        }
        return this.systemOffering.isPodArtworkFromStorage();
    },
    isPublishedPodArtworkFromTemplate: function () {
        if (this.overrideArtwork) {
            return this.isPodArtworkFromTemplate();
        }
        return this.systemOffering.isPodArtworkFromTemplate();
    },
    isPublishedPodArtworkFromUser: function () {
        if (this.overrideArtwork) {
            return this.isPodArtworkFromUser();
        }
        return this.systemOffering.isPodArtworkFromUser();
    },
    getPublishedThumbURL: function () {
        if (this.overrideDisplay) {
            return this.thumbURL;
        }
        return this.systemOffering.thumbURL;
    },
    getPublishedThumbWidth: function () {
        if (this.overrideDisplay) {
            return this.thumbWidth;
        }
        return this.systemOffering.thumbWidth;
    },
    getPublishedThumbHeight: function () {
        if (this.overrideDisplay) {
            return this.thumbHeight;
        }
        return this.systemOffering.thumbHeight;
    },
    getMarkupTypeName: function () {
        var markupType = this.markupType;
        if (!hasValue(markupType)) {
            return ' ';
        }
        if (markupType === 'QUANTITY') {
            return 'Quantity';
        }
        if (markupType === 'THROUGHPUT') {
            return 'Device Throughput';
        }
        if (markupType === 'SQUARE FEET') {
            return 'Square Feet';
        }
        return ' ';
    },
    isPublishedServiceTypePOD: function () {
        return this.systemOffering.isServiceTypePOD;
    },
    isSmallFormatPOD: function () {
        return this.isPublishedServiceTypePOD() && (this.getPublishedPricingPrintJobClassification() != null);
    },
    isLargeFormatPOD: function () {
        return this.isPublishedServiceTypePOD() && (this.getPublishedPricingLargeFormatPrintJobClassification() != null);
    },
    getPublishedProductId: function () {
        if (this.systemOffering) {
            return this.systemOffering.productId;
        }
        return null;
    },
    getPrefixedId: function (variantId) {
        var prefixedId = '';
        prefixedId += 'SLS_';
        prefixedId += this.systemOffering.id;
        prefixedId += '_';
        prefixedId += this.id;
        prefixedId += '_';
        prefixedId += variantId;
        return prefixedId;
    },
    getPublishedStockTrackInventory: function () {
        return this.systemOffering.stockTrackInventory;
    },
    getPublishedSku: function () {
        return this.systemOffering.skuNumber;
    },
    getPublishedSalesTaxCategory: function () {
        return this.systemOffering.salesTaxCategory;
    }
});
