var TBGBOUserAddress = {
    ID_PREFIX: 'ADD_',
    BILL_TO: 'BILL_TO',
    BLIND_FROM: 'BLIND_FROM',
    SHIP_TO: 'SHIP_TO'
};

cometBusinessObjectBehaviour.add('TBGBOUserAddress', {
    isAddressType: function (typeString) {
        var addressTypeString = this.addressType;
        if (hasValue(addressTypeString)) {
            return addressTypeString.indexOf(typeString) >= 0;
        }
        return false;
    },
    getAddressTypeDescription: function () {
        var answer = '';
        if (this.isAddressType(TBGBOUserAddress.SHIP_TO)) {
            answer += 'Ship To';
        }
        if (this.isAddressType(TBGBOUserAddress.BLIND_FROM)) {
            if (answer.length > 0) {
                answer += ', ';
            }
            answer += 'Blind Ship From';
        }
        if (this.isAddressType(TBGBOUserAddress.BILL_TO)) {
            if (answer.length > 0) {
                answer += ', ';
            }
            answer += 'Bill To';
        }
        if (answer.length > 0) {
            return answer;
        }
        return null;
    },
    getTypeAndId: function () {
        return TBGBOUserAddress.ID_PREFIX + this.id;
    },
    isType: function (prefix) {
        return this.getTypeAndId().indexOf(prefix) == 0;
    }
});
