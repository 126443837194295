(function () {
    'use strict';

    angular
        .module('tbg.errorReporting')
        .factory('stackTrace', stackTrace);

    stackTrace.$inject = ['$window'];

    function stackTrace ($window) {
        return {
            get: $window.StackTrace.get,
            fromError: $window.StackTrace.fromError
        };
    }
}());
