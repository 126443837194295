var SPINNERIMAGE = '/web3/build/storefront/images/spinner.gif';
var comet = new CometManager(comm);

var globalpageglobals = new PageGlobals();

/*
 * global controllers
 */

var globals = {
    setCUser: function (object) {
        globalpageglobals.set('cuser', object);
    },
    browser: {
        hasLocalStorage: function () {
            var test = 'tbg_localStorage_test';
            try {
                localStorage.setItem(test, test);
                localStorage.removeItem(test);
                return true;
            } catch (e) {
                return false;
            }
        }
    }
};

var searchController = {
    search: function (control) {
        var $container = $(control).parents('#productSearchContainer');
        var keyword = $container.find('.keywords input').val();
        var categoryId = $container.find('.categories select').val();
        var searchType = $container.find("input[name='SEARCHTYPE']").val();

        //
        var searchResultsURL = new JDURL(searchGlobals.searchResultsURL);
        searchResultsURL.appendParam('k', keyword);
        if (hasValue(categoryId) && categoryId > 0) {
            searchResultsURL.appendParam('c', categoryId);
        }
        if (hasValue(searchType)) {
            searchResultsURL.appendParam('s', searchType);
        }

        //
        gotoURL(searchResultsURL.getUrl());
    },
    checkEnter: function (event, control) {
        if (isEnterEvent(event)) {
            searchController.search(control);
        }
    }
};

var events = {
    USER_LOADED: 'userLoaded',
    userLoaded: function () {
    },
    USER_LOADED_LOGGED_IN: 'userLoadedLoggedIn',
    userLoadedLoggedIn: function () {
    },
    USER_LOADED_NOT_LOGGED_IN: 'userLoadedNotLoggedIn',
    userLoadedNotLoggedIn: function () {
    },
    USER_JUST_LOGGED_IN: 'userJustLoggedIn',
    userJustLoggedIn: function () {
    },
    SESSION_ERROR: 'sessionError',
    sessionError: function () {
    },
    FORM_EMAIL_SENT: 'formEmailSent',
    formEmailSent: function () {
    },
    FORM_EMAIL_ERROR: 'formEmailError',
    formEmailError: function () {
    },
    PRINT_FILE_UPLOADED: 'printFileUploaded',
    printFileUploaded: function () {
    },
    NEW_ACTIVITY_LOG_ENTRY: 'newActivityLogEntry',
    newActivityLogEntry: function () {
    },
    CART_ITEM_REMOVED: 'cartItemRemoved',
    cartItemRemoved: function () {
    },
    PROJECT_ITEMS_MOVED: 'projectItemsMoved',
    projectItemsMoved: function () {
    },
    USER_SHARING_CHANGED: 'userSharingChanged',
    userSharingChanged: function () {
    },
    PROJECTS_LOADED: 'projectsLoaded',
    projectsLoaded: function () {
    },
    PRICE_ADJUSTED: 'priceAdjusted',
    priceAdjusted: function () {
    },
    NOTIFICATION_COUNT_UPDATED: 'totalNotificationCountUpdated',
    totalNotificationCountUpdated: function () {
    },
    PENDING_REVIEW_LIST_UPDATED: 'pendingReviewListUpdated',
    pendingReviewListUpdated: function () {
    },
    NEEDS_PRINT_FILES_LIST_UPDATED: 'needsPrintFilesListUpdated',
    needsPrintFilesListUpdated: function () {
    },
    REQUIRES_ACTION_LIST_UPDATED: 'requiresActionListUpdated',
    requiresActionListUpdated: function () {
    },
    ORDER_LIST_UPDATED: 'orderListUpdated',
    orderListUpdated: function () {
    },

    //
    trigger: function (eventName, argArray) {
        if (this.isEventRegistered(eventName)) {
            var eventFunction = this[eventName];

            // eventFunction.apply(null, argArray);// would be nice, but args are not passed by jquery.connect wiring
            eventFunction();
        }
    },
    listen: function (eventName, functionOrObject, optionalFunctionName) {
        if (optionalFunctionName == null) {
            $.connect(this, eventName, functionOrObject);
        } else {
            $.connect(this, eventName, functionOrObject, optionalFunctionName);
        }
    },
    unlisten: function (eventName, object, functionName) {
        $.disconnect(this, eventName, object, functionName);
    },
    registerCustomEvent: function (eventName) {
        var success = false;
        if (hasValue(eventName)) {
            if (!this.isEventRegistered(eventName)) {
                this[eventName] = new Function();
                success = true;
            }
        }
        return success;
    },
    isEventRegistered: function (eventName) {
        return this[eventName] != null;
    }
};

var userNotificationStorage = {
    clearAllCached: function () {
        this.clearCachedPendingReview();
        this.clearCachedRequiresAction();
        this.clearCachedNeedsPrintFiles();
    },
    clearCachedPendingReview: function () {
        if (globals.browser.hasLocalStorage()) {
            localStorage.removeItem('userNotifications_pendingReview');
        }
    },
    clearCachedRequiresAction: function () {
        if (globals.browser.hasLocalStorage()) {
            localStorage.removeItem('userNotifications_requiresAction');
        }
    },
    clearCachedNeedsPrintFiles: function () {
        if (globals.browser.hasLocalStorage()) {
            localStorage.removeItem('userNotifications_needsPrintFiles');
        }
    }
};

var getCustomEmailFormSentEventName = function (emailFormName) {
    return emailFormName + '_customFormSentEvent';
};
var listenForCustomFormEmailSent = function (emailFormName, callbackFunction) {
    var customEmailFormSentEventName = getCustomEmailFormSentEventName(emailFormName);
    if (!events.isEventRegistered(customEmailFormSentEventName)) {
        events.registerCustomEvent(customEmailFormSentEventName);
    }
    if (events.isEventRegistered(customEmailFormSentEventName) && callbackFunction != null) {
        events.listen(customEmailFormSentEventName, callbackFunction);
    }
};

/*
Braintree hosted input text style defaults

See: https://developers.braintreepayments.com/reference/client-reference/javascript/v2/hosted-fields#internal-styling-properties
Allowed properties are:
    color, font, font-family, font-size, font-size-adjust, font-stretch, font-style, font-variant, font-variant-alternates,
    font-variant-caps, font-variant-east-asian, font-variant-ligatures, font-variant-numeric, font-weight, line-height,
    outline, opacity, text-shadow, transition, -moz-osx-font-smoothing, -moz-transition, -webkit-font-smoothing,
    -webkit-transition, -moz-tap-highlight-color, -webkit-tap-highlight-color
*/
window.inputStyles = {
    input: {
        'font-family': 'Helvetica Neue, arial, sans-serif',
        'font-size': '11px',
        color: '#666',
        'font-weight': 'normal',
        transition: 'color 1s linear'
    },
    ':focus': {
        color: '#000'
    }
};

/*
 * Initialization
 */
$(function () {
    // init page
    var cometRequest = comet.newRequest(STORECOMETLINK, 'INIT', function (cometRequest) {
        if (cometRequest.sessionTracking) {
            try {
                // when in a frame cometMessages are not shown for now
                growlMessages.showAll(cometRequest);
            } catch (err) {
            }
            globals.setCUser(cometRequest.getObject('USER'));
            globalpageglobals.set('cstorefrontsettings', cometRequest.getObject('STOREFRONTSETTINGS'));
            globalpageglobals.set('cshippingsettings', cometRequest.getObject('SHIPPINGSETTINGS'));

            // one time firing of user load events.
            events.trigger(events.USER_LOADED);
            if (globalpageglobals.cuser == null) {
                events.trigger(events.USER_LOADED_NOT_LOGGED_IN);
            } else {
                events.trigger(events.USER_LOADED_LOGGED_IN);
            }
        } else {
            events.trigger(events.SESSION_ERROR);
        }
    });
    cometRequest.send();

    $('.datepicker').datepicker({ showOn: 'focus' });
});

