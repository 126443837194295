function Communications (sourcePageHandler) {
    this.sourcePageHandler = sourcePageHandler;
    this.createUrl = Communications_createUrl;
}
function Communications_createUrl (serverUrl, handlerAction, commType) {
    var commUrl = new JDURL(serverUrl);
    commUrl.appendParam(SOURCEPAGEHF, this.sourcePageHandler);
    commUrl.appendParam(PAGEACTIONHF, handlerAction);
    commUrl.appendParam(REQUESTTYPE, commType);
    return commUrl;
}
