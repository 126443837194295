(function () {
    'use strict';

    angular
        .module('tbg.errorReporting')
        .config(config)
        .factory('httpErrorInterceptor', httpErrorInterceptor);

    config.$inject = ['$httpProvider'];

    function config ($httpProvider) {
        $httpProvider.interceptors.push('httpErrorInterceptor');
    }

    httpErrorInterceptor.$inject = ['$q', '$exceptionHandler', 'tbgErrorService'];

    function httpErrorInterceptor ($q, $exceptionHandler, tbgErrorService) {
        return {
            responseError: function responseErrorFn (rejection) {
                // An undefined status indicates the request was cancelled, do not treat this as an error.
                // A status of -1 usually means call timed out or was otherwise aborted by client, don't report.
                if (!rejection.status || rejection.status === -1) {
                    return rejection;
                }

                // requestConfig.resolves, takes an array of resolve objects:
                // {
                //     status: 0, // the error status to match
                //     data: ANY, // (optional) what to resolve with when match is made
                // }
                // When a resolve object's status matches an error response status, error reporting is supressed.
                // Additionally, if a matching object contains a `data` property, the request is resolved with the value specified.
                if (rejection.config.resolves && rejection.config.resolves.length) {
                    var match = rejection.config.resolves.filter(function (resolve) { // TODO: replace `filter` with `find` once IE11 dropped
                        return resolve.status === rejection.status;
                    })[0]; // TODO: remove `[0]` once IE11 dropped

                    if (match) {
                        rejection.data = match.hasOwnProperty('data') ? match.data : rejection.data;
                        return $q.resolve(rejection);
                    }
                }

                // Generate error and hand it off to $exceptionHandler for reporting
                tbgErrorService
                    .newHttpError(rejection)
                    .then($exceptionHandler);

                return $q.reject(rejection);
            }
        };
    }
}());
