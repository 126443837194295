(function () {
    'use strict';

    angular.module('collaterate.common.plupload', []).provider('PluploadSettings', PluploadSettingsProvider);

    function PluploadSettingsProvider () {
        var settings = {
            url: '',
            silverlight_xap_url: '/web3/build/base/js/plupload/js/plupload.silverlight.xap',

            // browse_button: '', // Set explicitly by our directive
            // drop_element: '',  // Set explicitly by our directive
            // container: '',     // Set explicitly by our directive
            filters: {
                mime_types: [/* { title: 'Image Files', extensions: 'jpg,gif,png' }, { title: 'Zip Files', extensions: 'zip' } */],
                max_file_size: 0,
                prevent_duplicates: false
            },
            headers: {},
            multipart: true,
            multipart_params: {},
            max_retries: 0,
            chunk_size: 0,

            // Resize options are disabled by default
            // resize: {
            //     width: '',
            //     height: '',
            //     crop: false,
            //     quality: 90,
            //     preserve_headers: true
            // },
            multi_selection: true,

            // required_features: '' // See http://www.plupload.com/docs/Required-Features
            unique_names: false,
            runtimes: 'html5,silverlight,html4',
            file_data_name: 'file'
        };

        return {
            options: config,
            $get: PluploadSettings
        };

        function PluploadSettings () {
            return settings;
        }

        function config (configOpts) {
            plupload.extend(settings, configOpts);
        }
    }
}());
