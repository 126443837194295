(function () {
    'use strict';

    /*
    Directive currently supports a single set of controls per paginated list,
    TODO: update pagination-local to support multiple instances

    Do not use "ng-if" to show/hide this directive - this will result in the
    directive being removed before it can update the paginated list. Use
    "ng-show/hide" instead.
*/
    angular
        .module('collaterate.common')
        .directive('paginationLocal', paginationLocal);

    paginationLocal.$inject = [];

    function paginationLocal () {
        return {
            restrict: 'AE',
            templateUrl: '/templates/pagination-local.template.html',
            scope: {
                masterList: '=',
                paginatedList: '=',
                defaultPageSize: '@?',
                showMenu: '=?',
                showPageSizeSelector: '=?',
                onChanged: '&?'
            },
            controller: PaginationLocalController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    PaginationLocalController.$inject = ['$scope', '$timeout', '$attrs'];

    function PaginationLocalController ($scope, $timeout, $attrs) {
        var vm = this;

        vm.pageSizeOptions = [
            { name: '5 per page', value: 5 },
            { name: '25 per page', value: 25 },
            { name: '50 per page', value: 50 },
            { name: '100 per page', value: 100 },
            { name: '500 per page', value: 500 },
            { name: 'Show All', value: 0 }
        ];

        vm.showMenu = $attrs.hasOwnProperty('showMenu') ? !!vm.showMenu : true;
        vm.showPageSizeSelector = $attrs.hasOwnProperty('showPageSizeSelector') ? !!vm.showPageSizeSelector : true;
        vm.currentPage = 0;
        vm.totalPages = 0;
        vm.itemsPerPage = getInitialItemsPerPage();
        vm.currentRangeStart = 0;
        vm.currentRangeEnd = 0;
        vm.pageRange = [];
        vm.goToPage = goToPage;
        vm.reset = reset;

        init();

        return;

        function init () {
            $scope.$watchCollection('vm.masterList', runPagination);
            runPagination();
        }

        function goToPage (index) {
            vm.currentPage = index;
            updatePaginatedList();
        }

        function reset () {
            vm.currentPage = 0;
            runPagination();
        }

        function runPagination () {
            calculateTotalPages();
            updatePaginatedList();
        }

        function calculateTotalPages () {
            var numPages = Math.ceil(vm.masterList.length / vm.itemsPerPage);

            // We are setting "show all results" to an itemsPerPage value of 0,
            // which results in numPages equalling Infinity. In this case, we
            // manually set the totalPages to 1.
            vm.totalPages = numPages === Infinity ? 1 : numPages;

            // Updates the page selector menu
            vm.pageRange = generatePageRange();

            // If an update to the master list leaves us on a page greater than
            // the new number of pages, take us to the last page of results.
            if (vm.currentPage + 1 > vm.totalPages) {
                vm.currentPage = vm.totalPages > 0 ? vm.totalPages - 1 : 0;
            }
        }

        function updatePaginatedList () {
            var currentIndex = vm.currentPage * vm.itemsPerPage;
            var itemsPerPage = vm.itemsPerPage || vm.masterList.length;

            vm.paginatedList = vm.masterList.slice(currentIndex, currentIndex + itemsPerPage);
            vm.currentRangeStart = vm.masterList.length ? currentIndex + 1 : 0;
            vm.currentRangeEnd = currentIndex + itemsPerPage >= vm.masterList.length ? vm.masterList.length : currentIndex + itemsPerPage;

            if (vm.onChanged) {
                vm.onChanged();
            }
        }

        // Builds convenience object for constructing page selector list
        function generatePageRange () {
            var range = [];

            for (var i = 0; i < vm.totalPages; i++) {
                range.push({ index: i });
            }

            return range;
        }

        function getInitialItemsPerPage () {
            if ($attrs.hasOwnProperty('defaultPageSize')) {
                for (var i = 0; i < vm.pageSizeOptions.length; i++) {
                    if (vm.pageSizeOptions[i].value === parseInt(vm.defaultPageSize, 10)) {
                        return vm.pageSizeOptions[i].value;
                    }
                }
            }

            return vm.pageSizeOptions[0].value;
        }
    }
}());
