/*
 *  sample usages:
 *    - <a href="http://localhost/myurl" onclick="linkTracker.linkClicked(this.href)">my link</a>
 *    - tags.a('my link','http://localhost/myurl',{onclick:linkTracker.getOnClickAttribute()});
 *    - linkTracker.gotoURL('http://localhost/myurl')
 *
 */
var linkTracker = {
    name: 'linkTracker',
    hasLinkHandlers: false,
    linkHandlers: new Array(),
    url: null,
    getOnClickAttribute: function () {
        var content = '';
        content += 'return ' + this.name + '.linkClicked(this.href)';
        return content;
    },
    linkClicked: function (url) {
        // 2017-04-27: domain switch removed from collaterate.  linkTracker.handleLinkClick() is obsolete and will no longer be called.
        //        if (this.hasLinkHandlers && this.isDomainSwitchURL(url)) {
        //            // domain switch requested. call tracking handler link functions.
        //            this.url = url;
        //            this.handleLinkClick(url);
        //            return false;
        //        }
        return true;
    },
    isDomainSwitchURL: function (url) {
        if (hasValue(url)) {
            var isDomainSwitchURL = url.indexOf('domainSwitch') > -1;
            if (isDomainSwitchURL) {
                return true;
            }
        }
        return false;
    },
    handleLinkClick: function () {
        // placeholder function:
        // use the registerLinkHandler() method to attach tracking handlers to
        // this method.
        // e.g. linkTracker.registerLinkHandler(myHandler, 'handleLinkClick')
    },
    registerLinkHandler: function (handlerObject, functionName) {
        this.hasLinkHandlers = true;
        this.linkHandlers[this.linkHandlers.length] = handlerObject;
        $.connect(this, 'handleLinkClick', handlerObject, functionName);
    },
    gotoURL: function (url) {
        // 2017-04-27: domain switch removed from collaterate.  linkTracker.gotoURL() is now deprecated, as it is simply a call-through to global gotoURL() now.
        //        if (this.hasLinkHandlers && this.isDomainSwitchURL(url)) {
        //            // for now we will only support using one single analytics system at a time
        //            var linkHandler = this.linkHandlers[0];
        //            linkHandler.gotoURL(url);
        //        } else {

        // call base function
        gotoURL(url);

        //        }
    }
};
