(function () {
    angular.module('collaterate.common.directives.currency', []).directive('collaterateCurrency', function () {
        return {
            restrict: 'AE',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModel) {
                // What gets pushed to the model, should return float formatted as string
                ngModel.$parsers.push(function (value) {
                    var prefix = false;
                    var numStr = value;

                    // check for negative indicator, remove and store for later if found
                    if (numStr.charAt(0) === '-') {
                        prefix = true;
                        numStr = numStr.substring(1);
                    }

                    // only allow digits / decimals
                    numStr = numStr.replace(/[^0-9.]/g, '');

                    // strip additional decimals if more than one is found
                    if (numStr.split('.').length > 2) {
                        // protect against getting "NaN" as result
                        numStr = Number.parseFloat(numStr, 10) ? Number.parseFloat(numStr, 10).toString() : '0';
                    }

                    // limit decimal to two places
                    if (numStr.indexOf('.') > -1 && numStr.length - numStr.indexOf('.') > 3) {
                        numStr = parseFloat(numStr).toFixed(2);
                    }

                    // add zero to beginning if leading with decimal
                    if (numStr.indexOf('.') === 0) {
                        numStr = '0' + numStr;
                    }

                    // add prefix back onto beginning of string if it existed
                    if (prefix) {
                        numStr = '-' + numStr;
                    }

                    // Only need to call render if things are different
                    if (numStr !== value.toString()) {
                        ngModel.$setViewValue(numStr);
                        ngModel.$render();
                    }

                    return numStr;
                });

                // What gets pushed to the view
                ngModel.$formatters.push(function (value) {
                    return Number.parseFloat(value, 10).toFixed(2);
                });
            }
        };
    });
}());
