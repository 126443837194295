cometBusinessObjectBehaviour.add('TBGBOSiteOfferingMetadata', {
    hasAdminDisplayOption: function (id) {
        var adminDisplayOptionsObject = this.adminDisplayOptions;
        if (adminDisplayOptionsObject == null) {
            return false;
        }
        var adminDisplayOptions = adminDisplayOptionsObject.list;
        if (adminDisplayOptions == null) {
            return false;
        }
        for (var i = 0; i < adminDisplayOptions.length; i++) {
            adminDisplayOption = adminDisplayOptions[i];
            if (adminDisplayOption.id == id) {
                return true;
            }
        }
        return false;
    }
});
