(function () {
    'use strict';

    angular
        .module('tbg.errorReporting')
        .config(config);

    config.$inject = ['$provide'];

    function config ($provide) {
        $provide.decorator('$exceptionHandler', exceptionHandlerDecoratorFn);
    }

    exceptionHandlerDecoratorFn.$inject = ['$delegate', 'errorTypes', 'errorLogger', 'tbgErrorService'];

    function exceptionHandlerDecoratorFn ($delegate, errorTypes, errorLogger, tbgErrorService) {
        return function (exception, cause) {
            switch (exception.type) {
                case errorTypes.HTTP:
                case errorTypes.WINDOW:
                    logError(exception);
                    break;
                case errorTypes.STANDARD:
                default:
                    tbgErrorService
                        .newError(exception, cause)
                        .then(logError);
                    break;
            }

            return;

            function logError (error) {
                // Create log for browser console...
                var log = [error.message];
                for (var i = 0, funcName, fileName, origin = window.location.origin; i < error.mappedStack.length; i++) {
                    funcName = error.mappedStack[i].functionName === 'null' ? '(anonymous function)' : error.mappedStack[i].functionName;
                    fileName = error.mappedStack[i].fileName;
                    if (fileName != null && fileName.startsWith(origin)) {
                        fileName = fileName.substr(origin.length);
                    }
                    log.push('\t at ' + funcName + ' (' + origin + fileName + ':' + error.mappedStack[i].lineNumber + ':' + error.mappedStack[i].columnNumber + ')');
                }
                console.error(log.join('\n'));

                // Log the error to the server...
                errorLogger.log(error);
                return error;
            }
        };
    }
}());
