(function () {
    'use strict';

    angular
        .module('collaterate.interceptors')
        .factory('collaterateInterceptors', collaterateInterceptors)
        .config(collaterateInterceptorsConfig);

    collaterateInterceptors.$inject = ['$q', 'alertPanelService'];

    function collaterateInterceptors ($q, alertPanelService) {
        return {
            responseError: function (response) {
                var ret = response;

                switch (response.status) {
                    case 401:
                        alertPanelService.alert('Oops. Your session has expired. Please reload the page and try again.');

                        // Uncomment below if we decide to pass the error response along
                        // ret = $q.reject(response);
                        break;
                    case 417:
                        ret = $q.reject(response);
                        break;
                    default:
                        ret = $q.reject(response);
                        break;
                }

                return ret;
            }
        };
    }

    collaterateInterceptorsConfig.$inject = ['$httpProvider'];

    function collaterateInterceptorsConfig ($httpProvider) {
        $httpProvider.interceptors.push('collaterateInterceptors');
    }
}());
