(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('S3BulkOrderUploadPluploadSettings', getS3BulkOrderUploadPluploadSettingsConstructor);

    getS3BulkOrderUploadPluploadSettingsConstructor.$inject = ['AbstractS3PluploadSettings', 'fileUploadService'];

    function getS3BulkOrderUploadPluploadSettingsConstructor (AbstractS3PluploadSettings, fileUploadService) {
        var Superclass = AbstractS3PluploadSettings;
        S3BulkOrderUploadPluploadSettings.prototype = new Superclass();
        S3BulkOrderUploadPluploadSettings.prototype.constructor = S3BulkOrderUploadPluploadSettings;

        function S3BulkOrderUploadPluploadSettings () {
            Superclass.call(this, fileUploadService.prepareBulkOrderUpload);

            this.filters = {
                mime_types: [{ title: 'CSV Files', extensions: 'csv,CSV' }]
            };
        }

        return S3BulkOrderUploadPluploadSettings;
    }
}());
