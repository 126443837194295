(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('AbstractS3PluploadSettings', getAbstractS3PluploadSettingsConstructor);

    getAbstractS3PluploadSettingsConstructor.$inject = ['$window'];

    function getAbstractS3PluploadSettingsConstructor ($window) {
        var plupload = $window.plupload;
        var metaPrefix = 'x-amz-meta-';

        function AbstractS3PluploadSettings (reservationMethod) {
            this.runtimes = 'html5';
            this.multipart = true;
            this.max_file_size = '5gb';
            this.chunk_size = 0;

            this.init = {
                BeforeUpload: function (up, file) {
                    var msg = {
                        displayName: file.name,
                        submissionProfile: 'S3_UPLOAD_' + up.runtime.toUpperCase()
                    };

                    reservationMethod(msg)
                        .then(configureUploadSettings)
                        .then(beginUpload)
                        .catch(displayError);

                    return false;

                    function configureUploadSettings (response) {
                        up.settings.url = response.s3PostConfig.bucketUrl;
                        file.name = response.s3PostConfig.fileName;
                        up.settings.multipart_params = {
                            key: response.s3PostConfig.key,
                            Filename: response.s3PostConfig.displayName,
                            acl: response.s3PostConfig.accessControlList,
                            success_action_status: '201',
                            AWSAccessKeyId: response.s3PostConfig.awsAccessKeyId,
                            policy: response.s3PostConfig.policy,
                            'x-amz-algorithm': response.s3PostConfig.amzAlgorithm,
                            'x-amz-credential': response.s3PostConfig.amzCredential,
                            'x-amz-date': response.s3PostConfig.amzDate,
                            'x-amz-signature': response.s3PostConfig.v4Signature,
                            signature: response.s3PostConfig.v2Signature
                        };

                        if (response.meta) {
                            for (var prop in response.meta) {
                                if (response.meta.hasOwnProperty(prop)) {
                                    up.settings.multipart_params[metaPrefix + prop] = response.meta[prop];
                                }
                            }
                        }
                    }

                    function beginUpload () {
                        file.status = plupload.UPLOADING;
                        up.trigger('UploadFile', file);
                    }

                    function displayError (error) {
                        up.trigger('Error', {
                            code: error.status,
                            message: 'Connection failed [' + error.status + ']. Reload page.',
                            file: file
                        });

                        up.removeFile(file);
                    }
                }
            };
        }

        return AbstractS3PluploadSettings;
    }
}());
