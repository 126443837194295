function lookupFormItem (name, options) {
    if (options && options.customParent) {
        return getFormItemOfParent(name, options.parent);
    }
    return getFormItem(name);
}

function itemIsChecked (formItemObject) {
    if (formItemObject == null || formItemObject.checked == null) {
        return false;
    }
    return formItemObject.checked;
}
function itemHasValue (formItemObject) {
    if (formItemObject == null || formItemObject.value == null || stringTrim(formItemObject.value) == '') {
        return false;
    }
    return true;
}
function isNumber (aString) {
    var value = aString.replace(',', '');
    return !isNaN(value);
}
function isValidCreditCardNumber (cardNumber, cardType) {
    var isValid = false;
    var ccCheckRegExp = /[^\d ]/;
    isValid = !ccCheckRegExp.test(cardNumber);
    var cardNumbersOnly = null;
    var cardNumberLength = 0;

    if (isValid) {
        cardNumbersOnly = cardNumber.replace(/ /g, '');
        cardNumberLength = cardNumbersOnly.length;
        var lengthIsValid = false;
        var prefixIsValid = false;
        var prefixRegExp;

        // NOTE: these need to match the name values in ORDER_CC_TYPES
        switch (cardType) {
            case 'Visa':
                lengthIsValid = cardNumberLength == 16 || cardNumberLength == 13;
                prefixRegExp = /^4/;
                break;

            case 'Mastercard':
                lengthIsValid = cardNumberLength == 16;
                prefixRegExp = /^5[1-5]/;
                break;

            case 'American Express':
                lengthIsValid = cardNumberLength == 15;
                prefixRegExp = /^3(4|7)/;
                break;

            case 'Discover':
                lengthIsValid = cardNumberLength == 16;
                prefixRegExp = /^6011/;
                break;

            default:
                prefixRegExp = /^$/;
                return false;
        }// switch

        prefixIsValid = prefixRegExp.test(cardNumbersOnly);
        isValid = prefixIsValid && lengthIsValid;
    }
    if (isValid) {
        var numberProduct;
        var checkSumTotal = 0;

        for (var digitCounter = cardNumberLength - 1; digitCounter >= 0; digitCounter--) {
            checkSumTotal += parseInt(cardNumbersOnly.charAt(digitCounter));
            digitCounter--;
            numberProduct = String(cardNumbersOnly.charAt(digitCounter) * 2);
            for (var productDigitCounter = 0; productDigitCounter < numberProduct.length; productDigitCounter++) {
                checkSumTotal += parseInt(numberProduct.charAt(productDigitCounter));
            }
        }

        isValid = checkSumTotal % 10 == 0;
    }

    return isValid;
}

/*
 *
 * Object that manages validation. on page submit call isValid() to see if all
 * validation tests passed. object contains an array of "ValidateItem" objects.
 * It asks each item to validate and accumulate error messages from ones that
 * fail. Then it prompts the user if needed and sets focus to the first problem
 * item
 */
function Validator () {
    // object properties
    this.validateItems = new Array();

    // object functions
    this.isValid = Validator_isValid;
    this.addItem = Validator_addItem;
    this.resetValidated = function () {
        var items = this.validateItems;
        for (var i = 0; i < items.length; i++) {
            var anItem = items[i];
            if (anItem.setValidated != null) {
                anItem.setValidated(false);
            }
        }
    };
    this.clearErrorHighlights = function () {
        var items = this.validateItems;
        for (var i = 0; i < items.length; i++) {
            var anItem = items[i];
            if (anItem.clearErrorHighlights) {
                anItem.clearErrorHighlights();
            }
        }
    };
}

function Validator_isValid () {
    var items = this.validateItems;
    var userMessages = '';
    var focusItem = null;
    var errorCount = 0;
    var notificationData = [];
    this.resetValidated();
    this.clearErrorHighlights();
    for (var i = 0; i < items.length; i++) {
        var anItem = items[i];
        if (!anItem.isValid()) {
            errorCount++;
            userMessages += '<li>' + anItem.reason + '</li>';

            // alertPanel.alert(userMessages);
            if (focusItem == null) {
                focusItem = anItem;
            }
            notificationData.push({
                label: anItem.description,
                message: anItem.reason,
                name: anItem.name
            });
        }
    }

    if (errorCount > 0) {
        if (window.tbg && window.tbg.notifications) {
            if (window.tbg.notifications.trigger(window.tbg.notifications.FORM_VALIDATION, { fields: notificationData })) {
                showErrors();
            }
            return false;
        }

        showErrors();
        return false;
    }

    return true;

    function showErrors () {
        var displayMsg = '<p>Please address the following:</p>';
        displayMsg += '<ol>' + userMessages + '</ol>';
        alertPanel.alert(displayMsg, function () {
            if (focusItem.isRadioType && focusItem.isRadioType()) {
                document.location.hash = focusItem.anchorName;
            } else {
                if (focusItem.name != null) {
                    focusOn(focusItem.getName());
                }
            }
        });
    }
}
function Validator_addItem (aValidateItem) {
    this.validateItems[this.validateItems.length] = aValidateItem;
}

/*
 * Object that contains information on how to validate form items. Use functions
 * above to create a new object with the appropriate values set.
 *
 * param aName String - name of the form item to be validated param aDescription
 * String - description of what the item is that the user will understand param
 * isRequired boolean - true if this field needs to have a value, false if it is
 * optional this can be usefull if you only want to validate that the user
 * entered a valid number if they enter anything param validateType String -
 * optional value to indicate what type of validation to perform variable
 * conditionalType - optional int to indicate if item should be conditionally
 * validated. see below variable extraParam Object - optional value of any type.
 * required if the type of validation requires it variable anchorName String -
 * name of the anchor to put focus on if radio button validation fails.
 *
 * typeId ValidateItem.BASICVALUE - validate item has a value (Default)
 * ValidateItem.NUMBER - validate value is a number ValidateItem.EMAIL -
 * validate value is a valid email address ValidateItem.DATE - validate value is
 * a date ValidateItem.PHONE - validate value is a phone number
 * ValidateItem.EMPTY - validate value is empty or null
 * ValidateItem.PASSWORDMATCH - validate value and extra match (password)
 * ValidateItem.VALUEOREXTRA - validate value OR extra item has a value
 * ValidateItem.RADIO - validate radio buttons ValidateItem.CHECKBOXES -
 * validate at least one check box is checked in group of names
 *
 * conditionalType ValidateItem.CONDITIONS.OFF -1 - off
 * ValidateItem.CONDITIONS.EXTRA_IS_CHECKED 0 - if field with name in "extra" is
 * checked, validate ValidateItem.CONDITIONS.EXTRA_HAS_VALUE 1 - if field with
 * name in "extra" has value, validate ValidateItem.CONDITIONS.EXTRA_IS_EXTRA2 2 -
 * if field with name in "extra" has value same as "extra2", validate
 */
function ValidateItem (aName, aDescription, isRequired, validateType, options) {
    var defaults = {
        customParent: false,
        parent: null
    };

    this.options = $.extend({}, defaults, options);

    // object properties
    this.name = aName;
    this.description = aDescription;
    this.failureDescription = null;
    this.required = isRequired == null ? true : isRequired;
    this.type = validateType == null ? ValidateItem.BASICVALUE : validateType;
    this.conditionalType = ValidateItem.CONDITIONS.OFF;
    this.extra = null;
    this.extra2 = null;
    this.extra3 = null;
    this.anchorName = null;
    this.reason = '';
    this.validated = false;

    // private instance properties
    this._valid = undefined;

    // public instance methods (required by validator)
    this.isValidated = function () {
        return this.validated;
    };
    this.isValid = ValidateItem_isValid;
    this.getReason = ValidateItem_getReason;
    this.isRadioType = ValidateItem_isRadioType;
    this.getName = ValidateItem_getName;

    // private instance methods
    this.setValidated = function (validated) {
        this.validated = validated;
    };
    this.isValidValue = ValidateItem_isValidValue;
    this.isNotSpecificValue = ValidateItem_isNotSpecificValue;
    this.isValidEmptyValue = ValidateItem_isValidEmptyValue;
    this.isValidNumber = ValidateItem_isValidNumber;
    this.isValidNonNegativeNumber = ValidateItem_isValidNonNegativeNumber;
    this.isValidPositiveNumber = ValidateItem_isValidPositiveNumber;
    this.isValidInteger = ValidateItem_isValidInteger;
    this.isValidPositiveInteger = ValidateItem_isValidPositiveInteger;
    this.isValidNonNegativeInteger = ValidateItem_isValidNonNegativeInteger;
    this.isValidIntegerRange = ValidateItem_isValidIntegerRange;
    this.isValidRadioButtons = ValidateItem_isValidRadioButtons;
    this.isValidEmail = ValidateItem_isValidEmail;
    this.isValidDate = ValidateItem_isValidDate;
    this.isValidPhone = ValidateItem_isValidPhone;
    this.isValidCreditCard = ValidateItem_isValidCreditCard;
    this.isValidPassword = ValidateItem_isValidPassword;
    this.isValidCheckBoxes = ValidateItem_isValidCheckBoxes;
    this.isValidOR = ValidateItem_isValidOR;
    this.isValidWidget = ValidateItem_isValidWidget;
    this.isValidPositiveMoney = ValidateItem_isValidPositiveMoney;
    this.isValidMoney = ValidateItem_isValidMoney;
    this.isValidMinMax = ValidateItem_isValidMinMax;

    this.highlightErrors = function () {
        if (this.onErrorHighlightOptions) {
            var onErrorHighlightOptions = this.onErrorHighlightOptions;
            if (hasValue(onErrorHighlightOptions.elementId) && hasValue(onErrorHighlightOptions.cssClass)) {
                dynamic.addClass(onErrorHighlightOptions.elementId, onErrorHighlightOptions.cssClass);
            }
        }
    };
    this.clearErrorHighlights = function () {
        if (this.onErrorHighlightOptions) {
            var onErrorHighlightOptions = this.onErrorHighlightOptions;
            if (hasValue(onErrorHighlightOptions.elementId) && hasValue(onErrorHighlightOptions.cssClass)) {
                dynamic.removeClass(onErrorHighlightOptions.elementId, onErrorHighlightOptions.cssClass);
            }
        }
    };
}

// validation type static constants
ValidateItem.BASICVALUE = 'BasicValue';
ValidateItem.NOTSPECIFICVALUE = 'NotSpecificValue';
ValidateItem.NUMBER = 'Number';
ValidateItem.NONNEGATIVENUMBER = 'NonNegativeNumber';
ValidateItem.POSITIVENUMBER = 'PositiveNumber';
ValidateItem.POSITIVEMONEY = 'POSITIVEMONEY';
ValidateItem.MONEY = 'MONEY';
ValidateItem.INTEGER = 'Integer';
ValidateItem.POSITIVEINTEGER = 'PositiveInteger';
ValidateItem.NONNEGATIVEINTEGER = 'NonNegativeInteger';
ValidateItem.INTEGERRANGE = 'IntegerRange';
ValidateItem.EMAIL = 'Email';
ValidateItem.DATE = 'Date';
ValidateItem.PHONE = 'Phone';
ValidateItem.CREDITCARD = 'CreditCard';
ValidateItem.EMPTY = 'EmptyNull';
ValidateItem.PASSWORDMATCH = 'PasswordMatch';
ValidateItem.VALUEOREXTRA = 'ValueOrExtra';
ValidateItem.RADIO = 'RadioButtons';
ValidateItem.CHECKBOXES = 'Checkboxes';
ValidateItem.WIDGET = 'Widget';
ValidateItem.MINMAXMATCH = 'MinMaxMatch';

// conditional types static constants
function ValidateItemConditionTypes () {
    // holder for static constants
}
ValidateItemConditionTypes.OFF = 'Off';
ValidateItemConditionTypes.EXTRA_IS_FUNCTION = 'Any Function';
ValidateItemConditionTypes.EXTRA_IS_CHECKED = 'Checked Checkbox';
ValidateItemConditionTypes.EXTRA_IS_NOT_CHECKED = 'Checked Not Checkbox';
ValidateItemConditionTypes.EXTRA_HAS_VALUE = 'Extra Has Value';
ValidateItemConditionTypes.EXTRA_IS_EXTRA2 = 'Extra is Extra2 Value';
ValidateItemConditionTypes.EXTRARADIO_IS_EXTRA2 = 'Extra Radio Button is Extra2 Value';
ValidateItemConditionTypes.EXTRA_IS_PREREQ_FUNCTION = 'Prerequisite Function with Failure Description';// this
// item
// will
// not
// be
// validated
// if
// the
// prerequisite
// function
// returns
// false
ValidateItem.CONDITIONS = ValidateItemConditionTypes;

// instance methods
function ValidateItem_getName () {
    return this.name;
}
function ValidateItem_getReason () {
    return this.reason;
}
function ValidateItem_isValid () {
    // first check to see whether we have already validated this item
    if (this.isValidated()) {
        return this._valid;
    }
    this.setValidated(true);

    // prepare to validate
    if (this.conditionalType != ValidateItem.CONDITIONS.OFF) {
        if (this.conditionalType == ValidateItem.CONDITIONS.EXTRA_IS_FUNCTION) {
            this.required = this.extra() == true;
        } else if (this.conditionalType == ValidateItem.CONDITIONS.EXTRA_IS_CHECKED) {
            if (itemIsChecked(lookupFormItem(this.extra, this.options))) {
                this.required = true;
            } else {
                this.required = false;
            }
        } else if (this.conditionalType == ValidateItem.CONDITIONS.EXTRA_IS_NOT_CHECKED) {
            if (itemIsChecked(lookupFormItem(this.extra, this.options))) {
                this.required = false;
            } else {
                this.required = true;
            }
        } else if (this.conditionalType == ValidateItem.CONDITIONS.EXTRA_HAS_VALUE) {
            if (itemHasValue(lookupFormItem(this.extra, this.options))) {
                this.required = true;
            } else {
                this.required = false;
            }
        } else if (this.conditionalType == ValidateItem.CONDITIONS.EXTRA_IS_EXTRA2) {
            if (lookupFormItem(this.extra, this.options).value == this.extra2) {
                this.required = true;
            } else {
                this.required = false;
            }
        } else if (this.conditionalType == ValidateItem.CONDITIONS.EXTRARADIO_IS_EXTRA2) {
            if (getSelectedRadioValue(lookupFormItem(this.extra, this.options)) == this.extra2) {
                this.required = true;
            } else {
                this.required = false;
            }
        } else if (this.conditionalType == ValidateItem.CONDITIONS.EXTRA_IS_PREREQ_FUNCTION) {
            if (this.extra()) {
                this.required = true;
            } else {
                this.required = false;
                this.reason = this.failureDescription;
                return this._valid = false;
            }
        }
    }

    // perform validation
    this.reason = '';
    switch (this.type) {
        case ValidateItem.BASICVALUE:
            this._valid = this.isValidValue();
            break;
        case ValidateItem.NOTSPECIFICVALUE:
            this._valid = this.isNotSpecificValue();
            break;
        case ValidateItem.NUMBER:
            this._valid = this.isValidNumber();
            break;
        case ValidateItem.NONNEGATIVENUMBER:
            this._valid = this.isValidNonNegativeNumber();
            break;
        case ValidateItem.POSITIVENUMBER:
            this._valid = this.isValidPositiveNumber();
            break;
        case ValidateItem.INTEGER:
            this._valid = this.isValidInteger();
            break;
        case ValidateItem.POSITIVEINTEGER:
            this._valid = this.isValidPositiveInteger();
            break;
        case ValidateItem.NONNEGATIVEINTEGER:
            this._valid = this.isValidNonNegativeInteger();
            break;
        case ValidateItem.POSITIVEMONEY:
            this._valid = this.isValidPositiveMoney();
            break;
        case ValidateItem.MONEY:
            this._valid = this.isValidMoney();
            break;
        case ValidateItem.INTEGERRANGE:
            this._valid = this.isValidIntegerRange();
            break;
        case ValidateItem.EMAIL:
            this._valid = this.isValidEmail();
            break;
        case ValidateItem.DATE:
            this._valid = this.isValidDate();
            break;
        case ValidateItem.PHONE:
            this._valid = this.isValidPhone();
            break;
        case ValidateItem.CREDITCARD:
            this._valid = this.isValidCreditCard();
            break;
        case ValidateItem.EMPTY:
            this._valid = this.isValidEmptyValue();
            break;
        case ValidateItem.PASSWORDMATCH:
            this._valid = this.isValidPassword();
            break;
        case ValidateItem.VALUEOREXTRA:
            this._valid = this.isValidOR();
            break;
        case ValidateItem.RADIO:
            this._valid = this.isValidRadioButtons();
            break;
        case ValidateItem.CHECKBOXES:
            this._valid = this.isValidCheckBoxes();
            break;
        case ValidateItem.WIDGET:
            this._valid = this.isValidWidget();
            break;
        case ValidateItem.MINMAXMATCH:
            this._valid = this.isValidMinMax();
            break;
        default:
            this.reason = 'invalid validation type';
            this._valid = false;
    }

    // highlight errors
    this.highlightErrors();

    return this._valid;
}
function ValidateItem_isValidValue () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    var options = this.options;

    // this.required defaults to true, but is overridden by options.required
    // if it exists
    if ('required' in options) {
        this.required = options.required;
    }

    if (hasVal) {
        var val = formItem.value;
        var trimmedVal = stringTrim(val);
        if (options.maxLength && trimmedVal.length && trimmedVal.length > options.maxLength) {
            this.reason = '' + this.description + ' can be no longer than ' + options.maxLength + ' characters';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isNotSpecificValue () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        if (formItem.value == this.extra2) {
            this.reason = '' + this.failureDescription;
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidPassword () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var formItem2 = lookupFormItem(this.extra, this.options);
        var hasVal2 = itemHasValue(formItem2);
        if (hasVal2) {
            if (formItem.value == formItem2.value) {
                return true;
            }
            this.reason = 'Passwords do not match';
            return false;
        }
        this.reason = 'Verify Password is required';
        return false;
    }
    if (this.required) {
        this.reason = 'Password is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidOR () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    var formItem2 = lookupFormItem(this.extra, this.options);
    var hasVal2 = itemHasValue(formItem2);

    // alertPanel.alert("" + hasVal +" "+ formItem.value+ " " + formItem2.value
    // + " " +hasVal2);
    if (!hasVal && !hasVal2) {
        this.reason = '' + this.description;
        return false;
    }
    return true;
}
function ValidateItem_isValidEmptyValue () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);

    // assumes is required
    if (hasVal) {
        this.reason = '' + this.description;
        return false;
    }
    return true;
}
function ValidateItem_isValidNumber () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        if (isNumber(value)) {
            return true;
        }
        this.reason = '' + this.description + ' is not a number';
        return false;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidNonNegativeNumber () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        value = value.replace(',', '');
        if (isNaN(value) || value <= -1) {
            this.reason = '' + this.description + ' is not a number greater than -1';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidPositiveNumber () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        value = value.replace(',', '');
        if (isNaN(value) || value <= 0) {
            this.reason = '' + this.description + ' is not a number greater than zero';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidPositiveMoney () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        value = value.replace(',', '');
        if (isNaN(value) || value <= 0 || countDecimalPlaces(value) > 2) {
            this.reason = '' + this.description + ' is not a dollar and cents value greater than zero';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidMoney () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        value = value.replace(',', '');
        if (isNaN(value) || countDecimalPlaces(value) > 2) {
            this.reason = '' + this.description + ' is not a dollar and cents value';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidInteger () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        value = value.replace(',', '');
        if (isNaN(value) || value.indexOf('.') != -1) {
            this.reason = '' + this.description + ' is not an integer';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidPositiveInteger () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        value = value.replace(',', '');
        if (isNaN(value) || value.indexOf('.') != -1 || value < 1) {
            this.reason = '' + this.description + ' is not an integer greater than zero';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidNonNegativeInteger () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        value = value.replace(',', '');
        if (isNaN(value) || value.indexOf('.') != -1 || value < 0) {
            this.reason = '' + this.description + ' must be a whole number zero or greater';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidIntegerRange () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        var goodLowerRange = true;
        if (this.extra2 != null) {
            goodLowerRange = value >= this.extra2;
        }
        var goodUpperRange = true;
        if (this.extra3 != null) {
            goodUpperRange = value <= this.extra3;
        }
        if (isNaN(value) || value.indexOf('.') != -1 || !goodLowerRange || !goodUpperRange) {
            this.reason = '' + this.description + ' must be';
            if (this.extra2 != null && this.extra3 != null) {
                this.reason += ' between ' + this.extra2 + ' and ' + this.extra3;
            } else if (this.extra2 != null) {
                this.reason += ' greater than or equal to ' + this.extra2;
            } else {
                this.reason += ' less than or equal to ' + this.extra3;
            }
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidRadioButtons () {
    var formItem = lookupFormItem(this.name, this.options);
    var val = getSelectedRadioValue(formItem);
    if (val == null || val == '') {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidEmail () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;

        // first validate parsing char
        if (value.indexOf(';') > -1) {
            this.reason = '' + this.description + ' cannot contain semicolons.  Please use commas instead.';
            return false;
        }

        // remove newline chars from the tested string (not the original
        // string), so we can check for a comma-delimited, multi-line list as if
        // it were a single line
        value = value.replace(/\n/g, '');

        // multiple emails can be entered if separated by a comma. split on
        // comma, then validate each address separately so we can give a helpful
        // message about each
        var failReasons = new Array(0);
        var emailList = value.split(',');
        for (var i = 0, ni = emailList.length; i < ni; i++) {
            var email = emailList[i];

            if (!RegExp(/^(\s*\<?\s*([a-zA-Z0-9_\-\.\!\#\$\%\^\&\*\(\)\=\?\~\{\}\]\[\|\+\:\'\"\/]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,})\s*\>?\s*){1}$/).test(email)) {
                // [sec1]@[sec2].[sec3]
                // must be made up of 3 parts separated by "@" and "."
                // sec1 can contain alpha or numeric and special characters
                // \-\.\!\#\$\%\^\&\*\(\)\=\?\>\<\~\{\} (separated by a back
                // slash)
                // sec2 can contain alpha or numeric and "-" and "."
                // sec3 is alpha only and must have a minimum of 2 characters.
                failReasons.push('"' + email + '" is not a valid email address');
            }

            // matching angle-brackets
            var hasLeftSide = email.indexOf('<') > -1;
            var hasRightSide = email.indexOf('>') > -1;
            if (hasLeftSide && !hasRightSide) {
                failReasons.push('missing ">" after "' + email + '"');
            }
            if (!hasLeftSide && hasRightSide) {
                failReasons.push('missing "<" before "' + email + '"');
            }
        }

        if (failReasons.length > 0) {
            this.reason = '' + this.description + ': ';
            for (var i = 0, ni = failReasons.length; i < ni; i++) {
                if (i > 0) {
                    this.reason += ', ';
                }
                this.reason += strWeb(failReasons[i]);
            }
            return false;
        }

        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidDate () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (hasVal) {
        var value = formItem.value;
        if (value.length < 10 || value.charAt(2) != '/' || value.charAt(5) != '/') {
            this.reason = '' + this.description + ' is not a valid date format (MM/DD/YYYY)';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}
function ValidateItem_isValidPhone () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    var value = formItem.value.replace(/\D/g, '');
    var intTelInstance = null;

    if (hasVal) {
        if (intlTelInputGlobals) {
            for (var instance in intlTelInputGlobals.instances) {
                if (intlTelInputGlobals.instances.hasOwnProperty(instance)) {
                    if (formItem === intlTelInputGlobals.instances[instance].telInput) {
                        intTelInstance = intlTelInputGlobals.instances[instance];
                    }
                }
            }
        }

        if (intTelInstance) {
            if (intTelInstance.isValidNumber()) {
                if (intTelInstance.getSelectedCountryData().dialCode === '1') {
                    this.reason = this.description + ' is not a valid phone number';
                    return isValidNANP(intTelInstance.getNumber());
                }
                return true;
            }
            this.reason = this.description + ' is not a valid number for selected country';
            return false;
        }

        if (value.length < 8 || value.length > 15) {
            this.reason = '' + this.description + ' is not a valid phone number';
            return false;
        }

        return true;
    }

    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }

    return true;

    function isValidNANP (val) {
        // NANP phone regex based on: http://regexlib.com/REDetails.aspx?regexp_id=607
        var nanpRegex = /^(1)?(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?|\.?))[2-9]\d{2}[- \.]?\d{4}$/;
        var tel = val.replace(/\D/g, '');

        if (tel.charAt(0) === '1') {
            if (tel.length !== 11) {
                return false;
            }
        } else {
            if (tel.length !== 10) {
                return false;
            }
        }

        return nanpRegex.test(tel);
    }
}
function ValidateItem_isValidCreditCard () {
    var formItem = lookupFormItem(this.name, this.options);
    var cardNumHasVal = itemHasValue(formItem);
    var cardTypeHasVal = hasValue(getDropDownSelectedValue(this.extra));
    var cardTypeName = getDropDownSelectedText(this.extra);
    if (cardNumHasVal) {
        var value = formItem.value;
        if (isNaN(value)) {
            this.reason = '' + this.description + ' can only contain numbers';
            return false;
        }
        if (cardTypeHasVal && !isValidCreditCardNumber(value, cardTypeName)) {
            this.reason = '' + this.description + ' is not a valid credit card number for the selected credit card type';
            return false;
        }
        return true;
    }
    if (this.required) {
        this.reason = '' + this.description + ' is required';
        return false;
    }
    return true;
}

function ValidateItem_isValidCheckBoxes () {
    var ids = this.extra;
    for (var i = 0; i < ids.length; i++) {
        var anid = ids[i];

        // assumes name combined with id makes the name of the check box object.
        var formItem = lookupFormItem(this.name + anid, this.options);
        if (formItem != null) {
            if (formItem.checked) {
                return true;
            }
        }
    }
    this.reason = 'At least one ' + this.description + ' must be selected';
    return false;
}
function ValidateItem_isValidWidget () {
    // each widget can validate itself
    var widget = this.extra;
    if (widget.isValid()) {
        return true;
    }
    this.reason = this.description + ' ' + widget.getReason();
    return false;
}

function ValidateItem_isRadioType () {
    return this.type == ValidateItem.RADIO;
}
function ValidateItem_isValidMinMax () {
    var minValue = parseInt(this.extra2);
    var maxValue = parseInt(this.extra3);
    if (minValue > maxValue) {
        this.reason = this.description;
        return false;
    }
    return true;
}

/*
 * constructors for a ValidateItem. Simplify object creating to require less
 * code on JSP pages. more of these can be added as needed to fit all the
 * possible conditions.
 */
function newEmailValidateItem (aName, aDescription, options) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.EMAIL, options);
}
function newOptionalEmailValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.EMAIL);
}
function newDateValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.DATE);
}
function newOptionalDateValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.DATE);
}
function newPhoneValidateItem (aName, aDescription, options) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.PHONE, options);
}
function newOptionalPhoneValidateItem (aName, aDescription, options) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.PHONE, options);
}
function newNumberValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.NUMBER);
}
function newNonNegativeNumberValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.NONNEGATIVENUMBER);
}
function newOptionalNonNegativeNumberValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.NONNEGATIVENUMBER);
}
function newPositiveNumberValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.POSITIVENUMBER);
}
function newOptionalPositiveNumberValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.POSITIVENUMBER);
}
function newPositiveMoneyValidateItem (aName, aDescription, onErrorHighlightOptions) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.POSITIVEMONEY);
    newItem.onErrorHighlightOptions = onErrorHighlightOptions;
    return newItem;
}
function newOptionalPositiveMoneyValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.POSITIVEMONEY);
}
function newMoneyValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.MONEY);
}
function newOptionalMoneyValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.MONEY);
}
function newIntegerValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.INTEGER);
}
function newPositiveIntegerValidateItem (aName, aDescription, options) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.POSITIVEINTEGER, options);
}
function newNonNegativeIntegerValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, true, ValidateItem.NONNEGATIVEINTEGER);
}
function newOptionalNumberValidateItem (aName, aDescription, options) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.NUMBER, options);
}
function newOptionalIntegerValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.INTEGER);
}
function newOptionalPositiveIntegerValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.POSITIVEINTEGER);
}
function newOptionalNonNegativeIntegerValidateItem (aName, aDescription) {
    return new ValidateItem(aName, aDescription, false, ValidateItem.NONNEGATIVEINTEGER);
}
function newCreditCardValidateItem (aName, aDescription, cardTypeFieldName, options) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.CREDITCARD, options);
    newItem.extra = cardTypeFieldName;
    return newItem;
}
function newRadioValidateItem (aName, aDescription, anAnchorName) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.RADIO);
    newItem.anchorName = anAnchorName;
    return newItem;
}
function newNotSpecificValueValidateItem (aName, aDescription, aSpecificValue, aFailureDescription) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.NOTSPECIFICVALUE);
    newItem.extra2 = aSpecificValue;
    newItem.failureDescription = aFailureDescription;
    return newItem;
}
function newConditionalFunction (aName, aDescription, conditionFunction, options) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.BASICVALUE, options);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_FUNCTION;
    newItem.extra = conditionFunction;
    return newItem;
}
function newConditionalPrereqFunction (aName, aDescription, conditionFunction, failureDescription, options) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.BASICVALUE, options);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_PREREQ_FUNCTION;
    newItem.extra = conditionFunction;
    newItem.failureDescription = failureDescription;
    return newItem;
}
function newConditionalValidateItem (aName, aDescription, conditionItemName) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.BASICVALUE);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_CHECKED;
    newItem.extra = conditionItemName;
    return newItem;
}
function newConditionalHasValueValidateItem (aName, aDescription, conditionItemName) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.BASICVALUE);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_HAS_VALUE;
    newItem.extra = conditionItemName;
    return newItem;
}
function newConditionalNumberValidateItem (aName, aDescription, conditionItemName) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.NUMBER);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_CHECKED;
    newItem.extra = conditionItemName;
    return newItem;
}
function newConditionalIntegerValidateItem (aName, aDescription, conditionItemName) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.INTEGER);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_CHECKED;
    newItem.extra = conditionItemName;
    return newItem;
}
function newConditionalPositiveIntegerValidateItem (aName, aDescription, conditionItemName) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.POSITIVEINTEGER);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_CHECKED;
    newItem.extra = conditionItemName;
    return newItem;
}
function newConditionalIntegerRangeValidateItem (aName, aDescription, conditionItemName, minValue, maxValue) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.INTEGERRANGE);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_CHECKED;
    newItem.extra = conditionItemName;
    newItem.extra2 = minValue;
    newItem.extra3 = maxValue;
    return newItem;
}
function newIntegerRangeValidateItem (aName, aDescription, minValue, maxValue, onErrorHighlightOptions) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.INTEGERRANGE);
    newItem.extra2 = minValue;
    newItem.extra3 = maxValue;
    newItem.onErrorHighlightOptions = onErrorHighlightOptions;
    return newItem;
}
function newConditionalDateValidateItem (aName, aDescription, conditionItemName) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.DATE);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_CHECKED;
    newItem.extra = conditionItemName;
    return newItem;
}
function newSpecificRadioConditionalDateValidateItem (aName, aDescription, conditionItemName, conditionItemValue) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.DATE);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRARADIO_IS_EXTRA2;
    newItem.extra = conditionItemName;
    newItem.extra2 = conditionItemValue;
    return newItem;
}
function newSpecificConditionalValidateItem (aName, aDescription, conditionItemName, conditionItemValue) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.BASICVALUE);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_EXTRA2;
    newItem.extra = conditionItemName;
    newItem.extra2 = conditionItemValue;
    return newItem;
}
function newSpecificConditionalNumberValidateItem (aName, aDescription, conditionItemName, conditionItemValue) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.NUMBER);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_EXTRA2;
    newItem.extra = conditionItemName;
    newItem.extra2 = conditionItemValue;
    return newItem;
}
function newSpecificConditionalIntegerValidateItem (aName, aDescription, conditionItemName, conditionItemValue) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.INTEGER);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_EXTRA2;
    newItem.extra = conditionItemName;
    newItem.extra2 = conditionItemValue;
    return newItem;
}
function newSpecificConditionalPositiveIntegerValidateItem (aName, aDescription, conditionItemName, conditionItemValue) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.POSITIVEINTEGER);
    newItem.conditionalType = ValidateItem.CONDITIONS.EXTRA_IS_EXTRA2;
    newItem.extra = conditionItemName;
    newItem.extra2 = conditionItemValue;
    return newItem;
}
function newValidateItem (aName, aDescription, options) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.BASICVALUE, options);
    return newItem;
}
function newPasswordValidateItem (aName, field2Name, options) {
    var newItem = new ValidateItem(aName, '', true, ValidateItem.PASSWORDMATCH, options);
    newItem.extra = field2Name;
    return newItem;
}
function newOptionalPasswordValidateItem (aName, field2Name, options) {
    var newItem = new ValidateItem(aName, '', false, ValidateItem.PASSWORDMATCH, options);
    newItem.extra = field2Name;
    return newItem;
}
function newORValidateItem (aName, aDescription, field2Name) {
    var newItem = new ValidateItem(aName, aDescription, true, ValidateItem.VALUEOREXTRA);
    newItem.extra = field2Name;
    return newItem;
}
function newCheckBoxesValidateItem (aNamePrefix, aDescription, checkBoxIdArray) {
    var newItem = new ValidateItem(aNamePrefix, aDescription, true, ValidateItem.CHECKBOXES);
    newItem.extra = checkBoxIdArray;
    return newItem;
}
function newWidgetValidateItem (aDescription, widgetInstance) {
    var newItem = new ValidateItem(null, aDescription, true, ValidateItem.WIDGET);
    newItem.extra = widgetInstance;
    return newItem;
}
function newMinMaxValidateItem (aDescription, minValue, maxValue) {
    var newItem = new ValidateItem(null, aDescription, true, ValidateItem.MINMAXMATCH);
    newItem.extra2 = minValue;
    newItem.extra3 = maxValue;
    return newItem;
}

/*
 * Custom complex validate item. This item can be used as a base object to do
 * complex validation. Redefine the isValid() function to perform what you need.
 */
function CustomValidateItem (aName, isValidFunction, reason, description) {
    this.name = aName;
    this.reason = '';
    this.description = '';
    if (reason != null) {
        this.reason = reason;
    }
    if (description != null) {
        this.description = description;
    }

    // public object functions (required by validator)
    this.isValid = function () {
        // redefine this function to validate,
        // return true if valid.
        // return false if not valid and set reason to a description why.
        return true;
    };
    if (isValidFunction != null) {
        this.isValid = isValidFunction;
    }
    this.getReason = function () {
        return this.reason;
    };
    this.getDescription = function () {
        return this.description;
    };
    this.isRadioType = function () {
        return false;
    };
    this.getName = function () {
        return this.name;
    };
}

/*
 * functions to use with CustomValidateItem
 */

/*
 * validateOptionalIntegerOrPercentNumberFunction is useful for validating
 * iframe height/width EFs where the values can be plain pixel size or a
 * percent. Both types of values are checked for: - integer - non negative - not
 * zero - (if percent) not over 100
 */
var validateOptionalIntegerOrPercentNumberFunction = function () {
    var formItem = lookupFormItem(this.name, this.options);
    var hasVal = itemHasValue(formItem);
    if (!hasVal) {
        return true;
    }
    var value = formItem.value;
    value = value.replace(',', '');
    var isPercent = value.indexOf('%') != -1;
    value = value.replace('%', '');
    if (isNaN(value) || value.indexOf('.') != -1) {
        this.reason = '' + this.description + ' is not an integer';
        return false;
    }
    value = parseInt(value, 10);
    if (value == 0) {
        this.reason = '' + this.description + ' cannot be zero';
        return false;
    }
    if (value < 0) {
        this.reason = '' + this.description + ' cannot be negative';
        return false;
    }
    if (isPercent && value > 100) {
        this.reason = '' + this.description + ' cannot be greater than 100%';
        return false;
    }
    return true;
};
