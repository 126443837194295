function JDArray (array) {
    this.array = array;
    if (this.array == null) {
        this.array = [];
    }
    this.options = {};

    // methods
    this.add = function (newItem) {
        this.array[this.array.length] = newItem;
    };
    this.contains = function (anItem) {
        var list = this.array;
        for (var i = 0; i < list.length; i++) {
            var item = list[i];
            if (item == anItem) {
                return true;
            }
        }
        return false;
    };
    this.length = function () {
        return this.array.length;
    };
    this.size = this.length;
    this.get = function (index) {
        return this.array[index];
    };
}
