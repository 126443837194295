(function () {
    angular.module('collaterate.common.directives.autocomplete', ['angucomplete-alt']).directive('collaterateAutocomplete', function () {
        return {
            restrict: 'AE',
            templateUrl: '/templates/autocomplete.template.html',
            link: function (scope, element, attrs) {
                scope.chipTerms = [];

                scope.onSelectItem = function (value) {
                    if (scope.list && scope.chipExclude) {
                        for (var i = 0; i < scope.list.length; i++) {
                            if (value.originalObject[scope.chipExclude] === scope.list[i][scope.chipExclude]) {
                                return;
                            }
                        }
                    }
                    scope.onSelected(value.originalObject);
                };

                scope.getHideRemoveItemClass = function (shouldHide) {
                    if (shouldHide) {
                        return scope.hideRemoveItemClass;
                    }
                };

                var init = function () {
                    createChipDisplayString();
                };

                var createChipDisplayString = function () {
                    if (!scope.useChips) {
                        return;
                    }
                    scope.chipTerms = scope.chipDisplay.split(',');
                };

                init();
            },
            scope: {
                angucompleteId: '@',
                placeholder: '@',
                maxlength: '@',
                pause: '@',
                remoteUrl: '@',
                remoteUrlDataField: '@',
                titleField: '@',
                descriptionField: '@',
                imageField: '@',
                minlength: '@',
                inputName: '@',
                inputClass: '@',
                matchClass: '@',
                localData: '=',
                searchFields: '@',
                remoteUrlRequestFormatter: '=',
                remoteUrlRequestWithCredentials: '@',
                remoteUrlResponseFormatter: '=',
                remoteUrlErrorCallback: '=',
                remoteApiHandler: '=',
                clearSelected: '=',
                overrideSuggestions: '=',
                fieldRequired: '=',
                fieldRequiredClass: '@',
                textSearching: '@',
                textNoResults: '@',
                initialValue: '=',
                inputChanged: '=',
                autoMatch: '@',
                focusIn: '&',
                focusOut: '&',
                disableInput: '=',

                // Template URL removed for now due to
                // templateUrl: '@',
                focusFirst: '@',
                parseInput: '=',
                list: '=',
                removeItem: '&',
                hideRemoveItem: '&',
                hideRemoveItemClass: '@',
                hideRemoveItemText: '@',
                onSelected: '=',
                useChips: '=',
                chipDisplay: '@',
                chipExclude: '@'
            }
        };
    });
}());
