var TBGBODivision = {
    ID_PREFIX: 'DIV_'
};

cometBusinessObjectBehaviour.add('TBGBODivision', {
    getTypeAndId: function () {
        return TBGBODivision.ID_PREFIX + this.id;
    },
    isType: function (prefix) {
        return this.getTypeAndId().indexOf(prefix) == 0;
    },
    getListItemName: function () {
        var name = '';
        if (hasValue(this.name)) {
            name += strWeb(this.name);
        }
        if (hasValue(this.code)) {
            name += ' (';
            name += strWeb(this.code);
            name += ')';
        }
        return name;
    }
});
