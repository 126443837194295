/*
Notes from Dan:

Several layers of configuration here... The first layer of defaults are defined in
src/main/webapp/web3/js/ng/common/directives/plupload/plupload-settings.service.js,
and these (at present) mirror the plupload default settings.

These settings can be merged/overridden by a settings object in the app.confg()
phase, which is where any site-specific configuration should take place.

Third, the directive markup accepts an attribute, "plupload-opts," which accepts
an object that is again merged on top of the already-merged settings. You can
use this to create a controller-specific override of something that was a
default from the plupload-settings service, or set globally during app.config().
It is worth noting that any Plupload Events that are defined at this level are
NOT merged, they will override what is present below in our directive. This is
intentional and allows a controller to completely tap into Plupload, ignoring
any default behavior we build into the directive if needed.

Last, you can use our default Plupload Events, and perform additional behavior
for each event by binding methods to the handler attributes available on the
directive. Each native Plupload event is exposed and can be tapped into via
the directive's attributes. Using this method allows our default behaviors to
run, as well as any additional behavior you need to add on top of those.

I have additionally included an "auto-upload" attribute, which expects a boolean
and, predictably, controls whether or not the file auto uploads.

Example Markup:

<input
    tbg-plupload
    plupload-opts="ctrl.testOpts"           - (optional) Plupload settings object

    ng-model="ctrl.product.pdfFileName"     - if supplied, is updated with filename on upload
    model-value="response.fileName"         - expression to evaluate that controls what will be sent to ng-model
    name="pdfFileName"                      - the form element name, not used by directive, but can be used for validation
    ng-change="ctrl.doThing()"              - if ng-model is supplied, ng-change works as expected. requires ng-model.
    required                                - normal validation attributes work with ng-model

    auto-upload="false"                     - whether or not to immediately start upload upon selection (default: true)
    on-init="ctrl.init(up, info)"
    on-upload-file="ctrl.uploadFile(up, file)"
    on-post-init="ctrl.postInit()"
    on-browse="ctrl.browse(up)"
    on-refresh="ctrl.refresh(up)"
    on-state-changed="ctrl.stateChanged(up)"
    on-queue-changed="ctrl.queueChanged(up)"
    on-option-changed="ctrl.optionChanged(up, name, value, oldValue)"
    on-before-upload="ctrl.beforeUpload(up, file)"
    on-upload-progress="ctrl.uploadProgress(up, file)"
    on-file-filtered="ctrl.fileFiltered(up, file)"
    on-files-added="ctrl.filesAdded(up, files)"
    on-files-removed="ctrl.filesRemoved(up, files)"
    on-file-uploaded="ctrl.fileUploaded(up, file, info)"
    on-chunk-uploaded="ctrl.chunkUploaded(up, file, info)"
    on-upload-complete="ctrl.uploadComplete(up, file, info)"
    on-destroy="ctrl.destroy(up)"
    on-error="ctrl.error(up, args)"
    on-message="ctrl.onMessage(data)"
/>

*/

(function () {
    'use strict';

    angular
        .module('collaterate.common.plupload')
        .factory('PluploadDirective', getPluploadDirectiveConstructor);

    getPluploadDirectiveConstructor.$inject = ['PluploadSettings', '$timeout'];

    function getPluploadDirectiveConstructor (PluploadSettings, $timeout) {
        function PluploadDirective (configArgs) {
            var defaultConfig = {
                restrict: 'AE',
                templateUrl: '/templates/plupload.template.html',
                require: '^?ngModel',
                replace: 'true',
                scope: {
                    pluploadOpts: '=',
                    autoUpload: '@',
                    modelValue: '@',
                    onInit: '&',
                    onUploadFile: '&',
                    onPostInit: '&',
                    onBrowse: '&',
                    onRefresh: '&',
                    onStateChanged: '&',
                    onQueueChanged: '&',
                    onOptionChanged: '&',
                    onBeforeUpload: '&',
                    onUploadProgress: '&',
                    onFileFiltered: '&',
                    onFilesAdded: '&',
                    onFilesRemoved: '&',
                    onFileUploaded: '&',
                    onChunkUploaded: '&',
                    onUploadComplete: '&',
                    onDestroy: '&',
                    onError: '&',
                    onMessage: '&'
                }
            };

            var directiveConfig = angular.merge({}, defaultConfig, configArgs);

            Object.assign(this, directiveConfig);
        }

        PluploadDirective.prototype.link = function (scope, element, attrs, ngModel) {
            var autoUpload = true;
            scope.uploader = null; // Our plupload instance
            scope.dragover = false; // state tracking for whether or not the dropzone is currently "dragged over"
            scope.plupload = plupload; // expose the global plupload object to our template

            if (attrs.hasOwnProperty('autoUpload') && attrs.autoUpload === 'false') {
                autoUpload = false;
            }

            // var el = element[0];
            var dropTarget = element.find('.uploadDropTarget');
            var modelArray = []; // Tracks files uploaded for the model if multi-file is active

            var options = {
                browse_button: dropTarget[0],
                drop_element: dropTarget[0],

                // PreInit events, bound before any internal events
                preinit: {
                    Init: function (up, info) {
                        $timeout(function () {
                            scope.onInit({ up: up, info: info });
                        });
                    },
                    UploadFile: function (up, file) {
                        $timeout(function () {
                            scope.onUploadFile({ up: up, file: file });
                        });
                    }
                },

                // Post init events, bound after the internal events
                init: {
                    PostInit: function () {
                        $timeout(function () {
                            scope.onPostInit();
                        });
                    },
                    Browse: function (up) {
                        $timeout(function () {
                            scope.onBrowse({ up: up });
                        });
                    },
                    Refresh: function (up) {
                        $timeout(function () {
                            scope.onRefresh({ up: up });
                        });
                    },
                    StateChanged: function (up) {
                        $timeout(function () {
                            scope.onStateChanged({ up: up });
                        });
                    },
                    QueueChanged: function (up) {
                        $timeout(function () {
                            scope.onQueueChanged({ up: up });
                        });
                    },
                    OptionChanged: function (up, name, value, oldValue) {
                        $timeout(function () {
                            scope.onOptionChanged({ up: up, name: name, value: value, oldValue: oldValue });
                        });
                    },
                    BeforeUpload: function (up, file) {
                        $timeout(function () {
                            scope.onBeforeUpload({ up: up, file: file });
                        });
                    },
                    UploadProgress: function (up, file) {
                        $timeout(function () {
                            scope.onUploadProgress({ up: up, file: file });
                        });
                    },
                    FileFiltered: function (up, file) {
                        $timeout(function () {
                            scope.onFileFiltered({ up: up, file: file });
                        });
                    },
                    FilesAdded: function (up, files) {
                        $timeout(function () {
                            scope.onFilesAdded({ up: up, files: files });
                        });
                    },
                    FilesRemoved: function (up, files) {
                        $timeout(function () {
                            scope.onFilesAdded({ up: up, files: files });
                        });
                    },
                    FileUploaded: function (up, file, info) {
                        if (info.response) {
                            try {
                                if (scope.modelValue) {
                                    var response = JSON.parse(info.response);
                                    var val = eval(scope.modelValue);

                                    // NOTE! This is not built to handle removal of files at this point,
                                    // but file removal is also not a feature of the directive at this point.
                                    // If we implement that as a feature, will need to expand on
                                    // how we handle multi-file tracking as well.
                                    if (scope.uploader.settings.multi_selection) {
                                        modelArray.push(val);

                                        // ngModel watches for changes via reference, not value,
                                        // always passing a new array so that it keeps itself up to date
                                        updateModel([].concat(modelArray));
                                    } else {
                                        updateModel(val);
                                    }
                                }
                            } catch (e) {
                                scope.uploader.trigger('Error', {
                                    code: 9,
                                    message: 'Unknown error',
                                    file: file
                                });
                            }
                        } else {
                            scope.uploader.trigger('Error', {
                                code: info.status,
                                message: 'Connection failed [' + info.status + ']. Reload page.',
                                file: file
                            });
                        }

                        $timeout(function () {
                            scope.onFileUploaded({ up: up, file: file, info: info });
                        });
                    },
                    ChunkUploaded: function (up, file, info) {
                        if (info.response) {
                            var jsonResponse = JSON.parse(info.response);
                            if (jsonResponse.error) {
                                scope.uploader.trigger('Error', {
                                    code: jsonResponse.error.code,
                                    message: jsonResponse.error.message,
                                    file: file
                                });
                            }
                        } else {
                            scope.uploader.trigger('Error', {
                                code: info.status,
                                message: 'Connection failed [' + info.status + ']. Reload page.',
                                file: file
                            });
                        }

                        $timeout(function () {
                            scope.onChunkUploaded({ up: up, file: file, info: info });
                        });
                    },
                    UploadComplete: function (up, files) {
                        $timeout(function () {
                            scope.onUploadComplete({ up: up, files: files });
                        });
                    },
                    Destroy: function (up) {
                        $timeout(function () {
                            scope.onDestroy({ up: up });
                        });
                    },
                    Error: function (up, args) {
                        $timeout(function () {
                            scope.onError({ up: up, args: args });
                        });
                    }
                }
            };

            init();

            function init () {
                scope.uploader = new plupload.Uploader(angular.merge(options, PluploadSettings, scope.pluploadOpts));
                scope.uploader.init();
                bindUI();
                bindEvents();
                scope.$on('$destroy', unbindUI);
            }

            /*
                Use this function to "hard-bind" to events...
                In the case that there is a need to persist certain
                behavior, but also let events be overloaded via
                the "scope.pluploadOpts" object, this is how we
                implement that behavior.
            */
            function bindEvents () {
                scope.uploader.bind('Refresh', function (up) {
                    // The call to scope.uploader.splice(0) in the
                    // ngModel.$render handler was causing an
                    // already-in-progress $digest error, but is needed.
                    // May revisit that in the future... For now, this is
                    // harmless enough in this case.
                    $timeout(function () {
                        scope.$apply();
                    });
                });

                scope.uploader.bind('UploadProgress', function (up, file) {
                    scope.$apply();
                });

                scope.uploader.bind('FilesAdded', function (up, files) {
                    if (scope.uploader.settings.multi_selection === false) {
                        if (files.length > 1 || scope.uploader.files.length > files.length) {
                            scope.uploader.splice(0, scope.uploader.files.length - 1);
                        }

                        if (scope.uploader.files[0].status !== plupload.DONE && autoUpload) {
                            scope.uploader.start();
                        }
                    } else {
                        if (autoUpload) {
                            scope.uploader.start();
                        }
                    }
                });

                scope.uploader.bind('Error', function (up, args) {
                    sendMessage({
                        type: 'ERROR',
                        instance: scope.uploader,
                        data: args
                    });
                });
            }

            function bindUI () {
                dropTarget.on('dragover', function (e) {
                    e.originalEvent.dataTransfer.dropEffect = 'copy';
                });

                dropTarget.on('dragenter', function (e) {
                    scope.dragover = true;
                    scope.$apply();
                });

                dropTarget.on('dragleave', function (e) {
                    scope.dragover = false;
                    scope.$apply();
                });

                dropTarget.on('drop', function (e) {
                    scope.dragover = false;
                    scope.$apply();
                });
            }

            function unbindUI () {
                dropTarget.off('dragover');
                dropTarget.off('dragenter');
                dropTarget.off('dragleave');
                dropTarget.off('drop');
            }

            if (ngModel) {
                ngModel.$render = function () {
                    if (!ngModel.$viewValue || !ngModel.$viewValue.length) {
                        scope.uploader.splice(0);
                    }

                    if (!scope.uploader.settings.multi_selection) {
                        return;
                    }

                    if (ngModel.$viewValue && ngModel.$viewValue.length) {
                        modelArray = [].concat(ngModel.$viewValue);
                    }
                };
            }

            function updateModel (value) {
                if (!ngModel || value === undefined) {
                    return;
                }
                ngModel.$setViewValue(value);
            }

            function sendMessage (msg) {
                if (!msg) {
                    return;
                }
                scope.onMessage({ msg: msg });
            }
        };

        return PluploadDirective;
    }
}());
