var alertPanel = {
    divId: 'alertPanel',
    onCloseFunction: null,
    alert: function (content, onCloseFunction) {
        var $panel = $('#' + alertPanel.divId);
        var convertedContent = strBR(content);
        dynamic.showOverlay('alertPanelOverlay', 30);
        dynamic.setDivIdContent('alertPanelContent', convertedContent);
        alertPanel.onCloseFunction = onCloseFunction;
        var top = -$('body').position().top + 100;
        dynamic.centerDiv(alertPanel.divId);
        $panel.css({
            top: top + 'px'
        }).fadeIn();
        $panel.find('#alertPanelOKPB').on('click', handleCloseClick);
        $(window).on('keydown.alertPanel', alertPanel.onkeyup);

        //		$('#' + alertPanel.divId).keyup(function(e) {
        //			e.preventDefault();
        //			alertPanel.onkeyup(e);
        //			return false();
        //		});
        function handleCloseClick (e) {
            e.stopPropagation();
            alertPanel.close();
        }
    },
    close: function () {
        var $panel = $('#' + alertPanel.divId);
        $panel.find('#alertPanelOKPB').off('click');
        $(window).off('keydown.alertPanel');
        alertPanel.onclose();
        if (alertPanel.onCloseFunction) {
            alertPanel.onCloseFunction();
        }
        $panel.fadeOut(null, function () {
            dynamic.setDivIdContent('alertPanelContent', '');
        });
        dynamic.hideOverlay('alertPanelOverlay');
    },
    onkeyup: function (event) {
        if (isEnterEvent(event) || isEscapeEvent(event)) {
            stopPropagation(event);
            delay(function () {
                alertPanel.close();
            }, 100);
        }
    },
    onclose: function () {
        // event handlers
    }
};
