(function () {
    'use strict';

    angular
        .module('collaterate.panels')
        .factory('alertPanelService', alertPanelService);

    alertPanelService.$inject = ['$window'];

    function alertPanelService ($window) {
        return $window.alertPanel || angular.noop;
    }
}());
