(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .factory('queryParams', queryParams);

    queryParams.$inject = ['$window'];

    function queryParams ($window) {
        var service = {
            get: getParams
        };

        return service;

        function getParams () {
            var search = $window.location.search.substring(1);
            return JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === '' ? value : decodeURIComponent(value);
            });
        }
    }
}());
