Promise.prototype.finally = Promise.prototype.finally || {
    finally: function (fn) {
        var onFinally = function (callback) {
            return Promise.resolve(fn()).then(callback);
        };
        return this.then(
            function (result) {
                return onFinally(function () {
                    return result;
                });
            },
            function (reason) {
                return onFinally(function () {
                    return Promise.reject(reason);
                });
            }
        );
    }
}.finally;
