function CometMessages (divId, thisName) {
    this.autoHideInfo = false;
    this.autoHideWarn = false;
    this.autoHideError = false;
    if (thisName != null) {
        this.autoHideInfo = true;
        this.autoHideWarn = true;
        this.autoHideError = true;
    }
    this.divId = divId;
    this.thisName = thisName;

    // state
    this.showing = false;

    // functions
    this.showAll = CometMessages_showAll;
    this.showAllInfo = CometMessages_showAllInfo;
    this.showInfo = CometMessages_showInfo;
    this.showError = CometMessages_showError;
    this.showAllWarning = CometMessages_showAllWarning;
    this.showAllMessages = CometMessages_showAllMessages;
    this.show = CometMessages_show;
    this.hide = CometMessages_hide;
}

function CometMessagesSpecifyAutoHide (divId, thisName, autoHideInfo, autoHideWarn, autoHideError) {
    this.autoHideInfo = autoHideInfo;
    this.autoHideWarn = autoHideWarn;
    this.autoHideError = autoHideError;
    this.divId = divId;
    this.thisName = thisName;

    // state
    this.showing = false;

    // functions
    this.showAll = CometMessages_showAll;
    this.showAllInfo = CometMessages_showAllInfo;
    this.showInfo = CometMessages_showInfo;
    this.showError = CometMessages_showError;
    this.showAllWarning = CometMessages_showAllWarning;
    this.showAllMessages = CometMessages_showAllMessages;
    this.show = CometMessages_show;
    this.hide = CometMessages_hide;
}

function CometMessages_showAll (cometRequest) {
    var content = '';
    var autoHide = true;
    var errorMessages = cometRequest.errorMessages;
    if (errorMessages != null && errorMessages.length > 0) {
	    if (!this.autoHideError) {
	        autoHide = false;
	    }
        for (var i = 0; i < errorMessages.length; i++) {
		    content += '<div id="userMessageError">' + strWeb(errorMessages[i]) + '</div>\n';
        }
    }
    var warningMessages = cometRequest.warningMessages;
    if (warningMessages != null && warningMessages.length > 0) {
        if (!this.autoHideWarn) {
            autoHide = false;
        }
        for (var i = 0; i < warningMessages.length; i++) {
		    content += '<div id="userMessageAlert">' + strWeb(warningMessages[i]) + '</div>\n';
        }
    }
    var infoMessages = cometRequest.infoMessages;
    if (infoMessages != null && infoMessages.length > 0) {
        if (!this.autoHideInfo) {
            autoHide = false;
        }
        for (var i = 0; i < infoMessages.length; i++) {
		    content += '<div id="userMessageInfo">' + strWeb(infoMessages[i]) + '</div>\n';
        }
    }
    if (content != '') {
        var tempThis = this;
        this.hide(function () {
            tempThis.show(content, autoHide);
        });
    }
}
function CometMessages_showInfo (infoMessage) {
    var content = '';
    content += '<div id="userMessageInfo">' + strWeb(infoMessage) + '</div>\n';
    if (content != '') {
        var tempThis = this;
        this.hide(function () {
            tempThis.show(content, tempThis.autoHideInfo);
        });
    }
}
function CometMessages_showError (errorMessage) {
    var content = '';
    content += '<div id="userMessageError">' + strWeb(errorMessage) + '</div>\n';
    if (content != '') {
        var tempThis = this;
        this.hide(function () {
            tempThis.show(content, tempThis.autoHideError);
        });
    }
}
function CometMessages_showAllInfo (messageArray) {
    this.showAllMessages(messageArray, 'userMessageInfo', this.autoHideInfo);
}
function CometMessages_showAllWarning (messageArray) {
    this.showAllMessages(messageArray, 'userMessageAlert', this.autoHideWarn);
}
function CometMessages_showAllMessages (messageArray, divId, autoHide) {
    if (messageArray != null && messageArray.length > 0) {
        var tempThis = this;
        this.hide(function () {
            var content = '';
            for (var i = 0; i < messageArray.length; i++) {
                var message = messageArray[i];
                content += '<div id="' + divId + '">' + strWeb(message) + '</div>';
            }
            tempThis.show(content, autoHide);
        });
    }
}
function CometMessages_show (content, autoHide, onDoneFunction) {
    this.showing = true;
    logDebug(content);
    dynamic.setContentSmooth2(this.divId, content, {
        duration: 400,
        onDone: onDoneFunction
    });
    if (autoHide) {
        setTimeout(this.thisName + '.hide()', 5000);
    }
}
function CometMessages_hide (onDoneFunction) {
    if (this.showing) {
        dynamic.setContentSmooth2(this.divId, '', {
            duration: 400,
            onDone: onDoneFunction
        });
        this.showing = false;
    } else {
        if (onDoneFunction != null) {
            onDoneFunction();
        }
    }
}
