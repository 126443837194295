(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .directive('collaterateInfo', collaterateInfo);

    collaterateInfo.$inject = [];

    function collaterateInfo () {
        return {
            restrict: 'AE',
            templateUrl: '/templates/collaterate-info.template.html',
            scope: {},
            controller: CollaterateInfoController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    CollaterateInfoController.$inject = ['$attrs', '$interval', 'collaterateInfoService', '$window'];

    function CollaterateInfoController ($attrs, $interval, collaterateInfoService, $window) {
        var vm = this;
        var INTERVAL_MS = 60000;
        var messagesUri = $attrs.messagesUri;

        vm.messages = [];

        init();

        return;

        function init () {
            getMessages();
            $interval(getMessages, INTERVAL_MS);
        }

        function getMessages () {
            return collaterateInfoService
                .getInfo(messagesUri)
                .then(updateMessages);

            function updateMessages (messages) {
                if ($window.tbg && $window.tbg.notifications && !$window.tbg.notifications.trigger($window.tbg.notifications.SYSTEM_MESSAGE, { messages: messages })) {
                    return;
                }

                vm.messages = messages;
            }
        }
    }
}());
