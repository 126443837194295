(function () {
    'use strict';

    angular
        .module('tbg.errorReporting')
        .factory('errorLogger', errorLogger);

    errorLogger.$inject = ['$window'];

    function errorLogger ($window) {
        return {
            log: $window.tbgErrorLogger.log
        };
    }
}());
