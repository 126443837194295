function JDURL (theUrl) {
    this.url = theUrl;
    this.params = '';

    // methods
    this.appendParam = function (newName, newValue) {
        var delim = '&';
        var working = this.params;
        if (working == null || newName == null || newValue == null) {
            return;
        }
        if (working == null || working == '') {
            delim = '';
        }
        this.params = working + delim + newName + '=' + encodeURIComponent(newValue);
    };
    this.getUrl = function () {
        if (this.url == null || this.url == '') {
            return this.params;
        } if (this.params == null || this.params == '') {
            return this.url;
        }
        var delim = '?';
        var working = this.url;
        if (working.indexOf('?') != -1) {
            delim = '&';
        }
        return this.url + delim + this.params;
    };
    this.getParams = function () {
        return this.params;
    };
    this.toHttpString = function () {
        return 'http://' + this.getUrl();
    };
    this.toHttpsString = function () {
        return 'https://' + this.getUrl();
    };
    this.toString = function () {
        return this.toHttpString();
    };
}
