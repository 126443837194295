(function () {
    'use strict';

    /* eslint-disable angular/directive-restrict */
    /* eslint-disable angular/component-limit */

    angular
        .module('storefrontApp')
        .directive('bulkOrderUploadPlupload', bulkOrderUploadPlupload)
        .directive('bulkOrderUpload', bulkOrderUpload);

    bulkOrderUploadPlupload.$inject = ['PluploadDirective'];

    function bulkOrderUploadPlupload (PluploadDirective) {
        var Superclass = PluploadDirective;
        BulkOrderUploadPluploadDirective.prototype = new Superclass();
        BulkOrderUploadPluploadDirective.prototype.constructor = BulkOrderUploadPluploadDirective;

        function BulkOrderUploadPluploadDirective () {
            var directiveConfig = {
                templateUrl: '/templates/bulk-order-upload-plupload.template.html',
                scope: {
                    errorMessage: '='
                }
            };

            Superclass.call(this, directiveConfig);
        }

        return new BulkOrderUploadPluploadDirective();
    }

    bulkOrderUpload.$inject = [];

    function bulkOrderUpload () {
        return {
            restrict: 'A',
            template: '<div bulk-order-upload-plupload plupload-opts="vm.pluploadSettings" on-error="vm.onUploadError(up, args)" error-message="vm.uploadErrorMessage"></div>',
            scope: {},
            controller: BulkOrderUploadController,
            controllerAs: 'vm',
            bindToController: true
        };
    }

    BulkOrderUploadController.$inject = ['S3BulkOrderUploadPluploadSettings'];

    function BulkOrderUploadController (S3BulkOrderUploadPluploadSettings) {
        var vm = this;

        vm.uploadErrorMessage = '';
        vm.pluploadSettings = new S3BulkOrderUploadPluploadSettings();
        vm.onUploadError = onUploadError;

        return;

        function onUploadError (up, args) {
            var message = args.message;

            if (message === 'File size error.') {
                message = 'Error - exceeds max file size: ' + up.settings.max_file_size + '.';
            }

            vm.uploadErrorMessage = message;
        }
    }

    /* eslint-enable angular/directive-restrict */
    /* eslint-enable angular/component-limit */
}());
