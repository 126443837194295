function CometManager (commInfo) {
    this.comm = commInfo;
    this.commType = 'comet';
    this.OBJECTID = 'OBJECTID';
    this.OBJECTID2 = 'OBJECTID2';

    // state

    // functions
    this.newRequest = CometManager_newRequest;
}
function CometManager_newRequest (handlerUrl, theServerAction, theSuccessResultHandlerFunction, optionalSuccessHandlerFunctionParm, optionalFailureResultHandlerFunction, optionalFailureHandlerFunctionParm) {
    return new CometRequest(this, handlerUrl, theServerAction, theSuccessResultHandlerFunction, optionalSuccessHandlerFunctionParm, optionalFailureResultHandlerFunction, optionalFailureHandlerFunctionParm);
}
function CometRequest (cometManager, handlerUrl, theServerAction, theSuccessResultHandlerFunction, optionalSuccessHandlerFunctionParm, optionalFailureResultHandlerFunction, optionalFailureHandlerFunctionParm) {
    // private communication info
    this.manager = cometManager;
    this.handlerUrl = handlerUrl;
    this.url = this.manager.comm.createUrl(null, theServerAction, this.manager.commType);
    this.successResultHandlerFunction = theSuccessResultHandlerFunction;
    this.failureResultHandlerFunction = optionalFailureResultHandlerFunction;

    // additional handler info will also get passed into the handler function.
    this.successResultHandlerFunctionParameter = optionalSuccessHandlerFunctionParm;
    this.failureResultHandlerFunctionParameter = optionalFailureHandlerFunctionParm;
    this.statusDivId = null;
    this.spinner = SPINNERIMAGE;

    // communication status
    this.errorMessage = null;
    this.commSuccess = false;
    this.serverSuccess = false;
    this.httpResponseStatusCode = 0;

    // this.applicationSuccess = false;

    // results
    this.xmlResponse = null;
    this.sessionTracking = false;
    this.systemErrors = new Array();
    this.infoMessages = new Array();
    this.warningMessages = new Array();
    this.errorMessages = new Array();

    // public functions
    this.send = CometRequest_send;
    this.addParameter = CometRequest_addParameter;
    this.addObject = CometRequest_addObject;
    this.addObjectId = CometRequest_addObjectId;
    this.addObjectId2 = CometRequest_addObjectId2;
    this.addFormField = CometRequest_addFormField;
    this.addDropDown = CometRequest_addDropDown;
    this.addRadio = CometRequest_addRadio;
    this.addCheckBox = CometRequest_addCheckBox;
    this.hasUserMessages = CometRequest_hasUserMessages;
    this.hasInfoMessages = CometRequest_hasInfoMessages;
    this.hasWarningMessages = CometRequest_hasWarningMessages;
    this.hasErrorMessages = CometRequest_hasErrorMessages;
    this.hasAnyErrorMessages = CometRequest_hasAnyErrorMessages;
    this.showUserMessages = CometRequest_showUserMessages;
    this.showErrorMessagesInAlertPanel = CometRequest_showErrorMessagesInAlertPanel;
    this.getObject = CometRequest_getObject;
    this.getResultNode = CometRequest_getResultNode;
    this.getListManager = CometRequest_getListManager;
    this.getWorkingObject = CometRequest_getWorkingObject;

    // private functions
    this.private_getRootNode = CometRequest_private_getRootNode;
    this.private_setSessionTracking = CometRequest_private_setSessionTracking;
    this.private_finished = CometRequest_private_finished;
    this.private_setStatusDiv = CometRequest_private_setStatusDiv;
    this.private_getMessageArray = CometRequest_private_getMessageArray;

    // initialize
}
function CometRequest_addParameter (key, value) {
    this.url.appendParam(key, value);
}
function CometRequest_addObjectId (value) {
    this.addParameter(this.manager.OBJECTID, value);
}
function CometRequest_addObjectId2 (value) {
    this.addParameter(this.manager.OBJECTID2, value);
}
function CometRequest_addFormField (fieldName, resetField, context) {
    this.addParameter(fieldName, getFormItemValue(fieldName, context));
    if (resetField == true) {
        resetFormItemValue(fieldName);
    }
}
function CometRequest_addCheckBox (fieldName, resetField, context) {
    if (isChecked(fieldName, context)) {
        this.addParameter(fieldName, getFormItemValue(fieldName, context));
    }
    if (resetField == true) {
        // TODO: reset field
    }
}
function CometRequest_addDropDown (fieldName, resetField, context) {
    this.addParameter(fieldName, getDropDownSelectedValue(fieldName, context));
    if (resetField == true) {
        selectIndexOnDropDown(fieldName, 0);
    }
}
function CometRequest_addRadio (fieldName, context) {
    this.addParameter(fieldName, getSelectedRadioNamed(fieldName, context));
}
function CometRequest_addObject (fieldName, object) {
    var key = fieldName;
    if (key == null) {
        key = this.manager.OBJECTID;
    }
    var value = '';
    if (object != null) {
        value = $.toJSON(object);
    }
    this.addParameter(key, value);
}
function CometRequest_send () {
    this.private_setStatusDiv('<span class="preloader"><img src="' + this.spinner + '"/></span>');
    var httpRequest = null;
    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        httpRequest = new XMLHttpRequest();
        if (httpRequest.overrideMimeType) {
            httpRequest.overrideMimeType('text/xml');
        }
    } else if (window.ActiveXObject) { // IE
        try {
            httpRequest = new ActiveXObject('Msxml2.XMLHTTP');

            // httpRequest = new ActiveXObject("Microsoft.XMLHTTP");
        } catch (e) {
            try {
                httpRequest = new ActiveXObject('Microsoft.XMLHTTP');
            } catch (e) {
            }
        }
    }
    if (!httpRequest) {
        this.commSuccess = false;
        this.errorMessage = 'Cannot create an XMLHTTP object';
        return this.private_finished();
    }
    var thisInstance = this;

    // httpRequest might be an Active X object
    httpRequest.onreadystatechange = function () {
        CometRequest_private_handleResult(thisInstance, httpRequest);
    };

    // alertPanel.alert(this.serverURL + " " + this.url.getParams());
    httpRequest.open('POST', this.handlerUrl, true);
    httpRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
    var parms = this.url.getParams();
    httpRequest.send(parms);
}
function CometRequest_private_handleResult (cometRequest, httpRequest) {
    if (httpRequest.readyState == 4) {
        try {
            cometRequest.httpResponseStatusCode = httpRequest.status;
            if (httpRequest.status == 200) {
                cometRequest.xmlResponse = httpRequest.responseXML;

                // IE's first child is the xml tag
                // alertPanel.alert(httpRequest.responseXML.lastChild.nodeName);
                cometRequest.commSuccess = true;

                // check for results
                if (cometRequest.xmlResponse.childNodes.length == 0) {
                    cometRequest.commSuccess = false;
                    cometRequest.errorMessage = 'Communication error.  No results returned from server.  Please try again later.';
                } else {
                    // check for system errors
                    cometRequest.systemErrors = cometRequest.private_getMessageArray('SYSTEMERRORSNODE');
                    if (cometRequest.systemErrors.length > 0) {
                        cometRequest.serverSuccess = false;
                        var systemErrorAlert = '';
                        for (var i = 0; i < cometRequest.systemErrors.length; i++) {
                            var message = cometRequest.systemErrors[i];
                            systemErrorAlert += message;
                            systemErrorAlert += '\n\n';
                        }
                        cometRequest.errorMessage = systemErrorAlert;
                    } else {
                        cometRequest.serverSuccess = true;
                        cometRequest.private_setSessionTracking();
                    }
                }
            } else if (httpRequest.status == 0) {
                // drop for now?  possibly on page unload
                cometRequest.commSuccess = false;
                cometRequest.errorMessage = 'Request timed out. (status ' + httpRequest.status + ')';
            } else {
                cometRequest.commSuccess = false;
                cometRequest.errorMessage = 'Could not communicate with server.  Server may be down.  Please try again later. (status ' + httpRequest.status + ')';
            }
        } catch (ex) {
            cometRequest.commSuccess = false;
            cometRequest.errorMessage = 'Could not communicate with server.  Server may be down.  Please try again later.';
        }
        return cometRequest.private_finished();
    }

    // for compile error
    return null;
}
function CometRequest_private_getRootNode (rootNodeName) {
    var cometNode = this.xmlResponse.lastChild;
    var rootNodes = cometNode.childNodes;
    for (var i = 0; i < rootNodes.length; i++) {
        var rootNode = rootNodes[i];
        if (rootNode.nodeName == rootNodeName) {
            return rootNode;
        }
    }
    return null;
}
function CometRequest_private_setSessionTracking () {
    var rootNode = this.private_getRootNode('SESSIONTRACKINGNODE');
    if (rootNode != null) {
        var val = rootNode.firstChild.nodeValue;
        this.sessionTracking = val == 'true';
    }
}
function CometRequest_private_getMessageArray (messagesNodeName) {
    var rootNode = this.private_getRootNode(messagesNodeName);
    if (rootNode != null) {
        var messages = new Array();
        var messageNodes = rootNode.childNodes;
        for (var j = 0; j < messageNodes.length; j++) {
            var messageNode = messageNodes[j];
            messages[messages.length] = messageNode.firstChild.nodeValue;
        }
        return messages;
    }
    return new Array();
}
function CometRequest_private_finished () {
    this.private_setStatusDiv(null);
    if (this.errorMessage != null) {
        // alert for now.  critical error
        alertPanel.alert(this.errorMessage);
        this.private_setStatusDiv(this.errorMessage);
    }
    if (!this.commSuccess) {
        var temp = 'Communication Error: ' + this.errorMessage;
        logDebug(temp);
        this.private_setStatusDiv(temp);
    } else if (!this.serverSuccess) {
        var temp = 'System Error: ' + this.errorMessage;
        logDebug(temp);
        this.private_setStatusDiv(temp);
    } else {
        // all went well, parse objects out
        this.infoMessages = this.private_getMessageArray('INFOMESSAGESNODE');
        this.warningMessages = this.private_getMessageArray('WARNINGMESSAGESNODE');
        this.errorMessages = this.private_getMessageArray('ERRORMESSAGESNODE');
        return this.successResultHandlerFunction(this, this.successResultHandlerFunctionParameter);
    }
    if (this.failureResultHandlerFunction) {
        return this.failureResultHandlerFunction(this, this.failureResultHandlerFunctionParameter);
    }
    return null;
}
function CometRequest_private_setStatusDiv (htmlContent) {
    if (this.statusDivId != null) {
        var content = htmlContent;
        if (content == null) {
            content = '';
        }
        dynamic.setDivIdContent(this.statusDivId, content);
    }
}
function CometRequest_hasUserMessages () {
    return this.hasInfoMessages() || this.hasWarningMessages() || this.hasErrorMessages();
}
function CometRequest_hasInfoMessages () {
    if (this.infoMessages.length > 0) {
        return true;
    }
    return false;
}
function CometRequest_hasWarningMessages () {
    if (this.warningMessages.length > 0) {
        return true;
    }
    return false;
}
function CometRequest_hasErrorMessages () {
    if (this.warningMessages.length > 0) {
        return true;
    } if (this.errorMessages.length > 0) {
        return true;
    }
    return false;
}
function CometRequest_hasAnyErrorMessages () {
    if (this.errorMessages.length > 0) {
        return true;
    }
    return false;
}
function CometRequest_showUserMessages (targetDivId) {
    var html = '';
    if (this.hasUserMessages()) {
        if (this.errorMessages.length > 0) {
            html += '<div id="userMessageError"><strong>';
            for (var i = 0; i < this.errorMessages.length; i++) {
                var message = this.errorMessages[i];
                html += strWB(message + '\n');
            }
            html += '</strong></div>';
        }
        if (this.warningMessages.length > 0) {
            html += '<div id="userMessageAlert"><strong>';
            for (var i = 0; i < this.warningMessages.length; i++) {
                var message = this.errorMessages[i];
                html += strWB(message + '\n');
            }
            html += '</strong></div>';
        }
        if (this.infoMessages.length > 0) {
            html += '<div id="userMessageInfo"><strong>';
            for (var i = 0; i < this.infoMessages.length; i++) {
                var message = this.infoMessages[i];
                html += strWB(message + '\n');
            }
            html += '</strong></div>';
        }
        dynamic.setDivIdContent(targetDivId, html);
    }
}
function CometRequest_showErrorMessagesInAlertPanel (onCloseFunction) {
    if (this.hasAnyErrorMessages()) {
        var content = '';
        content += '<p>Please address the following:</p><ol>';
        for (var i = 0; i < this.errorMessages.length; i++) {
            var message = this.errorMessages[i];
            content += '<li>' + strWB(message) + '</li>';
        }
        content += '</ol>';
        alertPanel.alert(content, onCloseFunction);
    }
}
function CometRequest_getResultNode (resultObjectNodeName) {
    var rootNode = this.private_getRootNode('RESULTSNODE');
    if (rootNode != null) {
        var resultNodes = rootNode.childNodes;
        for (var j = 0; j < resultNodes.length; j++) {
            var resultNode = resultNodes[j];
            if (resultNode.nodeName == resultObjectNodeName) {
                return resultNode;
            }
        }
    }
    return null;
}
function CometRequest_getObject (resultObjectNodeName) {
    var key = resultObjectNodeName;
    if (key == null) {
        // this is JDXMLCometResult.WORKINGOBJECT
        key = 'WORKINGOBJECT';
    }
    var resultNode = this.getResultNode(key);
    if (resultNode == null) {
        return null;
    }

    // turn object node into a javascript bo.
    // assumes result node has one object node in it
    var objectNode = resultNode.firstChild;

    if (objectNode == null) {
        return null;
    }

    // this will be recursive eventually
    var bo = new CometBusinessObject(objectNode);
    var behaviorClass = cometBusinessObjectBehaviour.getWithClassName(bo.javaClassName);
    if (behaviorClass != null) {
        bo = cometBusinessObjectBehaviour.addBehavior(bo, behaviorClass.behaviourFunctions);
    }
    return bo;
}
function CometRequest_getListManager () {
    return this.getObject('LISTMANAGER');
}
function CometRequest_getWorkingObject () {
    return this.getObject('WORKINGOBJECT');
}
