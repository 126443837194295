(function () {
    'use strict';

    angular
        .module('tbg.errorReporting')
        .run(tbgWindowOnErrorRun);

    tbgWindowOnErrorRun.$inject = ['$window', '$exceptionHandler', 'tbgErrorService'];

    function tbgWindowOnErrorRun ($window, $exceptionHandler, tbgErrorService) {
        var originalHandler = $window.onerror || function mockOriginalHandler () {
            return true;
        };

        $window.onerror = function tbgHandleWindowOnError (message, file, line, col, error) {
            tbgErrorService
                .newWindowError(message, file, line, col, error)
                .then(handleError);

            return true;

            function handleError (modifiedError) {
                $exceptionHandler(modifiedError);

                try {
                    return originalHandler.apply($window, [message, file, line, col, error]);
                } catch (e) {
                    $exceptionHandler(e);
                }
            }
        };
    }
}());
