(function () {
    'use strict';

    angular
        .module('storefrontApp')
        .service('UtilService', UtilService);

    UtilService.$inject = [];

    function UtilService () {
        return {
            generateInstances: function (src, InstanceConstructor) {
                var ret = [];

                if (src && src.length) {
                    for (var i = 0; i < src.length; i++) {
                        ret.push(new InstanceConstructor(src[i]));
                    }
                }

                return ret;
            },
            unpackListResponse: function (response, listName) {
                var data = response.data ? response.data : response;
                var list = data._embedded ? data._embedded[listName] : [];
                return list;
            },
            replaceArray: function (array, contents) {
                // Work off of copy, prevents case where "array" and "contents"
                // are references to the same array, the array.splice(0)
                // would empty both before merge, with the final
                // result being an empty array.
                var contentsCopy = angular.copy(contents);

                // Empty the target array
                array.splice(0);

                // Merge the passed contents into the target array
                Array.prototype.push.apply(array, contentsCopy);

                // Even though target array was altered in place, return target array
                return array;
            }
        };
    }
}());
