var THEFORM = 'theform';
var SORTCOLUMNHF = 'sortColumnValueHF';
var SORTCOLUMNACTION = 'sortColumnAction';
var SOURCEPAGEHF = 'sourcepage';
var PAGEACTIONHF = 'pageaction';
var REQUESTTYPE = 'requesttype';

var MAXTA = 500;

if (!window.console) {
    window.console = {
        log: noop
    };

    function noop () {
    }
}

/*
* Copyright 2012 Dave Feil. All rights reserved.
*/
var IE = !!document.all;
function getKeyCode (event) {
    var code = 0;
    if (IE) {
        code = event.keyCode;
    } else {
        code = event.which;
    }
    return code;
}

/*
* return true if event is the enter key being pressed
* param event object
*/
function isEnterEvent (event) {
    var code = getKeyCode(event);
    if (code == 13 || code == 3) {
        return true;
    }
    return false;
}

/*
* return true if event is the escape key being pressed
* param event object
*/
function isEscapeEvent (event) {
    var code = getKeyCode(event);
    if (code == 27) {
        return true;
    }
    return false;
}
function stopPropagation (event) {
    event = event || window.event; // cross-browser event
    if (event.stopPropagation) {
        // W3C standard variant
        event.stopPropagation();
    } else {
        // IE variant
        event.cancelBubble = true;
    }
}

/*
* param aLocation String
*/
function openWindow (newURL, windowName, windowWidth, windowHeight, opts) {
    var defaultOpts = 'status=1,resizable=1,location=1,directories=1,menubar=1,toolbar=1,titlebar=1,scrollbars=1';
    if (opts != null) {
        defaultOpts = opts;
    }
    if (windowWidth == null || windowHeight == null) {
        return window.open(newURL, windowName, defaultOpts);
    }
    var leftLoc = (screen.width - windowWidth) / 2;
    var topLoc = (screen.height - windowHeight) / 2;
    return window.open(newURL, windowName, 'width=' + windowWidth + ',height=' + windowHeight + ',left=' + leftLoc + ',top=' + topLoc + ',' + defaultOpts);
}
function openImageWindow (newURL, windowWidth, windowHeight) {
    var opts = 'status=0,resizable=0,location=0,directories=0,menubar=0,toolbar=0,titlebar=1';
    openWindow(newURL, 'TBGIMAGEWINDOW', windowWidth + 15, windowHeight + 10, opts);
}

/*
* Navigate to aURL.  Instead of submitting a form this method might be more appropriate.
* Push button that navigates to another handler without any actions for example.
*
* param aURL String - URL to navigate to.
*/
function gotoURL (aURL) {
    location = aURL;

    // NOTE Avoid using replace method of navigation because of Safari
    // location.replace(aURL);
}

/*
* scroll to an anchor
*
* param anchorName String
*/
function goToAnchor (anchorName) {
    setTimeout("goToAnchorPrivate('" + anchorName + "')", 100);
}
function goToAnchorPrivate (anchorName) {
    return document.location.hash = anchorName;
}

/*
* browser sniffer
*/
function isMac () {
    var agt = navigator.userAgent.toLowerCase();
    return agt.indexOf('mac') != -1;
}
function isMacSafari () {
    var agt = navigator.userAgent.toLowerCase();
    return isMac() && (agt.indexOf('safari') != -1);
}
function isMacIE () {
    var appVer = navigator.appVersion.toLowerCase();
    return isMac() && (appVer.indexOf('msie') != -1);
}

// Check version of IE
function isIELessThan (lessThanVer) {
    if (navigator.appName == 'Microsoft Internet Explorer') {
        var rv = lessThanVer;
        var ua = navigator.userAgent;
        var re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
        if (re.exec(ua) != null) {
            rv = parseFloat(RegExp.$1);
        }

        if (rv < lessThanVer) {
            return true;
        }
    }
    return false;
}

/*
* Copyright 2012 Dave Feil. All rights reserved.
*/

function mm () {
    window.status = '';
    return true;
}

/*
* Get the object reference to the given div id.
*
* param divId
*
*/
function getDiv (divId) {
    // this seems to work for now
    return document.getElementById(divId);
}

/*
* Copyright 2012 Dave Feil. All rights reserved.
*/
/* ___________________________________________________________________________
* Form and Form Items
*/

/*
* return the form object reference
*/
function getForm () {
    return eval('document.' + THEFORM);
}

/*
* return the form item object reference with given name
*
* param formItemName String name of the object to get
*/
function getFormItem (formItemName, context) {
    if (!context) {
        context = getForm();
    }
    return getFormItemOfParent(formItemName, context);
}
function getFormItemOfParent (formItemName, parent) {
    var nodes = $("input[name='" + formItemName + "'], select[name='" + formItemName + "'], textarea[name='" + formItemName + "'], button[name='" + formItemName + "']", parent);
    if (nodes.length == 0) {
        return null;
    }
    if (nodes.length == 1) {
        return nodes[0];
    }
    return nodes;
}

/*
* return the "value" property of the form item object reference with given name
*
* param formItemName String name of the object to get
*/
function getFormItemValue (formItemName, context) {
    var formitem = getFormItem(formItemName, context);

    if (formitem === null) {
        return null;
    }

    if (intlTelInputGlobals) {
        for (var instance in intlTelInputGlobals.instances) {
            if (intlTelInputGlobals.instances.hasOwnProperty(instance)) {
                if (formitem === intlTelInputGlobals.instances[instance].telInput) {
                    return intlTelInputGlobals.instances[instance].getNumber();
                }
            }
        }
    }

    return formitem.value;
}

/*
* return boolean
*
* param formItemName String name of the object to get
*/
function isChecked (formItemName, context) {
    var formitem = getFormItem(formItemName, context);
    return formitem == null ? false : formitem.checked;
}

/*
* param formItemName String name of the checkbox object to set checked=false
*/
function setChecked (formItemName, abool, context) {
    var formitem = getFormItem(formItemName, context);
    if (formitem != null) {
        formitem.checked = abool;
    }
}

/*
* return the "value" property of the form item object reference with given name
*
* param formItemName String name of the object to get
*/
function resetFormItemValue (formItemName, context) {
    setFieldValue(formItemName, '', context);
}

/*
* return the value of the selected radio button.  null if none selected
*
* param radioButtonsName String name of the radio button set
*/
function getSelectedRadioNamed (radioButtonsName, context) {
    return getSelectedRadioValue(getFormItem(radioButtonsName, context));
}

/*
* return the value of the selected radio button.  null if none selected
*
* param formItem RadioButton form object
*/
function getSelectedRadioValue (formItem) {
    if (formItem == null || formItem.length == 0) {
        return '';
    }
    if (!formItem.length && formItem.checked) {
        return formItem.value;
    }
    for (var i = 0; i < formItem.length; i++) {
        var currentItem = formItem[i];
        if (currentItem.checked) {
            return currentItem.value;
        }
    }
    return null;
}

/**
 * return the value of the checkbox if it is checked otherwise null.
 */
function getSelectedCheckboxNamed (formItemName, context) {
    if (isChecked(formItemName, context)) {
        return getFormItemValue(formItemName, context);
    }
    return null;
}

/*
* Set the value of a hidden field
*
* param fieldName String - name of the hidden field form object
* param newValue String - value to set
*/
function setHiddenFieldValue (fieldName, newValue, context) {
    var hiddenField = getFormItem(fieldName, context);
    if (hiddenField == null) {
        addHiddenField(fieldName, newValue, context);
    } else {
        hiddenField.value = newValue;
    }
}

/*
* Set the value of a  field
*
* param fieldName String - name of the  field form object
* param newValue String - value to set
*/
function setFieldValue (fieldName, newValue, context) {
    var field = getFormItem(fieldName, context);
    if (field != null) {
        field.value = newValue;
    }
}
function addHiddenField (fieldName, fieldValue, context) {
    if (!context) {
        context = getForm();
    }
    $(context).append('<input type="hidden" name="' + fieldName + '" value="' + fieldValue + '" />');
}
function removeField (fieldName, context) {
    if (!context) {
        context = getForm();
    }
    $(getFormItemOfParent(fieldName, context)).remove();
}

/*
* Submit the form after setting the action to pageAction.  Submits the form to the default page handler.
*
* param pageAction String - name of the action defined by Java Page Handler for this page
* param targetWindow String - name of the window the form should show it's results
*/
function submitForm (pageAction, targetWindow) {
    return private_submitForm(pageAction, targetWindow, null);
}

/*
* Submit the form after setting the action to pageAction to the specified page handler class.
*
* param pageAction String - name of the action defined by Java Page Handler for this page
* param targetWindow String - name of the window the form should show it's results
*/
function submitFormToHandler (handlerUrl, pageAction) {
    return private_submitForm(pageAction, null, handlerUrl);
}

/*
* Submit the form after setting the action to pageAction.  Submits the form to the target page handler.
*
* param pageAction String - name of the action defined by Java Page Handler for this page
* param targetWindow String - name of the window the form should show it's results
*/
function private_submitForm (pageAction, targetWindow, handlerUrl) {
    var theform = getForm();
    if (handlerUrl != null) {
        theform.action = handlerUrl;
    } else {
        theform.action = formAction;
    }
    setHiddenFieldValue(PAGEACTIONHF, pageAction, theform);
    if (targetWindow != null && targetWindow != '') {
        getForm().target = targetWindow;
    } else {
        getForm().target = '_self';
    }
    getForm().submit();
    return false;
}

/*
* Download a PDF into a new window or for mac in same window (Which will popup the preview app to show the pdf)
*
* param pageHandlerClass String - Name of the JAVA page handler class to handle this request.
* param pageAction String - name of the action defined by Java Page Handler for this page
*/
function downloadPDF (pageAction, seperateWindowIndicatorHF) {
    // if (isMacSafari() || isMacIE()) {
    if (isMac() && !isMacSafari()) {
        submitForm(pageAction);
    } else {
        // let server know if request will be returning on a seperate window  (for user messages)
        if (seperateWindowIndicatorHF != null) {
            setHiddenFieldValue(seperateWindowIndicatorHF, 'true');
        }
        submitForm(pageAction, 'PDFWINDOW');
    }
}

/*
* Select All radio buttons with the given prefix and ending in the id in listIds.
* Check box name is made up of prefix + id where id is each id in the array.
* globalAllSelected stores the state of what was last selected
*
* param prefix String - prefix of the check box name
* param listIds Id Array - ids of each row.  (ending part of the name of the check box)
*/
var globalAllSelected = false;
function selectAllCheckbox (prefix, listIds, context) {
    // var form = getForm();
    globalAllSelected = !globalAllSelected;
    for (var i = 0; i < listIds.length; i++) {
        setChecked(prefix + listIds[i], globalAllSelected, context);
    }
}

/*
* select a specific optionValue on the given dropDown object.
*
* param dropDownName String - name of the select drop down object
* param optionValue any - the value that is compared to each option in the drop down.
*/
function selectOptionOnDropDown (dropDownName, optionValue, context) {
    var stringValue = '' + optionValue;
    var dropDown = getFormItem(dropDownName, context);
    if (dropDown != null) {
        var ddOptions = dropDown.options;
        for (var i = 0; i < ddOptions.length; i++) {
            var anOption = ddOptions[i];
            if (anOption.value == stringValue) {
                anOption.selected = true;
                return;
            }
        }
    }
}

/*
* select a specific optionValue on the given dropDown object.
*
* param dropDownName String - name of the select drop down object
* param optionValue any - the value that is compared to each option in the drop down.
*/
function selectIndexOnDropDown (dropDownName, index, context) {
    var dropDown = getFormItem(dropDownName, context);
    if (dropDown != null) {
        var ddOptions = dropDown.options;
        var anOption = ddOptions[index];
        anOption.selected = true;
    }
}

/*
* select an item that is similar to optionValue on the given dropDown object.
*
* param dropDownName String - name of the select drop down object
* param optionValue any - the value that is compared to each option in the drop down.
*/
function selectLikeNameOnDropDown (dropDownName, optionValue, context) {
    var dropDown = getFormItem(dropDownName, context);
    if (dropDown != null) {
        var ddOptions = dropDown.options;
        for (var i = 0; i < ddOptions.length; i++) {
            var anOption = ddOptions[i];
            if (anOption.text.toLowerCase().indexOf(optionValue.toLowerCase()) != -1) {
                anOption.selected = true;
                return;
            }
        }
    }
}

/*
* param dropDownName String - name of the select drop down object
* param optionValue any - the value that is compared to each option in the drop down.
*/
function getDropDownSelectedText (dropDownName, context) {
    var dropDown = getFormItem(dropDownName, context);
    if (dropDown != null && dropDown.selectedIndex != null) {
        return dropDown.options[dropDown.selectedIndex].text;
    }
    return null;
}

/*
*
*
* param dropDownName String - name of the select drop down object
* param optionValue any - the value that is compared to each option in the drop down.
*/
function getDropDownSelectedValue (dropDownName, context) {
    var dropDown = getFormItem(dropDownName, context);
    if (dropDown != null && dropDown.selectedIndex != null) {
        var selected = dropDown.options[dropDown.selectedIndex];
        if (selected) {
            return selected.value;
        }
    }
    return null;
}

/*
* select a matching value in a drop down based on a value in an entry field.
*
* param dropDownName String - name of the select drop down object
* param entryFieldName String - name of the entry field search field
*/
function dropDownSearchChanged (dropDownName, entryFieldName, context) {
    var searchFieldEF = getFormItem(entryFieldName, context);
    if (searchFieldEF != null) {
        selectLikeNameOnDropDown(dropDownName, searchFieldEF.value);
    }
}

/*
* move selected items from one multiselect to another.
*
* param sourceMultiName String - name of the source multi select
* param destinationMultiName String - name of the destination
* param hiddenFieldName String - name of the hidden field that will hold the id's of items in destination
*/
function moveChooserItems (sourceMultiName, destinationMultiName, isMoveRight, hiddenFieldName, context) {
    var sourceMS = getFormItem(sourceMultiName, context);
    var destMS = getFormItem(destinationMultiName, context);
    var sourceOptions = sourceMS.options;
    var destOptions = destMS.options;
    var newSource = new Array();
    var newDest = new Array();

    // var debug = "";
    for (var i = 0; i < destOptions.length; i++) {
        var destOption = destOptions[i];
        newDest[newDest.length] = new Option(destOption.text, destOption.value);
    }
    for (var i = 0; i < sourceOptions.length; i++) {
        var sourceOption = sourceOptions[i];
        var newSourceOption = new Option(sourceOption.text, sourceOption.value);
        if (sourceOption.selected) {
            newSourceOption.selected = true;
            newDest[newDest.length] = newSourceOption;
        } else {
            newSource[newSource.length] = newSourceOption;
        }
    }

    // store destination id's in the hidden field if it exists.
    var hiddenFieldValue = '';
    if (isMoveRight) {
        for (var i = 0; i < newDest.length; i++) {
            var newOption = newDest[i];
            if (i > 0) {
                hiddenFieldValue += ',';
            }
            hiddenFieldValue += newOption.value;
        }
    } else {
        for (var i = 0; i < newSource.length; i++) {
            var newOption = newSource[i];
            if (i > 0) {
                hiddenFieldValue += ',';
            }
            hiddenFieldValue += newOption.value;
        }
    }
    setFieldValue(hiddenFieldName, hiddenFieldValue, context);

    // alert(hiddenFieldValue);
    sourceMS.length = 0;
    destMS.length = 0;
    for (var i = 0; i < newSource.length; i++) {
        var newOption = newSource[i];
        sourceMS.options[i] = newOption;
    }
    for (var i = 0; i < newDest.length; i++) {
        var newOption = newDest[i];
        destMS.options[i] = newOption;
    }
}

/*
* sort the list on the given columName.  Does a submit
*
* param columnName String - name of the instance variable name (column) on the listed item to sort on
*/
function sortColumn (columnName) {
    setHiddenFieldValue(SORTCOLUMNHF, columnName);
    submitForm(SORTCOLUMNACTION);
}

/*
* Set focus to the form object with given name
*
* param formObjectName String - name of the form object
*/
function focusOn (formObjectName, context) {
    var formItem = getFormItem(formObjectName, context);
    if (formItem != null) {
        try {
            formItem.focus();
        } catch (e) {
        }
    }
}
function selectAll (formObjectName, context) {
    var formItem = getFormItem(formObjectName, context);
    if (formItem != null) {
        try {
            formItem.select();
        } catch (e) {
        }
    }
}

/*
* control the length of text in a text area form object.
* if it gets too long an alert can be shown
*
* param taName String - name of the text area form object
* param maxChars int - number of chars allowed or null
* param countEFName String - name of the object to set with the count
*/
function taChanged (taName, maxChars, countEFName, context) {
    var ta = getFormItem(taName, context);
    if (ta == null) {
        return;
    }
    var count = ta.value.length;
    if (maxChars != null && maxChars > 0) {
        if (count > maxChars) {
            alertPanel.alert('Max characters limit reached');
            ta.value = ta.value.substring(0, maxChars);
            count--;
        }
    }
    if (countEFName != null) {
        var countEF = getFormItem(countEFName, context);
        if (countEF != null) {
            countEF.value = count;
        }
    }
}
function enableFormItem (formObjectName, isEnable, context) {
    var formItem = getFormItem(formObjectName, context);
    if (formItem != null) {
        if (isEnable == null) {
            formItem.disabled = false;
        } else {
            formItem.disabled = !isEnable;
        }
    }
}
function blurFormItem (formObjectName, context) {
    var formItem = getFormItem(formObjectName, context);
    if (formItem != null) {
        formItem.blur();
    }
}
function disableFormItem (formObjectName, context) {
    enableFormItem(formObjectName, false, context);
}
function enableFormItems (formObjectNameArray, context) {
    for (var i = 0; i < formObjectNameArray.length; i++) {
        enableFormItem(formObjectNameArray[i], false, context);
    }
}
function disableFormItems (formObjectNameArray, context) {
    for (var i = 0; i < formObjectNameArray.length; i++) {
        disableFormItem(formObjectNameArray[i], context);
    }
}

// in IE8, rb.onChange does not fire until the input looses focus, so fire this method onClick.
// e.g. onclick="rbChange(this)"
function rbChange (rbFormItem) {
    if (rbFormItem) {
        rbFormItem.blur();
        rbFormItem.focus();
    }
}

/*
* Copyright 2012 Dave Feil. All rights reserved.
*/
/* ___________________________________________________________________________
* Image Rollover Functions
*/
function RolloverImage (imageSrc, overImageSrc) {
    this.image = new Image();
    this.image.src = imageSrc;
    this.overImage = new Image();
    this.overImage.src = overImageSrc;

    // methods
    this.rollOverAction = RolloverImage_rollOverAction;
    this.rollOutAction = RolloverImage_rollOutAction;
}
function RolloverImage_rollOverAction (imageObj) {
    imageObj.src = this.overImage.src;
}
function RolloverImage_rollOutAction (imageObj) {
    imageObj.src = this.image.src;
}

/*
* JDImage Object.  Stores dimension info of viewer images
*
*
*/
function JDImage (newWidth, newHeight, newId, newUrl, newTitle) {
    this.id = newId;
    this.name;
    this.width = newWidth;
    this.height = newHeight;
    this.url = newUrl;
    this.title = newTitle;
}

function toInt (someVal) {
    return parseInt(someVal);
}
function toFloat (someVal) {
    return parseFloat(someVal);
}
function numberWithCommas (x) {
    // http://stackoverflow.com/a/2901298
    var parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}
function getAntiCache () {
    var date = new Date();
    return '' + formatDate(date, 'HHmmss') + date.getMilliseconds();
}
function getRandomNumber () {
    return Math.floor(Math.random() * 100000000000);
}
function countDecimalPlaces (num) {
    if (isNaN(num)) {
        return 0;
    }
    var testVal = '' + num;
    var dot = testVal.indexOf('.');
    if (dot == -1) {
        return 0;
    }
    var decimal = testVal.substring(dot);
    if (decimal.length == 1) {
        return 0;
    }
    decimal = decimal.substring(1);
    return decimal.length;
}

function roundFloat (val, decimalPlaces) {
    if (val == null || isNaN(val)) {
        return 0;
    }
    var places = 0;
    if (decimalPlaces != null) {
        places = Math.min(7, decimalPlaces);
    }
    var power = Math.pow(10, places);
    var result = val * power;
    result = Math.round(result);
    result = result / power;
    return result;
}

// ------------------------------------------------------------------
// These functions use the same 'format' strings as the
// java.text.SimpleDateFormat class, with minor exceptions.
// The format string consists of the following abbreviations:
//
// Field        | Full Form          | Short Form
// -------------+--------------------+-----------------------
// Year         | yyyy (4 digits)    | yy (2 digits), y (2 or 4 digits)
// Month        | MMM (name or abbr.)| MM (2 digits), M (1 or 2 digits)
//              | NNN (abbr.)        |
// Day of Month | dd (2 digits)      | d (1 or 2 digits)
// Day of Week  | EE (name)          | E (abbr)
// Hour (1-12)  | hh (2 digits)      | h (1 or 2 digits)
// Hour (0-23)  | HH (2 digits)      | H (1 or 2 digits)
// Hour (0-11)  | KK (2 digits)      | K (1 or 2 digits)
// Hour (1-24)  | kk (2 digits)      | k (1 or 2 digits)
// Minute       | mm (2 digits)      | m (1 or 2 digits)
// Second       | ss (2 digits)      | s (1 or 2 digits)
// AM/PM        | a                  |
//
// NOTE THE DIFFERENCE BETWEEN MM and mm! Month=MM, not mm!
// Examples:
//  "MMM d, y" matches: January 01, 2000
//                      Dec 1, 1900
//                      Nov 20, 00
//  "M/d/yy"   matches: 01/20/00
//                      9/2/00
//  "MMM dd, yyyy hh:mm:ssa" matches: "January 01, 2000 12:30:45AM"
// ------------------------------------------------------------------

var MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
var DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
function LZ (x) {
    return (x < 0 || x > 9 ? '' : '0') + x;
}

// ------------------------------------------------------------------
// formatDate (date_object, format)
// Returns a date in the output format specified.
// The format string uses the same abbreviations as in getDateFromFormat()
// ------------------------------------------------------------------
function formatJacksonDate (date, format) {
    var dateObject = new Date(date);
    return formatDate(dateObject, format);
}

function formatDate (date, format) {
    format = format + '';
    var result = '';
    var i_format = 0;
    var c = '';
    var token = '';
    var y = date.getYear() + '';
    var M = date.getMonth() + 1;
    var d = date.getDate();
    var E = date.getDay();
    var H = date.getHours();
    var m = date.getMinutes();
    var s = date.getSeconds();

    // var yyyy,yy,MMM,MM,dd,hh,h,mm,ss,ampm,HH,H,KK,K,kk,k;
    // Convert real date parts into formatted versions
    var value = {};
    if (y.length < 4) {
        y = '' + (y - 0 + 1900);
    }
    value.y = '' + y;
    value.yyyy = y;
    value.yy = y.substring(2, 4);
    value.M = M;
    value.MM = LZ(M);
    value.MMM = MONTH_NAMES[M - 1];
    value.NNN = MONTH_NAMES[M + 11];
    value.d = d;
    value.dd = LZ(d);
    value.E = DAY_NAMES[E + 7];
    value.EE = DAY_NAMES[E];
    value.H = H;
    value.HH = LZ(H);
    if (H == 0) {
        value.h = 12;
    } else if (H > 12) {
        value.h = H - 12;
    } else {
        value.h = H;
    }
    value.hh = LZ(value.h);
    if (H > 11) {
        value.K = H - 12;
    } else {
        value.K = H;
    }
    value.k = H + 1;
    value.KK = LZ(value.K);
    value.kk = LZ(value.k);
    if (H > 11) {
        value.a = 'PM';
    } else {
        value.a = 'AM';
    }
    value.m = m;
    value.mm = LZ(m);
    value.s = s;
    value.ss = LZ(s);
    while (i_format < format.length) {
        c = format.charAt(i_format);
        token = '';
        while ((format.charAt(i_format) == c) && (i_format < format.length)) {
            token += format.charAt(i_format++);
        }
        if (value[token] != null) {
            result = result + value[token];
        } else {
            result = result + token;
        }
    }
    return result;
}

/*
 * get parameter values from url query string
 */
function getUrlParms () {
    var parms = [];
    var kvPair;
    var kvPairs = window.location.search.substr(1).split('&');
    for (var i = 0; i < kvPairs.length; i++) {
        kvPair = kvPairs[i].split('=');
        parms.push(kvPair[0]);
        parms[kvPair[0]] = kvPair[1];
    }
    return parms;
}
function getUrlParm (name) {
    return getUrlParms()[name] || null;
}
function getDecodedUrlParm (name) {
    var urlParam = getUrlParm(name);
    return urlParam == null ? null : decodeURIComponent(urlParam);
}

/*
 * get parameters from url hash string
 */
function getUrlHashParms () {
    var parms = [];
    var kvPair;
    var kvPairs = window.location.hash.substr(1).split('&');
    for (var i = 0; i < kvPairs.length; i++) {
        kvPair = kvPairs[i].split('=');
        parms.push(kvPair[0]);
        parms[kvPair[0]] = kvPair[1];
    }
    return parms;
}
function getUrlHashParm (name) {
    return getUrlHashParms()[name];
}
function setUrlHashParms (urlHashParms) {
    window.location.hash = urlHashParms;
}

/*
 * Throttle function by Marcus Ekwall.
 * Only run a function once every 'delay' milliseconds (100 by default)
 * example:  $(window).scroll(throttle(controller.onScroll));
 */
function throttle (fn, delay) {
    delay || (delay = 100);
    var throttle = false;
    return function () {
        if (throttle) {
            return;
        }
        throttle = setTimeout(function () {
            throttle = false;
        }, delay);
        fn.apply(this, arguments);
    };
}

/*
 *Calls a function in delay seconds
 */
function delay (callback, ms) {
    setTimeout(callback, ms);
}

/*
 * add startsWith() and endsWith() to String prototype
 * nod to CMS at http://stackoverflow.com/questions/646628/javascript-startswith
 */
if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
    };
}
if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function (str) {
        return this.slice(-str.length) == str;
    };
}

/*
 * Allow for printing of any div on the screen.   The only styles applied (for now) are global.css
 */
function printDiv (divId, title) {
    // include global.css for basic styling
    var printDivCSS = '<link href="/web3/build/storefront/css/global.css" rel="stylesheet" type="text/css"><link href="/web3/build/storefront/css/print.css" media="print" rel="stylesheet" type="text/css">';
    var elem = document.getElementById('print_frame');
    var doc = elem.contentDocument;
    if (doc == null) { // some browsers may not have elem.contentDocument, so get the document off the contentWindow
        doc = elem.contentWindow.document;
    }

    // optionally specify a doc title.  In some browsers (firefox) this will be included in the top margin area.
    if (title) {
        doc.title = title;
    }
    doc.body.innerHTML = printDivCSS + document.getElementById(divId).innerHTML;
    elem.contentWindow.focus();

    // this delay allows for the stylesheets to load without being interrupted by the print dialog
    setTimeout(function () {
        elem.contentWindow.print();
    }, 300);
}

function debounce (func, wait, immediate) {
    var timeout = null;
    return function () {
        var context = this;
        var args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        }, wait);
        if (immediate && !timeout) {
            func.apply(context, args);
        }
    };
}

/*
 * return the string with "s" appended if (count arg) is != 1, else return a copy of string.
 * examples:
 *   "Pie".plural(0) returns "Pies"
 *   "Pie".plural(1) returns "Pie"
 *   "Pie".plural(2) returns "Pies"
 */
if (typeof String.prototype.plural != 'function') {
    String.prototype.plural = function (count) {
        if (count != 1) {
            return this + 's';
        }
        return '' + this;
    };
}

/*
 * convert byte count to human readable string
 * http://stackoverflow.com/q/10420352
 */
function getReadableByteSizeString (byteSize, withCommas) {
    if (byteSize == 0) {
        return '0 B';
    }
    var i = -1;
    var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
    do {
        byteSize = byteSize / 1024;
        i++;
    } while (byteSize > 1024);

    var factoredByteSize = Math.max(byteSize, 0.1).toFixed(1);
    if (withCommas) {
        return numberWithCommas(factoredByteSize) + byteUnits[i];
    }
    return factoredByteSize + byteUnits[i];
}

/*
 * shim for adapting old jsp form submit pages to the new scenario where THEFORM does not encompass the entire html body.
 * pass in a list of field names.
 */
function copyFormElementsToTHEFORM (context, args) {
    var theForm = getForm();
    for (var i in args) {
        removeField(args[i], theForm);
        var formItem = getFormItem(args[i], context);
        if (formItem != null) {
            var hiddenFormItemName = args[i];
            var hiddenFormItemValue = null;
            if (formItem.type === 'checkbox') {
                hiddenFormItemValue = isChecked(args[i], context);
                if (hiddenFormItemValue === true) {
                    hiddenFormItemValue = 'true';
                } else {
                    hiddenFormItemValue = '';
                }
            } else {
                hiddenFormItemValue = getFormItemValue(args[i], context);
            }
            setHiddenFieldValue(hiddenFormItemName, hiddenFormItemValue, theForm);
        }
    }
}

/**
 * Created for top level metadata objects. They are stored as {"0":{"name":"ABC"},"1":{"name":"EFG"}}. This converts to {"list":[{"name":"ABC"},{"name":"EFG"}]}
 */
function convertToSortedArray (jsonObject) {
    var keys = [];
    if (jsonObject) {
        for (var k in jsonObject) {
            if (jsonObject.hasOwnProperty(k)) {
                keys.push(k);
            }
        }
    }
    keys.sort();

    var len = keys.length;

    var sortedValues = {
        list: []
    };

    for (var i = 0; i < len; i++) {
        k = keys[i];
        sortedValues.list.push(jsonObject[k]);
    }

    return sortedValues;
}

/**
 * select all the text in the form item with the provided name/id
 * then copy the selection to the clipboard
 * optionally deselect
 */
function copyEFToClipboard (efId, deselectAfterCopy) {
    var ef = getFormItem(efId);
    if (ef && ef.select) {
        ef.select();
        if (document.queryCommandSupported('copy')) {
            document.execCommand('copy');
        }
        if (deselectAfterCopy && ef.blur) {
            ef.blur();
        }
    }
}

/**
 * Created for dropdown object values. They are stored as [{""name"":""EFG"",""id"":""EFG""},{""name"":""ABC"",""id"":""ABC""}]. This converts to [{""name"":""ABC"",""id"":""ABC""},{""name"":""EFG"",""id"":""EFG""}]
 */
function sortDropDownArray (dropDownValues) {
    dropDownValues.sort(function (a, b) {
        if (a.name == '') {
            return 1;
        }
        if (b.name == '') {
            return -1;
        }
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
        }
        if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
        }
        return 0;
    });
    return dropDownValues;
}

/**
 *  Function to convert cst time zone to a specified time zone.
 */
function convertToLocalTimeZone (cstDate) {
    var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return new Date(cstDate.toLocaleString('en-US', { timeZone: timeZone }));
}

