var TBGBOAbstractOrderItemBehavior = {
    getOrderedQuantity: function () {
        var pjc = this.orderItemPrintJobClassification;
        if (pjc != null) {
            return pjc.qty;
        }
        var lpjc = this.orderItemLargeFormatPrintJobClassification;
        if (lpjc != null) {
            return lpjc.quantity;
        }
        if (this.stockQuantity != null && this.stockQuantity > 0) {
            return this.stockQuantity;
        }
        return null;
    },
    getAbstractVariant: function () {
        if (this.stockSystemOfferingVariant != null) {
            return this.stockSystemOfferingVariant;
        }
        if (this.stockSiteOfferingVariant != null) {
            return this.stockSiteOfferingVariant;
        }
        return null;
    },
    getOffering: function () {
        if (this.systemOfferingSiteShare != null) {
            return this.systemOfferingSiteShare;
        }
        if (this.siteOffering != null) {
            return this.siteOffering;
        }
        return null;
    },
    getOfferingPrefixedId: function () {
        if (this.systemOfferingSiteShare != null) {
            return this.systemOfferingSiteShare.getPrefixedId(this.stockSystemOfferingVariant.id);
        }
        if (this.siteOffering != null) {
            return this.siteOffering.getPrefixedId(this.stockSiteOfferingVariant.id);
        }
        return null;
    },
    isServiceTypePOD: function () {
        return this.offeringServiceType.name == 'POD';
    },
    isServiceTypePODTemplated: function () {
        return this.isServiceTypePOD() && this.getOffering().isPublishedPodArtworkFromTemplate();
    },
    isServiceTypePODStored: function () {
        return this.isServiceTypePOD() && this.getOffering().isPublishedPodArtworkFromStorage();
    },
    isServiceTypeStock: function () {
        return this.offeringServiceType.name == 'STOCK';
    },
    isServiceTypeStockWithVariant: function () {
        if (this.isServiceTypeStock()) {
            var offering = null;
            if (this.systemOfferingSiteShare != null && this.systemOfferingSiteShare.systemOffering != null) {
                offering = this.systemOfferingSiteShare.systemOffering;
            }
            if (this.siteOffering != null) {
                offering = this.siteOffering;
            }
            if (offering != null && (hasValue(offering.stockAttributeTitle1) || hasValue(offering.stockAttributeTitle2) || hasValue(offering.stockAttributeTitle3))) {
                return true;
            }
        }
        return false;
    },
    getStockVariantChoiceNames: function () {
        var content = '';
        if (this.isServiceTypeStockWithVariant() && isObject(this.stockVariantChoices)) {
            for (var propName in this.stockVariantChoices) {
                if (hasValue(content)) {
                    content += ', ';
                }
                content += this.stockVariantChoices[propName];
            }
        }
        return content;
    },
    requiresArtworkFromUser: function () {
        if (this.isServiceTypePOD()) {
            if (this.systemOfferingSiteShare != null && this.systemOfferingSiteShare.isPublishedPodArtworkFromUser()) {
                return true;
            }
        }
        return false;
    },
    hasAnyPrintFile: function () {
        if (this.consumerPrintFiles != null && this.consumerPrintFiles.length > 0) {
            return true;
        }
        return false;
    },
    hasActivePrintFile: function () {
        if (this.hasAnyPrintFile()) {
            for (var i = 0, ni = this.consumerPrintFiles.length; i < ni; i++) {
                if (this.consumerPrintFiles[i].active == true) {
                    return true;
                }
            }
        }
        return false;
    },
    hasActiveProofFile: function () {
        if (this.consumerProofFiles != null && this.consumerProofFiles.length > 0) {
            for (var i = 0, ni = this.consumerProofFiles.length; i < ni; i++) {
                if (this.consumerProofFiles[i].active == true) {
                    return true;
                }
            }
        }
        return false;
    },
    hasProofReadyForReview: function () {
        var proof = this.proof;
        if (proof != null) {
            var pstatus = this.proofStatus;
            if (pstatus != null && pstatus.name == 'Pending Review') {
                return true;
            }
        }
        return false;
    },
    isProofApproved: function () {
        if (this.proofStatus != null && this.proofStatus.name == 'Approved') {
            return true;
        }
        return false;
    },
    isProofDeclined: function () {
        if (this.proofStatus != null && this.proofStatus.name == 'Declined') {
            return true;
        }
        return false;
    },
    isPrinted: function () {
        if (this.productionStatus != null && this.productionStatus.name == 'Printed') {
            return true;
        }
        return false;
    },
    isFinished: function () {
        if (this.productionStatus != null && this.productionStatus.name == 'Finished') {
            return true;
        }
        return false;
    },
    getFirstShipmentServiceName: function () {
        var shipment = this.getFirstShipment();
        if (shipment == null || shipment.shippingService == null) {
            return null;
        }
        return shipment.shippingService.shippingServiceName;
    },
    getFirstShipment: function () {
        var shipments = this.shipments;
        if (shipments == null || shipments.length == 0) {
            return null;
        }
        var shipment = shipments[0];
        return shipment;
    },
    getOfferingFulfillmentCenter: function () {
        if (this.systemOfferingSiteShare != null && this.systemOfferingSiteShare.systemOffering != null) {
            return this.systemOfferingSiteShare.systemOffering.fulfillmentCenter;
        }
        if (this.siteOffering != null) {
            return this.siteOffering.fulfillmentCenter;
        }
        return null;
    },
    getJobOrProductName: function () {
        if (hasValue(this.jobName)) {
            return this.jobName;
        }
        return this.getOffering().name;
    },
    hasUserChoices: function () {
        var abstractPjc = this.getOIAbstractPJC();
        if (abstractPjc != null && abstractPjc.cometUserChoices != null) {
            return true;
        }
        return false;
    },
    hasVersions: function () {
        var abstractPjc = this.getOIAbstractPJC();
        if (abstractPjc != null && abstractPjc.versions != null && abstractPjc.versions.length > 0) {
            return true;
        }
        return false;
    },
    getFirstVersionQuantity: function () {
        if (this.hasVersions()) {
            var versions = this.getOIAbstractPJC().versions;
            return versions[0].quantity;
        }
        return undefined;
    },
    getOIAbstractPJC: function () {
        if (this.orderItemPrintJobClassification != null) {
            return this.orderItemPrintJobClassification;
        }
        if (this.orderItemLargeFormatPrintJobClassification != null) {
            return this.orderItemLargeFormatPrintJobClassification;
        }
        return null;
    },
    getChildItemFromListWithId: function (childItems, id) {
        for (var i = 0, ni = childItems ? childItems.length : 0; i < ni; i++) {
            var citem = childItems[i];
            if (citem.id == id) {
                return citem;
            }
        }
        return null;
    },
    getChildItemWithId: function (id) {
        var childItem = this.getChildItemFromListWithId(this.proofProductionRuns, id);
        if (childItem != null) {
            return childItem;
        }
        childItem = this.getChildItemFromListWithId(this.reworkProductionRuns, id);
        if (childItem != null) {
            return childItem;
        }
        childItem = this.getChildItemFromListWithId(this.internalReworkProductionRuns, id);
        if (childItem != null) {
            return childItem;
        }
        return null;
    },
    isTypeProofProductionRun: function () {
        return this.orderItemType && this.orderItemType.id == 'PR_PROOF';
    },
    isTypeReworkProductionRun: function () {
        return this.orderItemType && this.orderItemType.id == 'PR_REWORK';
    },
    isTypeInternalReworkProductionRun: function () {
        return this.orderItemType && this.orderItemType.id == 'PR_INTERNAL_REWORK';
    },
    isTypeOrderItem: function () {
        return this.orderItemType && this.orderItemType.id == 'OI';
    },
    isTypeProductionRun: function () {
        return !this.isTypeOrderItem();
    },
    getTypeAndJobNumberLabel: function () {
        var content = '';
        if (this.isTypeOrderItem()) {
            content += 'Order Item';
        } else if (this.isTypeProofProductionRun()) {
            content += 'Proof Production Run';
        } else if (this.isTypeReworkProductionRun()) {
            content += 'Customer Rework Production Run';
        } else if (this.isTypeInternalReworkProductionRun()) {
            content += 'Internal Rework Production Run';
        }
        content += ' #' + this.jobNumberDisplay;
        return content;
    },
    getShortTypeAndJobNumberLabel: function () {
        var content = '';
        if (this.isTypeOrderItem()) {
            content += 'Order Item';
        } else if (this.isTypeProofProductionRun()) {
            content += 'Hard Proof';
        } else if (this.isTypeReworkProductionRun()) {
            content += 'Customer Rework';
        } else if (this.isTypeInternalReworkProductionRun()) {
            content += 'Internal Rework';
        }
        content += ' #' + this.jobNumberDisplay;
        return content;
    },
    getOrderItemTypeLabel: function () {
        var content = '';
        if (this.isTypeOrderItem()) {
            content += 'Order Item';
        } else {
            content += 'Production Run';
        }
        return content;
    }
};

cometBusinessObjectBehaviour.add('TBGBOOrderItem', TBGBOAbstractOrderItemBehavior);
cometBusinessObjectBehaviour.add('TBGBOProofProductionRun', TBGBOAbstractOrderItemBehavior);
cometBusinessObjectBehaviour.add('TBGBOReworkProductionRun', TBGBOAbstractOrderItemBehavior);
cometBusinessObjectBehaviour.add('TBGBOInternalReworkProductionRun', TBGBOAbstractOrderItemBehavior);
