(function () {
    'use strict';

    /*
        API service is a wrapper for $http
        - removes need to manage 'restRootUrl' in each application service
        - returns response.data.result or response.data.content or response.data or response on successful requests, eliminating need to repeatedly do so in application services
        - can return class instances in place of raw responses
    */

    angular
        .module('collaterate.common')
        .factory('api', api);

    api.$inject = ['$http', 'restRootUrl', 'UtilService'];

    function api ($http, restRootUrl, UtilService) {
        function makeRequest (requestConfig, InstanceConstructor, listName) {
            var config = angular.merge({}, requestConfig);
            config.url = restRootUrl + config.url;

            if (config.hasOwnProperty('params')) {
                if (config.params.hasOwnProperty('q') && config.params.q.constructor === Object) {
                    normalizeQ();
                }
                if (config.params.hasOwnProperty('embeddedAssociations') && config.params.embeddedAssociations.constructor === Array) {
                    normalizeEmbeddedAssociations();
                }
            }

            return $http(config)
                .then(returnData);

            function returnData (response) {
                /*
                    Inspect response and set data according to response type:
                */
                var data = response;

                if (response && response.hasOwnProperty('data')) {
                    if (response.data._embedded && listName && response.data._embedded[listName]) {
                        data = response.data._embedded[listName];
                    } else if (response.data.hasOwnProperty('result')) {
                        data = response.data.result;
                    } else if (response.data.hasOwnProperty('content')) {
                        data = response.data.content;
                    } else {
                        data = response.data;
                    }
                }

                /*
                    If InstanceConstructor was passed in, return an instance or an array of instances
                */
                if (InstanceConstructor) {
                    return data.constructor === Array
                        ? UtilService.generateInstances(data, InstanceConstructor)
                        : new InstanceConstructor(data);
                }

                /*
                    Otherwise, just return the data
                */
                return data;
            }

            function normalizeQ () {
                var newQ = '';

                for (var prop in config.params.q) {
                    if (config.params.q.hasOwnProperty(prop)) {
                        newQ += prop;
                        newQ += '=';

                        if (config.params.q[prop].constructor === Array) {
                            newQ += '[';
                            config.params.q[prop].forEach(function (value, index, source) {
                                newQ += value;
                                if (index + 1 !== source.length) {
                                    newQ += ',';
                                }
                            });
                            newQ += ']';
                        } else {
                            newQ += config.params.q[prop];
                        }

                        newQ += ':';
                    }
                }

                newQ = newQ.substring(0, newQ.length - 1);

                config.params.q = newQ;
            }

            function normalizeEmbeddedAssociations () {
                config.params.embeddedAssociations = config.params.embeddedAssociations.join(',');
            }
        }

        return makeRequest;
    }
}());
