function Cookie (path, name, expireDays) {
    this.path = path;
    this.name = null;
    if (name != null) {
        this.name = name;
    }
    this.value = null;
    this.values = new Array();
    this.expireDays = 5;
    if (arguments.length == 3) {
        var parsedExpireDays = parseInt(expireDays, 10);
        this.expireDays = parsedExpireDays;
    }

    // public functions
    this.initializeFromValue = function () {
        this.values = new Array();

        // parse values
        var split = this.value.split(':');
        for (var i = 0; i < split.length; i++) {
            var each = split[i];
            var eachSplit = each.split('=');
            this.values[this.values.length] = {
                key: eachSplit[0],
                value: eachSplit[1]
            };
        }
    };
    this.generateValue = function () {
        // generate value
        var value = '';
        for (var i = 0; i < this.values.length; i++) {
            var each = this.values[i];
            if (i > 0) {
                value += ':';
            }
            value += each.key + '=' + each.value;
        }
        this.value = value;
    };
    this.save = function () {
        this.generateValue();
        var options = {
            expires: this.expireDays
        };
        if (this.path != null) {
            options.path = this.path;
        }
        $.cookie(this.name, this.value, options);

        // alert('saved ' + this.name + ':'+ this.value);
        this.display();
    };
    this.remove = function () {
        $.cookie(this.name, null, {
            path: this.path
        });
    };
    this.put = function (key, value) {
        for (var i = 0; i < this.values.length; i++) {
            var each = this.values[i];
            if (each.key == key) {
                each.value = value;
                return;
            }
        }
        this.values[this.values.length] = {
            key: key,
            value: value
        };
    };
    this.get = function (key) {
        for (var i = 0; i < this.values.length; i++) {
            var each = this.values[i];
            if (each.key == key) {
                return each.value;
            }
        }
        return null;
    };
    this.display = function () {
        if (DEBUG) {
            dynamic.setDivIdContent('debugCookie', 'name:' + this.name + ' value:' + this.value);
        }
    };
}

Cookie.load = function (path, name, expireDays) {
    var value = $.cookie(name);
    if (value == null) {
        return null;
    }
    var cookie = new Cookie();
    cookie.name = name;
    cookie.path = path;
    cookie.value = value;
    cookie.values = new Array();
    if (arguments.length == 3) {
        var parsedExpireDays = parseInt(expireDays, 10);
        cookie.expireDays = parsedExpireDays;
    }
    cookie.initializeFromValue();
    cookie.display();
    return cookie;
};
