/* See PluploadDirective (plupload-directive.constructor.js) for implementation notes */

(function () {
    'use strict';

    // Note: restriction is properly set in the constructor, but eslint does not see that,
    // disabling that rule for this file
    /* eslint-disable angular/directive-restrict */

    angular
        .module('collaterate.common.plupload')
        .directive('tbgPlupload', tbgPlupload);

    tbgPlupload.$inject = ['PluploadDirective'];

    function tbgPlupload (PluploadDirective) {
        return new PluploadDirective();
    }

    /* eslint-enable angular/directive-restrict */
}());
