cometBusinessObjectBehaviour.add('TBGBOSiteOffering', {
    getListItemValue: function () {
        return 'SLO_' + this.id;
    },
    getListItemName: function () {
        return this.name + ' (Product)';
    },
    isServiceTypeStock: function () {
        var offeringServiceType = this.offeringServiceType;
        return offeringServiceType.name == 'STOCK';
    },
    getStockAttributeTitleCount: function () {
        var i = 0;
        if (hasValue(this.stockAttributeTitle1)) {
            i += 1;
        }
        if (hasValue(this.stockAttributeTitle2)) {
            i += 1;
        }
        if (hasValue(this.stockAttributeTitle3)) {
            i += 1;
        }
        return i;
    },
    getPublishedThumbURL: function () {
        return this.thumbURL;
    },
    getPublishedThumbWidth: function () {
        return this.thumbWidth;
    },
    getPublishedThumbHeight: function () {
        return this.thumbHeight;
    },
    getVariant: function (id) {
        var variants = this.variants;
        for (var i = 0, ni = variants == null ? 0 : variants.length; i < ni; i++) {
            var variant = variants[i];
            if (variant.id == id) {
                return variant;
            }
        }
        return null;
    },
    getPublishedProductId: function () {
        return this.productId;
    },
    getPrefixedId: function (variantId) {
        var prefixedId = '';
        prefixedId += 'SLO_';
        prefixedId += this.id;
        prefixedId += '_';
        prefixedId += '-1';
        prefixedId += '_';
        prefixedId += variantId;
        return prefixedId;
    },
    getPublishedStockTrackInventory: function () {
        return this.stockTrackInventory;
    },
    getPublishedSku: function () {
        return this.skuNumber;
    }
});
