cometBusinessObjectBehaviour.add('TBGBOAdminUser', {
    getFullName: function () {
        var name = this.fname;
        name += ' ';
        name += this.lname;
        return name;
    },
    isSystemAdmin: function () {
        return true;
    }
});
