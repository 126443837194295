(function () {
    'use strict';

    angular
        .module('storefrontApp')
        .factory('fileUploadService', fileUploadService);

    fileUploadService.$inject = ['$http'];

    function fileUploadService ($http) {
        var service = {
            createReservation: createReservation,
            createFromStagedCartReservation: createFromStagedCartReservation,
            createFromStagedSalesEstimateReservation: createFromStagedSalesEstimateReservation,
            prepareBulkOrderUpload: prepareBulkOrderUpload
        };

        return service;

        function createReservation (data) {
            var requestConfig = {
                url: '/rest/storefront/cart/stagedFileReservations/create',
                method: 'POST',
                data: data
            };

            return $http(requestConfig)
                .then(returnData);

            function returnData (response) {
                return response.data;
            }
        }

        function createFromStagedCartReservation (data) {
            var requestConfig = {
                url: '/rest/storefront/cart/orderItemConsumerPrintFiles/createFromStagedFileReservation',
                method: 'POST',
                data: data
            };

            return $http(requestConfig)
                .then(returnData);

            function returnData (response) {
                return response.data;
            }
        }

        function createFromStagedSalesEstimateReservation (data) {
            var requestConfig = {
                url: '/rest/storefront/salesEstimate/orderItemConsumerPrintFiles/createFromStagedFileReservation',
                method: 'POST',
                data: data
            };

            return $http(requestConfig)
                .then(returnData);

            function returnData (response) {
                return response.data;
            }
        }

        function prepareBulkOrderUpload (data) {
            var requestConfig = {
                url: '/rest/storefront/projects/s3BulkItemUpload',
                method: 'POST',
                data: data
            };

            return $http(requestConfig)
                .then(returnData);

            function returnData (response) {
                return response.data;
            }
        }
    }
}());
