function PageLog (divId) {
    this.divId = divId;
    this.div = null;
    this.contentDivId = divId + 'contentDiv';
    this.contentDiv = null;

    // state
    this.grabXOffset;
    this.grabYOffset;
    this.mouseUpConnect = null;
    this.mouseMoveConnect = null;

    // public functions
    this.logDebug = PageLog_logDebug;

    // private functions
    this.initialize = PageLog_initialize;
    this.mouseDown = PageLog_mouseDown;
    this.mouseUp = PageLog_mouseUp;
    this.mouseMove = PageLog_mouseMove;

    // initialize
    var tempThis = this;
    $(window).load(function () {
        tempThis.initialize();
    });
}
function PageLog_initialize () {
    this.div = $('#' + this.divId)[0];

    // $.connect(this.div, "onmousedown", this, "mouseDown");
}
function PageLog_mouseDown (evt) {
    this.div.css({
        cursor: '-moz-grabbing',
        position: 'absolute'
    });
    var pos = this.div.position();
    this.grabXOffset = evt.pageX - pos.left;
    this.grabYOffset = evt.pageY - pos.top;
    this.mouseMove(evt);
    $.connect(document, 'onmouseup', this, 'mouseUp');
    $.connect(document, 'onmousemove', this, 'mouseMove');
}
function PageLog_mouseMove (evt) {
    var x = evt.pageX - this.grabXOffset;
    var y = evt.pageY - this.grabYOffset;
    this.div.css({
        left: x + 'px',
        top: y + 'px'
    });
}
function PageLog_mouseUp () {
    this.div.css({
        cursor: 'move'
    });
    $.disconnect(document, 'onmousemove', this, 'mouseMove');
    $.disconnect(document, 'onmouseup', this, 'mouseUp');
}
function PageLog_logDebug (methodName, message) {
    // if (this.contentDiv != null) {
    var div = $('#' + this.divId);
    if (div != null) {
        var text = methodName;
        if (message != null) {
            text += ' | ' + message;
        }
        text += '<br />';
        div.append(text);
    }
}
