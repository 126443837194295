(function () {
    'use strict';

    angular
        .module('collaterate.common')
        .animation('.animate--slideToggle', slideToggleAnimation);

    slideToggleAnimation.$inject = [];

    function slideToggleAnimation () {
        return {
            enter: onShow,
            leave: toggle,
            beforeAddClass: onClassHide,
            removeClass: onClassShow
        };

        function toggle (element, done) {
            angular
                .element(element)
                .stop()
                .slideToggle({
                    duration: 400,
                    easing: 'swing',
                    complete: done
                });
        }

        function onShow (element, done) {
            angular.element(element).hide();
            toggle(element, done);
        }

        function onClassShow (element, className, done) {
            if (className === 'ng-hide') {
                onShow(element, done);
            }
        }

        function onClassHide (element, className, done) {
            if (className === 'ng-hide') {
                toggle(element, done);
            }
        }
    }
}());
