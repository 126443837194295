var savePanel = {
    divId: 'savePanel',
    spinnerDivId: 'savePanelSpinnerDiv',
    onEditFunction: null,
    onSaveFunction: null,
    onCloseFunction: null,
    onCancelFunction: null,
    onDoneFunction: null,
    cancelButtonId: 'savePanelCancelButton',
    open: function (content, extraOptions) {
        var options = extraOptions;
        if (options == null) {
            options = {};
        }
        dynamic.showOverlay('savePanelOverlay', 8);
        dynamic.setDivIdContent('savePanelContent', content);
        if (options.beforeShow != null) {
            options.beforeShow();
        }
        content = '';
        if (options.customButtons != null) {
            for (var index in options.customButtons) {
                var button = options.customButtons[index];
                content += forms.button(button.name, {
                    name: button.id,
                    cssClass: button.cssClass,
                    onclick: button.onClick
                });
                content += ' ';
            }
        }
        if (options.showCancel == null || options.showCancel == true) {
            content += forms.button('Cancel', {
                name: this.cancelButtonId,
                cssClass: 'cancel',
                onclick: 'savePanel.cancelClicked()'
            });
            content += ' ';
        }
        if (options.showSave == null || options.showSave == true) {
            this.saveName = 'Save';
            if (options.saveName != null) {
                this.saveName = options.saveName;
            }
            content += forms.button(this.saveName, {
                onclick: 'savePanel.saveClicked(this)',
                cssClass: 'save'
            });
        }
        if (options.showEdit == true) {
            var editName = 'Edit';
            if (options.editName != null) {
                editName = options.editName;
            }
            content += forms.button(editName, {
                onclick: 'savePanel.editClicked()',
                cssClass: 'edit'
            });
        }
        if (options.showDone != null && options.showDone == true) {
            content += forms.button('Done', {
                onclick: 'savePanel.doneClicked()',
                cssClass: 'done'
            });
        }
        content += tags.div('', {
            id: this.spinnerDivId
        });

        dynamic.setDivIdContent('savePanelButtons', content);

        // default to show the close button in the upper right corner
        var closeContent = '';
        if (options.showClose == null || options.showClose == true) {
            closeContent += tags.a('<svg class="c-icon c-close"><use xlink:href="#close-icon" /></svg>', 'javascript:savePanel.cancelClicked()', {
                title: 'Close',
                cssClass: 'close'
            });
        }
        dynamic.setDivIdContent('closeButton', closeContent);

        this.onSaveFunction = null;
        this.onEditFunction = null;
        this.onCancelFunction = null;
        this.onDoneFunction = null;
        this.disableSaveButtonOnClick = false;
        this.disableCancelButtonOnClick = false;
        this.saveButtonDisabledText = 'Loading...';
        if (options.onsave != null) {
            this.onSaveFunction = options.onsave;
        }
        if (options.onedit != null) {
            this.onEditFunction = options.onedit;
        }
        if (options.onclose != null) {
            this.onCloseFunction = options.onclose;
        }
        if (options.oncancel != null) {
            this.onCancelFunction = options.oncancel;
        }
        if (options.ondone != null) {
            this.onDoneFunction = options.ondone;
        }
        if (options.disableSaveButtonOnClick != null) {
            this.disableSaveButtonOnClick = options.disableSaveButtonOnClick;
        }
        if (options.disableCancelButtonOnClick != null) {
            this.disableCancelButtonOnClick = options.disableCancelButtonOnClick;
        }
        if (options.saveButtonDisabledText != null) {
            this.saveButtonDisabledText = options.saveButtonDisabledText;
        }
        var topOffset = 100;
        if (options.topOffset != null) {
            topOffset = options.topOffset;
        }
        var top = $(window).scrollTop() + topOffset;
        if (options.minWidth != null) {
            $('#' + this.divId).css('min-width', options.minWidth);
        }
        if (options.maxWidth != null) {
            $('#' + this.divId).css('max-width', options.maxWidth);
        }
        var width = '500px';
        if (options.width != null) {
            width = options.width;
        }
        $('#' + this.divId).css({
            width: width
        });
        dynamic.centerDiv(this.divId);
        $('#' + this.divId).css({
            top: top + 'px'
        });
        $('#' + this.divId).fadeIn(null, function () {
            if (options.afterShow != null) {
                options.afterShow();
            }
        });
    },
    close: function () {
        if (this.onCloseFunction != null) {
            this.onCloseFunction();
        }
        this.onclose();
        $('#' + this.divId).fadeOut(null, function () {
            dynamic.setDivIdContent('savePanelContent', '');
        });
        dynamic.hideOverlay('savePanelOverlay');
    },
    onclose: function () {
        // event listeners
    },
    saveClicked: function (elem) {
        this.onsave();
        if (this.disableSaveButtonOnClick) {
            this.saveButton = elem;
            $(elem).prop('disabled', true);
            $(elem).html(this.saveButtonDisabledText);
        }
        if (this.disableCancelButtonOnClick) {
            // $('#cancelIdForSave').prop("disabled", true);
            $('#' + this.cancelButtonId).hide();
        }
        if (this.onSaveFunction != null) {
            this.onSaveFunction();
        } else {
            this.close();
        }
    },
    reEnableSaveButton: function () {
        if (this.saveButton) {
            setTimeout(function () {
                $(savePanel.saveButton).prop('disabled', false);
                $(savePanel.saveButton).html(savePanel.saveName);
            }, 1000);
        }
    },
    onsave: function () {
        // event listeners
    },
    editClicked: function () {
        this.onedit();
        if (this.onEditFunction != null) {
            this.onEditFunction();
        } else {
            this.close();
        }
    },
    onedit: function () {
        // event listeners
    },
    cancelClicked: function () {
        this.ondone();
        if (this.onCancelFunction != null) {
            this.onCancelFunction();
        } else {
            this.close();
        }
    },
    ondone: function () {
        // event listeners
    },
    doneClicked: function () {
        this.ondone();
        if (this.onDoneFunction != null) {
            this.onDoneFunction();
        } else {
            this.close();
        }
    }
};
