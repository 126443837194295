/**
 * @ngdoc object
 * @name window.tbg.notifications
 * @description
 * `window.tbg.notifications`
 *
 * Storefront notification service, enables storefront code to handle the display of various notifications instead of (or in addition to) Collaterate core.
 *
 * #### Events
 * - `GROWL_NOTIFICATION` (see Properties section below for event information)
 * - `FORM_VALIDATION` (see Properties section below for event information)
 * - `SYSTEM_MESSAGE` (see Properties section below for event information)
 */

/**
 * @ngdoc method
 * @name window.tbg.notifications#on
 * @methodOf window.tbg.notifications
 * @description Binds handlers to Collaterate notification events, optionally cancelling the default Collaterate actions.
 *
 * *Do not wait until document ready to bind to these events as there is a chance Collaterate may have already fired off its notifications by then. Bind them as close to the top of your document as possible, outside of a document ready callback.*
 * @param {string} eventName The notifications event to listen for.
 * @param {object=} [options={ preventCollaterateAction: false }] An options object. This optional param allows the cancellation of the default Collaterate action by setting preventCollaterateAction to true: `{ preventCollaterateAction: true }`
 * @param { function (event, data) } handler Function to handle the notification. Will be called with two parameters, the event object and an event data object.
 * @example
 <pre>
    // Handle a growl type notification
    window.tbg.notifications.on(window.tbg.notifications.GROWL_NOTIFICATION, function (event, data) {
        // Do something
    });

    // Handle a growl type notification, and prevent Collaterate from showing it
    window.tbg.notifications.on(window.tbg.notifications.GROWL_NOTIFICATION, { preventCollaterateAction: true }, function (event, data) {
        // Do something
    });
 </pre>
 */

/**
 * @ngdoc method
 * @name window.tbg.notifications#off
 * @methodOf window.tbg.notifications
 * @description Removes event bindings for an event.
 *
 * @param {string} eventName The notifications event to stop listening to.
 * @param {function=} handler Reference to handler function that we want to unbind. If no handler function is passed, all handler functions for eventName will be unbound.
 * @example
 <pre>
    // Stop listening to all growl notification events
    window.tbg.notifications.off(window.tbg.notifications.GROWL_NOTIFICATION);

    // To stop a particular handler from listening, we must have a reference to it. Start by declaring a named function handler:
    function myHandler (e, data) {
        console.log(data);
    }
    // Then when setting up your event listener, use the named handler function:
    window.tbg.notifications.on(window.tbg.notifications.GROWL_NOTIFICATION, myHandler)
    // Then unbind just that handler by using the named reference:
    window.tbg.notifications.off(window.tbg.notifications.GROWL_NOTIFICATION, myHandler);
    // This will unbind just myHandler, but leave any other listeners active for the window.tbg.notifications.GROWL_NOTIFICATION event
 </pre>
 */

/**
 * @ngdoc method
 * @name window.tbg.notifications#trigger
 * @methodOf window.tbg.notifications
 * @description Triggers specified event.
 *
 * @param {string} eventName The notifications event to trigger.
 * @param {object} data The event data listeners will be expecting when handling the event
 * @example
 <pre>
    // Trigger a growl notification
    window.tbg.notifications.trigger(window.tbg.notifications.GROWL_NOTIFICATION, {
        type: 'SUCCESS',
        message: 'My success growl notification message.'
    });
 </pre>
 */

/**
 * @ngdoc property
 * @name window.tbg.notifications#GROWL_NOTIFICATION
 * @propertyOf window.tbg.notifications
 * @description
 * The eventName of the event raised when Collaterate would spawn a growl type notification. The `data` param passed back to the handler funtion of this event contains two properties, `type` and `message`.
 * The `type` property will be one of three notification types, "ERROR," "SUCCESS" or "INFO." The `message` property contains the notification text provided by Collaterate.
 * @returns {string} Returns the string representation of the event name
 * @example
 <pre>
    /////////////////////////////////////////////
    // Example 'data' argument passed to handler:
    //
    // data = {
    //      type: 'ERROR'|'INFO'|'SUCCESS',
    //      message: 'Some notification message'
    // }
    /////////////////////////////////////////////

    // Log the type and message from a growl notification
    window.tbg.notifications.on(window.tbg.notifications.GROWL_NOTIFICATION, function (e, data) {
        console.log(data.type);
        console.log(data.message);
    });

    // Using the options object of the notifications.on method to prevent Collaterate from displaying the notification,
    // inspect the data.type value and display the appropriate message to the user.
    window.tbg.notifications.on(window.tbg.notifications.GROWL_NOTIFICATION, { preventCollaterateAction: true }, function (e, data) {
        // Do something to display the notification to the user instead of letting Collaterate handle it
        if (data.type === 'ERROR') {
            window.alert('An error occurred! ' + data.message);
        }

        if (data.type === 'INFO') {
            window.alert('For your information: ' + data.message);
        }

        if (data.type === 'SUCCESS') {
            window.alert('Success! ' + data.message);
        }
    });
 </pre>
 */

/**
 * @ngdoc property
 * @name window.tbg.notifications#FORM_VALIDATION
 * @propertyOf window.tbg.notifications
 * @description
 * The eventName of the event raised when Collaterate would spawn a form validation modal. The `data` param passed back to the handler funtion of this event contains one property, `fields`.
 * The `fields` property contains an array of objects containing information on each validation error. Each of these objects has three properties, `name`, `message` and `label`. The `name`
 * property contains the value of the invalid input's "name" html attribute - this is useful for selecting the input in the DOM. The `message` property contains the validation message
 * for that input and the `label` property contains the value of the invalid input's label from the markup.
 * @returns {string} Returns the string representation of the event name
 * @example
 <pre>
    /////////////////////////////////////////////
    // Example 'data' argument passed to handler:
    //
    // data = {
    //      fields: [
    //          {
    //              name: 'sampleInputName',              // name attribute from the input
    //              message: 'Sample validation message', // validation message
    //              label: 'Sample input label'           // the label text from the input
    //          },
    //          ...
    //      ]
    // }
    /////////////////////////////////////////////

    // Log the number of validation errors
    window.tbg.notifications.on(window.tbg.notifications.FORM_VALIDATION, function (e, data) {
        console.log(data.fields.length);
    });

    // Using the options object of the notifications.on method to prevent Collaterate from displaying the validation error modal,
    // loop through the fields and log the validation data to the console.
    window.tbg.notifications.on(window.tbg.notifications.FORM_VALIDATION, { preventCollaterateAction: true }, function (e, data) {
        for (var i = 0; i < data.fields.length; i++) {
            console.log(data.fields[i].name);
            console.log(data.fields[i].message);
            console.log(data.fields[i].label);
        }
    });
 </pre>
 */

/**
 * @ngdoc property
 * @name window.tbg.notifications#SYSTEM_MESSAGE
 * @propertyOf window.tbg.notifications
 * @description
 * The eventName of the event raised when Collaterate would spawn a system message box. The `data` param passed back to the handler funtion of this event contains one property, `messages`.
 * The `messages` property contains an array of objects containing system message object. Each of these objects has two properties, `time` and `message`. The `time`
 * property contains a timestamp formatted `MM/DD/YYYY HH:MM AM/PM`. The `message` property contains the system message text. Note that this event will fire every 60 seconds regardless
 * of whether messages are present, it is up to the client to inspect the payload and determine whether messages should be shown or not.
 * @returns {string} Returns the string representation of the event name
 * @example
 <pre>
    /////////////////////////////////////////////
    // Example 'data' argument passed to handler:
    //
    // data = {
    //      messages: [
    //          {
    //              time: '10/18/2018 12:11 PM',      // timestamp
    //              message: 'Sample system message'  // system message
    //          },
    //          ...
    //      ]
    // }
    /////////////////////////////////////////////

    // Using the options object of the notifications.on method to prevent Collaterate from displaying the system messages,
    // loop through the messages and log the message data to the console.
    window.tbg.notifications.on(window.tbg.notifications.SYSTEM_MESSAGE, { preventCollaterateAction: true }, function (e, data) {
        for (var i = 0; i < data.messages.length; i++) {
            console.log(data.messages[i].time);
            console.log(data.messages[i].message);
        }
    });
 </pre>
 */

(function (window, $) {
    'use strict';

    if (!$) {
        console.log('window.tbg.notifications requires jQuery to be loaded first.');
        return;
    }

    if (window.tbg && window.tbg.notifications) {
        console.log('window.tbg.notifications have already been initialized.');
        return;
    }

    var $eventBus = $({});

    var service = {
        // service methods
        on: on_notificationsFn,
        off: off_notificationsFn,
        trigger: trigger_notificationsFn,

        // service event types
        FORM_VALIDATION: 'FORM_VALIDATION',
        GROWL_NOTIFICATION: 'GROWL_NOTIFICATION',
        SYSTEM_MESSAGE: 'SYSTEM_MESSAGE'
    };

    window.tbg = window.tbg || {};
    window.tbg.notifications = service;

    return;

    function on_notificationsFn (eventName) {
        if (eventName.constructor !== String) {
            console.log('window.tbg.notifications.on expects the first argument to be an event name.');
            return;
        }

        if (!service.hasOwnProperty(eventName)) {
            console.log('window.tbg.notifications.on cannot find an event with the name: %s', eventName);
            return;
        }

        if (arguments.length < 2) {
            console.log('window.tbg.notifications.on expects two or more arguments.');
            return;
        }

        // With two arguments, we expect arguments[1] to be the callback
        if (arguments.length === 2) {
            if (arguments[1].constructor !== Function) {
                console.log('window.tbg.notifications.on expects second argument to be a callback if only two arguments are passed.');
            } else {
                // If we've made it to here, we have two arguments and they are the event name and the callback function
                $eventBus.on(eventName, arguments[1]);
            }

            return;
        }

        // With three arguments, we expect arguments[1] to be a data object and arguments[2] to be the callback
        if (arguments[1].constructor !== Object) {
            console.log('window.tbg.notifications.on expects second argument to be an object if called with more than two arguments.');
            return;
        }
        if (arguments[2].constructor !== Function) {
            console.log('window.tbg.notifications.on expects third argument to be a callback if called with more than two arguments.');
            return;
        }

        // If we've made it to here, we have three arguments and they are the event name, the event data, and the callback function
        $eventBus.on(eventName, arguments[1], arguments[2]);
    }

    function off_notificationsFn (eventName) {
        if (eventName.constructor !== String) {
            console.log('window.tbg.notifications.off expects the first argument to be an event name.');
        }

        if (arguments.length > 1 && arguments[1].constructor === Function) {
            // If we've made it to here, there are two arguments and the second is the callback
            $eventBus.off(eventName, arguments[1]);
            return;
        }

        // If we've made it to here, there is only one argument, or the second argument is not a function
        $eventBus.off(eventName);
    }

    function trigger_notificationsFn (eventName, data) {
        if (!service.hasOwnProperty(eventName)) {
            console.log('window.tbg.notifications.trigger cannot find an event with the name: %s', eventName);
            return true;
        }

        // Trigger the event
        $eventBus.trigger(eventName, data);

        // Return false if default collaterate action is to be cancelled, otherwise return true
        // If any one of all the handlers for an event prevents the default, then return false.
        // For getting the listener data, see http://stackoverflow.com/questions/2518421/jquery-find-events-handlers-registered-with-an-object
        if ($._data($eventBus.get(0), 'events') && $._data($eventBus.get(0), 'events')[eventName] && $._data($eventBus.get(0), 'events')[eventName].length) {
            for (var i = 0; i < $._data($eventBus.get(0), 'events')[eventName].length; i++) {
                if ($._data($eventBus.get(0), 'events')[eventName][i].data && $._data($eventBus.get(0), 'events')[eventName][i].data.preventCollaterateAction === true) {
                    return false;
                }
            }
        }

        return true;
    }
}(window, jQuery));
