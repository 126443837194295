var confirmPanel = {
    divId: 'confirmPanel',
    onSaveFunction: null,
    onCancelFunction: null,
    confirm: function (content, onSaveFunction, onCancelFunction, extraOptions) {
        extraOptions = extraOptions || {};

        var options = {
            cancelText: extraOptions.cancelText || 'Cancel',
            confirmText: extraOptions.confirmText || 'OK'
        };

        var convertedContent = strBR(content);
        dynamic.showOverlay('confirmPanelOverlay', 30);
        dynamic.setDivIdContent('confirmPanelContent', convertedContent);
        this.onSaveFunction = onSaveFunction;
        this.onCancelFunction = onCancelFunction;

        var buttonContent = '';

        // Create cancel button:
        buttonContent += '<button type="button" class="cancel" onclick="confirmPanel.cancelClicked()">' + options.cancelText + '</button> ';

        // Create ok button:
        buttonContent += '<button type="button" class="save" onclick="confirmPanel.saveClicked()">' + options.confirmText + '</button>';
        dynamic.setDivIdContent('confirmPanelButtons', buttonContent);

        var top = $(window).scrollTop() + 100;
        dynamic.centerDiv(this.divId);
        $('#' + this.divId).css({
            top: top + 'px'
        });
        $('#' + this.divId).fadeIn();
        var tempThis = this;
        $(window).keyup(function (e) {
            tempThis.onkeyup(e);
        });
    },
    close: function () {
        this.onclose();
        $('#' + this.divId).fadeOut(null, function () {
            dynamic.setDivIdContent('confirmPanelContent', '');
        });
        dynamic.hideOverlay('confirmPanelOverlay');
        $(window).unbind('keyup');
    },

    saveClicked: function () {
        if (this.onSaveFunction != null) {
            this.onSaveFunction();
        }
        this.close();
    },
    cancelClicked: function () {
        if (this.onCancelFunction != null) {
            this.onCancelFunction();
        }
        this.close();
    },
    onkeyup: function (event) {
        if (isEnterEvent(event)) {
            this.cancelClicked();
        }
    },
    onclose: function () {
        // event handlers
    }
};
